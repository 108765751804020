import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import UserInfoForQuoteService from './UserInfoForQuoteService';

const RealState = (props) => {
    const [serviceData, setServiceData] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [realStateService, setRealStateService] = useState('');
    const [appraisal, setAppraisal] = useState('');
    const [otherAppraisal, setOtherAppraisal] = useState(false);
    const [buyerAgent, setBuyerAgent] = useState('');
    const [otherBuyerAgent, setOtherBuyerAgent] = useState(false);
    const [homeInspection, setHomeInspection] = useState('');
    const [otherHomeInspection, setOtherHomeInspection] = useState(false);
    const [listingAgent, setListingAgent] = useState('');
    const [otherListingAgent, setOtherListingAgent] = useState(false);
    const [propertySearch, setPropertySearch] = useState('');
    const [otherPropertySearch, setOtherPropertySearch] = useState(false);
    const [rentalAgent, setRentalAgent] = useState('');
    const [otherRentalAgent, setOtherRentalAgent] = useState(false);
    const [moveInDate, setMoveInDate] = useState(new Date());
    const [moveOutDate, setMoveOutDate] = useState(new Date());
    const [appointDate, setAppointDate] = useState(new Date());
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [zipCode, setZipcode] = useState('');
    const [otherInfo, setOtherInfo] = useState('');

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);

    const handleAppraisal = (value) => {
        if (value === 'other') {
            setOtherAppraisal(true)
        } else {
            setAppraisal(value)
            setOtherAppraisal(false)
        }
    }
    const handleBuyerAgent = (value) => {
        if (value === 'other') {
            setOtherBuyerAgent(true)
        } else {
            setBuyerAgent(value)
            setOtherBuyerAgent(false)
        }
    }

    const handleHomeInspection = (value) => {
        if (value === 'other') {
            setOtherHomeInspection(true)
        } else {
            setHomeInspection(value)
            setOtherHomeInspection(false)
        }
    }
    const handleListingAgent = (value) => {
        if (value === 'other') {
            setOtherListingAgent(true)
        } else {
            setListingAgent(value)
            setOtherListingAgent(false)
        }
    }

    const handlePropertySearch = (value) => {
        if (value === 'other') {
            setOtherPropertySearch(true)
        } else {
            setPropertySearch(value)
            setOtherPropertySearch(false)
        }
    }

    const handleRentalAgent = (value) => {
        if (value === 'other') {
            setOtherRentalAgent(true)
        } else {
            setRentalAgent(value)
            setOtherRentalAgent(false)
        }
    }
    const handleDateChange = (value, type) => {
        if (type === 'moveIn') {
            setMoveInDate(value)
        } else if (type === 'moveOut') {
            setMoveOutDate(value)
        } else {
            setAppointDate(value)
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (realStateService && appointDate && city && country && zipCode) {
            // change date formate in USA format
            const usMoveIndate = new Date(moveInDate).toLocaleDateString("en-US");
            const UsMoveOutDate = new Date(moveOutDate).toLocaleDateString("en-US")
            const UsApoointDate = new Date(appointDate).toLocaleDateString("en-US")
            var quoteDetails = {
                "Requested Service": "Real State",
                "What real estate service do you need?": realStateService,
                "Which day do you need it?": UsApoointDate,
                "City": city,
                "State": country,
                "Zipcode": zipCode,
                "Any other information": otherInfo
            }
            if (realStateService === 'Appraisal') { quoteDetails["Appraisal"] = appraisal }
            if (realStateService === 'Buyers agent') { quoteDetails["Buyer's Agent"] = buyerAgent }
            if (realStateService === 'Home Inspection') { quoteDetails["Home Inspection"] = homeInspection }
            if (realStateService === 'Listing Agent') { quoteDetails["Listing Agent"] = listingAgent }
            if (realStateService === 'Property search') { quoteDetails["Property search"] = propertySearch }
            if (realStateService === 'Rental Agent') {
                quoteDetails["Rental Agent"] = rentalAgent;
                quoteDetails["Preferred move in date"] = usMoveIndate;
                quoteDetails["Preferred move out date"] = UsMoveOutDate;
            }
            let data = {
                businessName: props.businessDetails.businessName,
                businessEmail: props.businessDetails.email,
                businessId: props.businessDetails.businessId,
                clickType: 'requestQuote',
                quoteDetails: quoteDetails
            }
            setServiceData(data)
            setIsSubmit(true)
        } else {
            toast.warning('Please Select All required field!', {
                autoClose: 700,
                hideProgressBar: true,
            })
        }
    }

    return (
        <>
            {!isSubmit ? (
                <div id="realState" className='real-state-sec card shadow-sm py-4 py-md-5 px-3 px-md-5 mt-4'>
                    <ToastContainer />
                    <div className="section-label fs-3 mb-4">Real State</div>
                    <div className="mb-3">
                        <label htmlFor="chooseOccasion" className="form-label fs-6 mb-2">What real estate service do you need?</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForEstateService" id="Appraisal" required
                                    onClick={() => setRealStateService('Appraisal')} />
                                <label className="form-check-label" htmlFor="Appraisal">Appraisal</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForEstateService" id="buyerAgent"
                                    onClick={() => setRealStateService('Buyers agent')} />
                                <label className="form-check-label" htmlFor="buyerAgent">Buyer's agent</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForEstateService" id="homeInspection"
                                    onClick={() => setRealStateService('Home Inspection')} />
                                <label className="form-check-label" htmlFor="homeInspection">Home Inspection</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForEstateService" id="landingSurveying"
                                    onClick={() => setRealStateService('Landing Surveying')} />
                                <label className="form-check-label" htmlFor="landingSurveying">Landing Surveying</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForEstateService" id="listingAgent"
                                    onClick={() => setRealStateService('Listing Agent')} />
                                <label className="form-check-label" htmlFor="listingAgent">Listing Agent</label>
                            </div>

                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForEstateService" id="rentalAgent"
                                    onClick={() => setRealStateService('Property search')} />
                                <label className="form-check-label" htmlFor="rentalAgent">Property search</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForEstateService" id="rentalAgent"
                                    onClick={() => setRealStateService('Rental Agent')} />
                                <label className="form-check-label" htmlFor="rentalAgent">Rental Agent</label>
                            </div>
                        </div>

                        <div className="element-wrapper">
                            {/* If appraisal */}
                            {
                                realStateService === 'Appraisal' ?
                                    <div id="Appraisal" className="appraisal mt-3">
                                        <div className="form-label fs-6 mb-2">Appraisal</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForAppraisal" id="Business" required
                                                    onClick={() => handleAppraisal('Business')} />
                                                <label className="form-check-label" htmlFor="Business">Business</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForAppraisal" id="Residential"
                                                    onClick={() => handleAppraisal('Residential')} />
                                                <label className="form-check-label" htmlFor="Residential">Residential</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForAppraisal" id="occOther"
                                                    onClick={() => handleAppraisal('other')} />
                                                <label className="form-check-label" htmlFor="occOther">Other</label>
                                            </div>
                                        </div>
                                        {
                                            otherAppraisal ?
                                                <input type="text" className="form-control mt-2" id="chooseAppraisal" placeholder='Other appraisal...' required
                                                    onChange={(e) => setAppraisal(e.target.value)} />
                                                : ''
                                        }

                                    </div>
                                    : ''
                            }
                            {/* If Buyer's agent */}
                            {
                                realStateService === 'Buyers agent' ?
                                    <div id="BuyersAgent" className="buyer_agent mt-3">
                                        <div className="form-label fs-6 mb-2">Buyer's Agent</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBuyerAgent" id="Apartment" required
                                                    onClick={() => handleBuyerAgent('Apartment')} />
                                                <label className="form-check-label" htmlFor="Apartment">Apartment</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBuyerAgent" id="Condo"
                                                    onClick={() => handleBuyerAgent('Condo')} />
                                                <label className="form-check-label" htmlFor="Condo">Condo</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBuyerAgent" id="farm" required
                                                    onClick={() => handleBuyerAgent('Farm')} />
                                                <label className="form-check-label" htmlFor="farm">Farm</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBuyerAgent" id="House"
                                                    onClick={() => handleBuyerAgent('House')} />
                                                <label className="form-check-label" htmlFor="House">House</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBuyerAgent" id="Land" required
                                                    onClick={() => handleBuyerAgent('Land')} />
                                                <label className="form-check-label" htmlFor="Land">Land</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBuyerAgent" id="mobile_home"
                                                    onClick={() => handleBuyerAgent('Mobile home')} />
                                                <label className="form-check-label" htmlFor="mobile_home">Mobile home</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBuyerAgent" id="multi_family" required
                                                    onClick={() => handleBuyerAgent('Multi - family')} />
                                                <label className="form-check-label" htmlFor="multi_family">Multi - family</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBuyerAgent" id="Town_house"
                                                    onClick={() => handleBuyerAgent('Town house')} />
                                                <label className="form-check-label" htmlFor="Town_house">Town house</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBuyerAgent" id="Not_sure" required
                                                    onClick={() => handleBuyerAgent('Not sure')} />
                                                <label className="form-check-label" htmlFor="Not_sure">Not sure</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBuyerAgent" id="Other"
                                                    onClick={() => handleBuyerAgent('other')} />
                                                <label className="form-check-label" htmlFor="Other">Other</label>
                                            </div>
                                        </div>
                                        {
                                            otherBuyerAgent ?
                                                <input type="text" className="form-control mt-2" id="chooseBuyerAgent" placeholder='Other buyer agent...' required
                                                    onChange={(e) => setBuyerAgent(e.target.value)} />
                                                : ''
                                        }
                                    </div>
                                    : ''
                            }

                            {/* Home Inspection */}
                            {
                                realStateService === 'Home Inspection' ?
                                    <div id="HomeInspection" className="home_inspetion mt-3">
                                        <div className="form-label fs-6 mb-2">Home Inspection</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForHomeInspection" id="Apartment" required
                                                    onClick={() => handleHomeInspection('Apartment')} />
                                                <label className="form-check-label" htmlFor="Apartment">Apartment</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForHomeInspection" id="Condo"
                                                    onClick={() => handleHomeInspection('Condo')} />
                                                <label className="form-check-label" htmlFor="Condo">Condo</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForHomeInspection" id="farm" required
                                                    onClick={() => handleHomeInspection('Farm')} />
                                                <label className="form-check-label" htmlFor="farm">Farm</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForHomeInspection" id="House"
                                                    onClick={() => handleHomeInspection('House')} />
                                                <label className="form-check-label" htmlFor="House">House</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForHomeInspection" id="Land" required
                                                    onClick={() => handleHomeInspection('Land')} />
                                                <label className="form-check-label" htmlFor="Land">Land</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForHomeInspection" id="mobile_home"
                                                    onClick={() => handleHomeInspection('Mobile home')} />
                                                <label className="form-check-label" htmlFor="mobile_home">Mobile home</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForHomeInspection" id="multi_family" required
                                                    onClick={() => handleHomeInspection('Multi - family')} />
                                                <label className="form-check-label" htmlFor="multi_family">Multi - family</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForHomeInspection" id="Town_house"
                                                    onClick={() => handleHomeInspection('Town house')} />
                                                <label className="form-check-label" htmlFor="Town_house">Town house</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForHomeInspection" id="Not_sure" required
                                                    onClick={() => handleHomeInspection('Not Sure')} />
                                                <label className="form-check-label" htmlFor="Not_sure">Not sure</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForHomeInspection" id="Other"
                                                    onClick={() => handleHomeInspection('other')} />
                                                <label className="form-check-label" htmlFor="Other">Other</label>
                                            </div>
                                        </div>
                                        {
                                            otherHomeInspection ?
                                                <input type="text" className="form-control mt-2" id="chooseHomeInspection" placeholder='Other home inspection...' required
                                                    onChange={(e) => setHomeInspection(e.target.value)} />
                                                : ''
                                        }

                                    </div>
                                    : ""
                            }


                            {/* Listing Agent */}
                            {
                                realStateService === 'Listing Agent' ?
                                    <div id="ListingAgent" className="listing_agent mt-3">
                                        <div className="form-label fs-6 mb-2">Listing Agent</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForListingagent" id="Apartment" required
                                                    onClick={() => handleListingAgent('Apartment')} />
                                                <label className="form-check-label" htmlFor="Apartment">Apartment</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForListingagent" id="Condo"
                                                    onClick={() => handleListingAgent('Condo')} />
                                                <label className="form-check-label" htmlFor="Condo">Condo</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForListingagent" id="farm" required
                                                    onClick={() => handleListingAgent('Farm')} />
                                                <label className="form-check-label" htmlFor="farm">Farm</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForListingagent" id="House"
                                                    onClick={() => handleListingAgent('House')} />
                                                <label className="form-check-label" htmlFor="House">House</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForListingagent" id="Land" required
                                                    onClick={() => handleListingAgent('Land')} />
                                                <label className="form-check-label" htmlFor="Land">Land</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForListingagent" id="mobile_home"
                                                    onClick={() => handleListingAgent('Mobile home')} />
                                                <label className="form-check-label" htmlFor="mobile_home">Mobile home</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForListingagent" id="multi_family" required
                                                    onClick={() => handleListingAgent('Multi - family')} />
                                                <label className="form-check-label" htmlFor="multi_family">Multi - family</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForListingagent" id="Town_house"
                                                    onClick={() => handleListingAgent('Town house')} />
                                                <label className="form-check-label" htmlFor="Town_house">Town house</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForListingagent" id="Not_sure" required
                                                    onClick={() => handleListingAgent('Not sure')} />
                                                <label className="form-check-label" htmlFor="Not_sure">Not sure</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForListingagent" id="Other"
                                                    onClick={() => handleListingAgent('other')} />
                                                <label className="form-check-label" htmlFor="Other">Other</label>
                                            </div>
                                        </div>

                                        {
                                            otherListingAgent ?
                                                <input type="text" className="form-control mt-2" id="chooseDrink" placeholder='Other listing agent...' required
                                                    onChange={(e) => setListingAgent(e.target.value)} />
                                                : ""
                                        }

                                    </div> :
                                    ''
                            }

                            {/* Property search */}
                            {
                                realStateService === 'Property search' ?
                                    <div id="propertySearch" className="property_search mt-3">
                                        <div className="form-label fs-6 mb-2">Property search</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPropertySearch" id="Apartment" required
                                                    onClick={() => handlePropertySearch('Apartment')} />
                                                <label className="form-check-label" htmlFor="Apartment">Apartment</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPropertySearch" id="Condo"
                                                    onClick={() => handlePropertySearch('Condo')} />
                                                <label className="form-check-label" htmlFor="Condo">Condo</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPropertySearch" id="farm" required
                                                    onClick={() => handlePropertySearch('Farm')} />
                                                <label className="form-check-label" htmlFor="farm">Farm</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPropertySearch" id="House"
                                                    onClick={() => handlePropertySearch('Apartment')} />
                                                <label className="form-check-label" htmlFor="House">House</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPropertySearch" id="Land" required
                                                    onClick={() => handlePropertySearch('Land')} />
                                                <label className="form-check-label" htmlFor="Land">Land</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPropertySearch" id="mobile_home"
                                                    onClick={() => handlePropertySearch('Mobile home')} />
                                                <label className="form-check-label" htmlFor="mobile_home">Mobile home</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPropertySearch" id="multi_family" required
                                                    onClick={() => handlePropertySearch('Multi - family')} />
                                                <label className="form-check-label" htmlFor="multi_family">Multi - family</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPropertySearch" id="Town_house"
                                                    onClick={() => handlePropertySearch('Town house')} />
                                                <label className="form-check-label" htmlFor="Town_house">Town house</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPropertySearch" id="Not_sure" required
                                                    onClick={() => handlePropertySearch('Not sure')} />
                                                <label className="form-check-label" htmlFor="Not_sure">Not sure</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForPropertySearch" id="Other"
                                                    onClick={() => handlePropertySearch('other')} />
                                                <label className="form-check-label" htmlFor="Other">Other</label>
                                            </div>
                                        </div>
                                        {
                                            otherPropertySearch ?
                                                <input type="text" className="form-control mt-2" id="chooseDrink" placeholder='Other proerty search...' required
                                                    onChange={(e) => setPropertySearch(e.target.value)} />
                                                : ''
                                        }

                                    </div>
                                    : ''
                            }

                            {/* Rental Agent */}
                            {
                                realStateService === 'Rental Agent' ?
                                    <div id="rentalAgent" className="rental_agent mt-3">
                                        <div className="form-label fs-6 mb-2">Rental Agent</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRentalAgent" id="Apartment" required
                                                    onClick={() => handleRentalAgent('Apartment')} />
                                                <label className="form-check-label" htmlFor="Apartment">Apartment</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRentalAgent" id="Condo"
                                                    onClick={() => handleRentalAgent('Condo')} />
                                                <label className="form-check-label" htmlFor="Condo">Condo</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRentalAgent" id="farm" required
                                                    onClick={() => handleRentalAgent('Farm')} />
                                                <label className="form-check-label" htmlFor="farm">Farm</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRentalAgent" id="House"
                                                    onClick={() => handleRentalAgent('House')} />
                                                <label className="form-check-label" htmlFor="House">House</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRentalAgent" id="Land" required
                                                    onClick={() => handleRentalAgent('Land')} />
                                                <label className="form-check-label" htmlFor="Land">Land</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRentalAgent" id="mobile_home"
                                                    onClick={() => handleRentalAgent('Mobile home')} />
                                                <label className="form-check-label" htmlFor="mobile_home">Mobile home</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRentalAgent" id="multi_family" required
                                                    onClick={() => handleRentalAgent('Multi - family')} />
                                                <label className="form-check-label" htmlFor="multi_family">Multi - family</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRentalAgent" id="Town_house"
                                                    onClick={() => handleRentalAgent('Town house')} />
                                                <label className="form-check-label" htmlFor="Town_house">Town house</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRentalAgent" id="Not_sure" required
                                                    onClick={() => handleRentalAgent('Not sure')} />
                                                <label className="form-check-label" htmlFor="Not_sure">Not sure</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRentalAgent" id="Other"
                                                    onClick={() => handleRentalAgent('other')} />
                                                <label className="form-check-label" htmlFor="Other">Other</label>
                                            </div>
                                        </div>
                                        {otherRentalAgent ?
                                            <input type="text" className="form-control mt-2" id="chooseRentalAgent" placeholder='Other rental agent...' required
                                                onChange={(e) => setRentalAgent(e.target.value)} />
                                            : ""
                                        }
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>
                    {
                        realStateService === 'Rental Agent' ?
                            <div className="row gx-3">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="inDate" className="form-label fs-6 mb-2">Preferred move in date</label>
                                        <div className='date-picker-wrapper'>
                                            <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={moveInDate}
                                                onChange={(value) => handleDateChange(value, 'moveIn')} minDate={new Date()}
                                            />
                                        </div>
                                        {/* <input type="date" className="form-control" id="inDate" required /> */}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="outDate" className="form-label fs-6 mb-2">Preferred move out date</label>
                                        <div className='date-picker-wrapper'>
                                            <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={moveOutDate}
                                                onChange={(value) => handleDateChange(value, 'moveOut')} minDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''
                    }

                    <div className="mb-3">
                        <label htmlFor="functionDate" className="form-label mb-2">Which day do you need it?</label>
                        <div className='date-picker-wrapper'>
                            <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={appointDate}
                                onChange={(value) => handleDateChange(value, 'appointDate')} minDate={new Date()}
                            />
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="functionDate" className="form-label fs-6 mb-2">In what location do you need the service? </label>
                        <div className="d-flex flex-column flex-md-row gap-3">
                            <input type="text" className="form-control" id="city" placeholder='Enter your city' required
                                onChange={(e) => setCity(e.target.value)} />
                            <input type="text" className="form-control" id="state" placeholder='Enter your state' required
                                onChange={(e) => setCountry(e.target.value)} />
                            <input type="text" className="form-control" id="zip" placeholder='zip code' required style={{ maxWidth: '150px' }}
                                onChange={(e) => setZipcode(e.target.value)} />
                        </div>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="otherInfo" className="form-label fs-6 mb-2">Any other information</label>
                        <textarea className="form-control" id="otherInfo" rows="4" placeholder='Write your description here...' required
                            onChange={(e) => setOtherInfo(e.target.value)}></textarea>
                    </div>
                    <div className='text-danger small mb-2'>TO QUALIFY FOR A REBATE, RATE, COMMENT & SUBMIT RECEIPT OF TRANSECTIONS MADE TO sales@couponranking.com </div>
                    <div className="btn-wrapper">
                        <button type="submit" className="w-100 btn btn-primary" onClick={(e) => handleSubmit(e)}>Next</button>
                    </div>
                    {props.loading ?
                        <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                            style={{ zIndex: 1030 }}>
                            <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                        </div>
                        : ''}
                </div>
            ) :
                (<UserInfoForQuoteService state={serviceData} />)
            }
        </>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clickPerCount: (data, toast, navigate) => dispatch(actions.clickPerCount(data, toast, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(RealState))