import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Helmet } from "react-helmet";
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import UserInfoForQuoteService from './UserInfoForQuoteService';

const Landscaping = (props) => {
    const [serviceData, setServiceData] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [project, setProject] = useState('')
    const [landscapeProject, setLandscapeProject] = useState([])
    const [designPrep, setDesignPrep] = useState('')
    const [installSOD, setInstallSOD] = useState([])
    const [landscopeWork, setLandscopeWork] = useState('')
    const [otherLandscopeWork, setOtherLandscopeWork] = useState(false)
    const [lawnMaintenaceWork, setlawnMaintenaceWork] = useState([])
    const [areaForWork, setAreaForWork] = useState('');
    const [otherAreaForWork, setOtherAreaForWork] = useState(false)
    const [surveyAndDesignStatus, setSurveyAndDesignStatus] = useState('');
    const [needGradingBeforeTopSoil, setNeedGrading] = useState('');
    const [materialDelivery, setMaterialDelivery] = useState('');
    const [otherMaterialDelivery, setOtherMaterialDelivery] = useState(false)
    const [gradingPurpose, setGradingPurpose] = useState('');
    const [otherGradingPurpose, setOtherGradingPurpose] = useState(false)
    const [lawnCareType, setLawnCareType] = useState('');
    const [otherLawnCareType, setotherLawnCareType] = useState(false)
    const [lawnCareService, setLawnCareService] = useState('');
    const [otherLawnCareService, setotherLawnCareService] = useState(false)
    const [lawnCareFrequency, setLawnCareFrequency] = useState('');
    const [otherLawnCareFrequency, setotherLawnCareFrequecy] = useState(false)
    const [recurringService, setRecurringService] = useState('')
    const [grassStatus, setGrassStatus] = useState('');
    const [locationType, setLocationType] = useState('')
    const [otherGrassStatus, setotherGrassStatus] = useState(false)
    const [appointDate, setAppointDate] = useState(new Date());
    const [otherInfo, setOtherInfo] = useState('')

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);

    const handleLandscapeProject = (value) => {
        let newLandProject = [...landscapeProject]
        if (newLandProject.includes(value)) {
            let indexValue = newLandProject.indexOf(newLandProject)
            newLandProject.splice(indexValue, 1)
            setLandscapeProject(newLandProject)
        } else {
            newLandProject.push(value)
            setLandscapeProject(newLandProject)
        }
    }

    const handleInstallSod = (value) => {
        let updatedInstallSOD = [...installSOD]
        if (updatedInstallSOD.includes(value)) {
            let indexValue = updatedInstallSOD.indexOf(updatedInstallSOD)
            updatedInstallSOD.splice(indexValue, 1)
            setInstallSOD(updatedInstallSOD)
        } else {
            updatedInstallSOD.push(value)
            setInstallSOD(updatedInstallSOD)
        }
    }

    const handleLandscapeWork = (value) => {
        if (value === 'other') {
            setOtherLandscopeWork(true)
            setLandscopeWork('')
        } else {
            setOtherLandscopeWork(false)
            setLandscopeWork(value)
        }
    }

    const handleLawnMaintenanceWork = (value) => {
        let updatedLawnMWork = [...lawnMaintenaceWork]
        if (updatedLawnMWork.includes(value)) {
            let indexValue = updatedLawnMWork.indexOf(updatedLawnMWork)
            updatedLawnMWork.splice(indexValue, 1)
            setlawnMaintenaceWork(updatedLawnMWork)
        } else {
            updatedLawnMWork.push(value)
            setlawnMaintenaceWork(updatedLawnMWork)
        }
    }

    const handleAreaForWork = (value) => {
        if (value === 'other') {
            setOtherAreaForWork(true)
            setAreaForWork('')
        } else {
            setOtherAreaForWork(false)
            setAreaForWork(value)
        }
    }

    const handleGradingPurpose = (value) => {
        if (value === 'other') {
            setOtherGradingPurpose(true)
            setGradingPurpose('')
        } else {
            setOtherGradingPurpose(false)
            setGradingPurpose(value)
        }
    }

    const handleMaterialDelivery = (value) => {
        if (value === 'other') {
            setOtherMaterialDelivery(true)
            setMaterialDelivery('')
        } else {
            setOtherMaterialDelivery(false)
            setMaterialDelivery(value)
        }
    }
    const handleLawnCareType = (value) => {
        if (value === 'other') {
            setotherLawnCareType(true)
            setLawnCareType('')
        } else {
            setotherLawnCareType(false)
            setLawnCareType(value)
        }
    }
    const handleLawnCareService = (value) => {
        if (value === 'other') {
            setotherLawnCareService(true)
            setLawnCareService('')
        } else {
            setotherLawnCareService(false)
            setLawnCareService(value)
        }
    }
    const handleLawnCareFrequency = (value) => {
        if (value === 'other') {
            setotherLawnCareFrequecy(true)
            setLawnCareFrequency('')
        } else {
            setotherLawnCareFrequecy(false)
            setLawnCareFrequency(value)
        }
    }
    const handleGrassStatus = (value) => {
        if (value === 'other') {
            setotherGrassStatus(true)
            setGrassStatus('')
        } else {
            setotherGrassStatus(false)
            setGrassStatus(value)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (project && appointDate && locationType) {
            let UsDate = new Date(appointDate).toLocaleDateString("en-US")
            let quoteDetails = {
                "Requested Service": "Landscaping (Lawn Mow) Services",
                "What type of project are you looking to start?": project,
                "What kind of location is this?": locationType,
                "When do you require this service?": UsDate,
                "Any other details": otherInfo
            }
            let data = {
                businessName: props.businessDetails.businessName,
                businessEmail: props.businessDetails.email,
                businessId: props.businessDetails.businessId,
                clickType: 'requestQuote',
                quoteDetails: quoteDetails
            }
            if (project === 'Landscaping') {
                if (landscapeProject && landscopeWork && areaForWork) {
                    quoteDetails['Select what will be included in your landscaping project'] = landscapeProject
                    quoteDetails['What landscaping work do you need?'] = landscopeWork
                    quoteDetails['What is the estimated size of the area you want to fill or cover?'] = areaForWork
                    if (landscopeWork === 'Landscaping for yard/garden') {
                        quoteDetails['What type of design preparation has been done?'] = designPrep
                        quoteDetails['Has the site been surveyed and have landscape drawings been completed?'] = surveyAndDesignStatus
                    } else if (landscopeWork === 'Grade or reslope grounds') {
                        quoteDetails['What is the purpose of grading?'] = gradingPurpose
                        quoteDetails['Does the area need to be graded before top soil and sod is placed?'] = needGradingBeforeTopSoil
                    } else if (landscopeWork === 'Install sod') {
                        quoteDetails['What existing surface have to be removed before the sod is installed?'] = installSOD
                    } else if (landscopeWork === 'Delivery of soil/sand/mulch/rock') {
                        quoteDetails['What material do you want delivered?'] = materialDelivery
                    }
                    setServiceData(data)
                    setIsSubmit(true)
                } else {
                    toast.warning(`Please Select All Landscape Project,Landscope Work and and area for work field!`, {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }
            } else if (project === 'Lawn mow') {
                if (lawnCareType && grassStatus && areaForWork) {
                    quoteDetails['What type of lawn care do you need?'] = lawnCareType
                    quoteDetails['Is grass taller than 6 inches?'] = grassStatus
                    quoteDetails['What is the estimated size of the area you want to fill or cover?'] = areaForWork
                    if (lawnCareType !== 'Fall/ spring clean up') { quoteDetails['How often do you need lawn care?'] = lawnCareFrequency }
                    if (lawnCareType === 'Lawn moving / maintenance') {
                        quoteDetails['What kind of lawn maintenance do you need?'] = lawnMaintenaceWork
                    } else if (lawnCareType === 'Fertilizer or treat lawn') {
                        quoteDetails['What lawn care service are needed?'] = lawnCareService
                    } else if (lawnCareType === 'Fall/ spring clean up') {
                        quoteDetails['Will this request be a recurring service?'] = recurringService
                    }
                    setServiceData(data)
                    setIsSubmit(true)
                } else {
                    toast.warning(`Please Select All lawn care type and grass status and area for work field!`, {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }
            } else if (project === 'Snow removal') {
                setServiceData(data)
                setIsSubmit(true)
            } else {
                toast.warning(`Please Select All ${project} field!`, {
                    autoClose: 700,
                    hideProgressBar: true,
                })
            }

        } else {
            toast.warning('Please Select All required field!', {
                autoClose: 700,
                hideProgressBar: true,
            })
        }
    }
    return (
        <>
            {!isSubmit ? (
                <div id="Landscaping" className='card shadow-sm py-4 py-md-5 px-3 px-md-5 mt-4'>
                    <Helmet>
                        <title>Request Quote For Landscaping</title>
                    </Helmet>
                    <ToastContainer />
                    <div className="section-label fs-3 mb-4">Landscaping (Lawn Mow) Services</div>
                    <div className="mb-3">
                        <label className="form-label fs-6 mb-2">What type of project are you looking to start?</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForProject" id="landscaping"
                                    onClick={() => setProject('Landscaping')} />
                                <label className="form-check-label" htmlFor="landscaping">Landscaping</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForProject" id="lawn_mow"
                                    onClick={() => setProject('Lawn mow')} />
                                <label className="form-check-label" htmlFor="lawn_mow">Lawn mow</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForProject" id="snow_removal" required
                                    onClick={() => setProject('Snow removal')} />
                                <label className="form-check-label" htmlFor="snow_removal">Snow removal</label>
                            </div>
                        </div>
                    </div>

                    {/* for selecting landscape this section will open */}
                    {
                        project === 'Landscaping' ?
                            <div className='forLandscaping'>
                                <div className="mb-3">
                                    <label className="form-label fs-6 mb-2">Select what will be included in your landscaping project</label>
                                    <div className="d-flex flex-wrap g-2">
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="checkbox" name="checkGroupLandPrjt" id="tree_shrub" required
                                                onClick={() => handleLandscapeProject('Trees/shrubs')} />
                                            <label className="form-check-label" htmlFor="tree_shrub">Trees/shrubs</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="checkbox" name="checkGroupLandPrjt" id="SPAG"
                                                onClick={() => handleLandscapeProject('Special planting area/garden')} />
                                            <label className="form-check-label" htmlFor="SPAG">Special planting area/garden</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="checkbox" name="checkGroupLandPrjt" id="grass"
                                                onClick={() => handleLandscapeProject('Grass')} />
                                            <label className="form-check-label" htmlFor="grass">Grass</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label fs-6 mb-2">What landscaping work do you need?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroup1" id="entire_exterior" required
                                                onClick={() => handleLandscapeWork('Landscaping for yard/garden')} />
                                            <label className="form-check-label" htmlFor="entire_exterior">Landscaping for yard/garden</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroup1" id="partial_exterior"
                                                onClick={() => handleLandscapeWork('Grade or reslope grounds')} />
                                            <label className="form-check-label" htmlFor="partial_exterior">Grade or reslope grounds</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroup1" id="entire_interior"
                                                onClick={() => handleLandscapeWork('Install sod')} />
                                            <label className="form-check-label" htmlFor="entire_interior">Install sod</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroup1" id="something_else"
                                                onClick={() => handleLandscapeWork('Delivery of soil/sand/mulch/rock')} />
                                            <label className="form-check-label" htmlFor="something_else">Delivery of soil/sand/mulch/rock</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroup1" id="others1"
                                                onClick={() => handleLandscapeWork('other')} />
                                            <label className="form-check-label" htmlFor="others1">Other</label>
                                        </div>
                                    </div>
                                    {
                                        otherLandscopeWork ?
                                            <input type="text" className="form-control mt-2" id="OtherForLandscapework" placeholder='Other...' required
                                                onChange={(e) => setLandscopeWork(e.target.value)} />
                                            : ''
                                    }
                                </div>
                                {
                                    landscopeWork === 'Landscaping for yard/garden' ?
                                        <div>
                                            <div className="mb-3">
                                                <label className="form-label fs-6 mb-2"> What type of design preparation has been done?</label>
                                                <div className="d-flex flex-wrap g-2">
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="radioGroupForDesignPrep" id="design"
                                                            onClick={() => setDesignPrep('Need design/plant preparation')} />
                                                        <label className="form-check-label" htmlFor="design">Need design/plant preparation</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="radioGroupForDesignPrep" id="noIdea" required
                                                            onClick={() => setDesignPrep('Have an idea of what I want')} />
                                                        <label className="form-check-label" htmlFor="noIdea">Have an idea of what I want</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="radioGroupForDesignPrep" id="notSure"
                                                            onClick={() => setDesignPrep('Not sure')} />
                                                        <label className="form-check-label" htmlFor="notSure">Not sure</label>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label fs-6 mb-2"> Has the site been surveyed and have landscape drawings been completed? </label>
                                                <div className="d-flex flex-wrap g-2">
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="radioGroupForSurvey" id="yesSurvey"
                                                            onClick={() => setSurveyAndDesignStatus('Yes')} />
                                                        <label className="form-check-label" htmlFor="yesSurvey">Yes</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="radioGroupForSurvey" id="noSurvey" required
                                                            onClick={() => setSurveyAndDesignStatus('No')} />
                                                        <label className="form-check-label" htmlFor="noSurvey">No</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="radioGroupForSurvey" id="notSure"
                                                            onClick={() => setSurveyAndDesignStatus('Not sure/want recommendation')} />
                                                        <label className="form-check-label" htmlFor="notSure">Not sure/want recommendation</label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }
                                {landscopeWork === 'Grade or reslope grounds' ?
                                    <div>
                                        <div className="mb-3">
                                            <label className="form-label fs-6 mb-2">What is the purpose of grading?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForGradingPurpose" id="improveDrainage" required
                                                        onClick={() => handleGradingPurpose('Improving existing drainage')} />
                                                    <label className="form-check-label" htmlFor="improveDrainage">Improving existing drainage</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForGradingPurpose" id="erosion"
                                                        onClick={() => handleGradingPurpose('Erosion/flooded prevention')} />
                                                    <label className="form-check-label" htmlFor="erosion">Erosion/flooded prevention</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForGradingPurpose" id="new_construction"
                                                        onClick={() => handleGradingPurpose('New construction')} />
                                                    <label className="form-check-label" htmlFor="new_construction">New construction</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForGradingPurpose" id="others1"
                                                        onClick={() => handleGradingPurpose('other')} />
                                                    <label className="form-check-label" htmlFor="others1">Other</label>
                                                </div>
                                            </div>
                                            {
                                                otherGradingPurpose ?
                                                    <input type="text" className="form-control mt-2" id="OtherForLandscapework" placeholder='Other...' required
                                                        onChange={(e) => setGradingPurpose(e.target.value)} />
                                                    : ''
                                            }
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label fs-6 mb-2"> Does the area need to be graded before top soil and sod is placed? </label>
                                            <div className="d-flex flex-wrap g-2">
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForNeedGrade" id="yesGraded"
                                                        onClick={() => setNeedGrading('Yes')} />
                                                    <label className="form-check-label" htmlFor="yesGraded">Yes</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForNeedGrade" id="noGraded" required
                                                        onClick={() => setNeedGrading('No')} />
                                                    <label className="form-check-label" htmlFor="noGraded">No</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForNeedGrade" id="notSureGraded"
                                                        onClick={() => setNeedGrading('Not sure/want recommendation')} />
                                                    <label className="form-check-label" htmlFor="notSureGraded">Not sure/want recommendation</label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    : ''
                                }
                                {
                                    landscopeWork === 'Install sod' ?
                                        <div className="mb-3">
                                            <label className="form-label fs-6 mb-2">What existing surface have to be removed before the sod is installed?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="checkGroupForSOD" id="old_grass_or_weeds" required
                                                        onClick={() => handleInstallSod('Old grass or weeds')} />
                                                    <label className="form-check-label" htmlFor="old_grass_or_weeds">Old grass or weeds</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="checkGroupForSOD" id="trees"
                                                        onClick={() => handleInstallSod('Trees')} />
                                                    <label className="form-check-label" htmlFor="trees">Trees</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="checkGroupForSOD" id="asphalt"
                                                        onClick={() => handleInstallSod('Asphalt')} />
                                                    <label className="form-check-label" htmlFor="asphalt">Asphalt</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="checkGroupForSOD" id="concrete"
                                                        onClick={() => handleInstallSod('Concrete')} />
                                                    <label className="form-check-label" htmlFor="concrete">Concrete</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="checkGroupForSOD" id="no_ex_srf"
                                                        onClick={() => handleInstallSod('No existing surface to be removed')} />
                                                    <label className="form-check-label" htmlFor="no_ex_srf">No existing surface to be removed</label>
                                                </div>

                                            </div>
                                        </div>
                                        : ''
                                }
                                {
                                    landscopeWork === 'Delivery of soil/sand/mulch/rock' ?
                                        <div className="mb-3">
                                            <label className="form-label fs-6 mb-2">What material do you want delivered?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForMaterial" id="soil" required
                                                        onClick={() => handleMaterialDelivery('Soil')} />
                                                    <label className="form-check-label" htmlFor="soil">Soil</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForMaterial" id="dirt"
                                                        onClick={() => handleMaterialDelivery('Dirt')} />
                                                    <label className="form-check-label" htmlFor="dirt">Dirt</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForMaterial" id="sand"
                                                        onClick={() => handleMaterialDelivery('Sand')} />
                                                    <label className="form-check-label" htmlFor="sand">Sand</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForMaterial" id="rock"
                                                        onClick={() => handleMaterialDelivery('Rock')} />
                                                    <label className="form-check-label" htmlFor="rock">Rock</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForMaterial" id="gravel"
                                                        onClick={() => handleMaterialDelivery('Gravel')} />
                                                    <label className="form-check-label" htmlFor="gravel">Gravel</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForMaterial" id="notSureMaterial"
                                                        onClick={() => handleMaterialDelivery('Not Sure')} />
                                                    <label className="form-check-label" htmlFor="notSureMaterial">Not Sure</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForMaterial" id="othersMaterial"
                                                        onClick={() => handleMaterialDelivery('other')} />
                                                    <label className="form-check-label" htmlFor="othersMaterial">Other</label>
                                                </div>
                                            </div>
                                            {
                                                otherMaterialDelivery ?
                                                    <input type="text" className="form-control mt-2" id="OtherForMaterial" placeholder='Other...' required
                                                        onChange={(e) => setMaterialDelivery(e.target.value)} />
                                                    : ''
                                            }
                                        </div>
                                        : ""
                                }
                            </div>
                            : ''
                    }

                    {/* for selecting Lawn Mow this section will open */}
                    {
                        project === 'Lawn mow' ?
                            <div className='forLawnMow'>
                                <div className="mb-3">
                                    <label className="form-label fs-6 mb-2">What type of lawn care do you need?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForLawnCare" id="lawn_moving" required
                                                onClick={() => handleLawnCareType('Lawn moving / maintenance')} />
                                            <label className="form-check-label" htmlFor="lawn_moving">Lawn moving / maintenance</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForLawnCare" id="fertilizer"
                                                onClick={() => handleLawnCareType('Fertilizer or treat lawn')} />
                                            <label className="form-check-label" htmlFor="fertilizer">Fertilizer or treat lawn</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForLawnCare" id="spring_clean_up"
                                                onClick={() => handleLawnCareType('Fall/ spring clean up')} />
                                            <label className="form-check-label" htmlFor="spring_clean_up">Fall/ spring clean up</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForLawnCare" id="othersMaterial"
                                                onClick={() => handleLawnCareType('other')} />
                                            <label className="form-check-label" htmlFor="othersMaterial">Other</label>
                                        </div>
                                    </div>
                                    {
                                        otherLawnCareType ?
                                            <input type="text" className="form-control mt-2" id="OtherForMaterial" placeholder='Other...' required
                                                onChange={(e) => setLawnCareType(e.target.value)} />
                                            : ''
                                    }
                                </div>
                                {
                                    lawnCareType === 'Fertilizer or treat lawn' ?
                                        <div className="mb-3">
                                            <label className="form-label fs-6 mb-2">What lawn care service are needed?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForLawnCareService" id="weed_control" required
                                                        onClick={() => handleLawnCareService('Weed control')} />
                                                    <label className="form-check-label" htmlFor="weed_control">Weed control</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForLawnCareService" id="lawn_analysis"
                                                        onClick={() => handleLawnCareService('Lawn analysis')} />
                                                    <label className="form-check-label" htmlFor="lawn_analysis">Lawn analysis</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForLawnCareService" id="natural_fertilizer"
                                                        onClick={() => handleLawnCareService('Natural fertilizer')} />
                                                    <label className="form-check-label" htmlFor="natural_fertilizer">Natural fertilizer</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForLawnCareService" id="insect_control"
                                                        onClick={() => handleLawnCareService('Insect control')} />
                                                    <label className="form-check-label" htmlFor="insect_control">Insect control</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForLawnCareService" id="seeding"
                                                        onClick={() => handleLawnCareService('Seeding')} />
                                                    <label className="form-check-label" htmlFor="seeding">Seeding</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForLawnCareService" id="otherLawnService"
                                                        onClick={() => handleLawnCareService('other')} />
                                                    <label className="form-check-label" htmlFor="otherLawnService">Other</label>
                                                </div>
                                            </div>
                                            {
                                                otherLawnCareService ?
                                                    <input type="text" className="form-control mt-2" id="OtherLawnCareService" placeholder='Other...' required
                                                        onChange={(e) => setLawnCareService(e.target.value)} />
                                                    : ''
                                            }
                                        </div> :
                                        ''
                                }
                                {
                                    lawnCareType === 'Lawn moving / maintenance' ?
                                        <div className="mb-3">
                                            <label className="form-label fs-6 mb-2">What kind of lawn maintenance do you need?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="checkGroupLawnMaintenace" id="aeration" required
                                                        onClick={() => handleLawnMaintenanceWork('Aeration')} />
                                                    <label className="form-check-label" htmlFor="aeration">Aeration</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="checkGroupLawnMaintenace" id="bodb"
                                                        onClick={() => handleLawnMaintenanceWork('Brush or debris removal')} />
                                                    <label className="form-check-label" htmlFor="bodb">Brush or debris removal</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="checkGroupLawnMaintenace" id="edging"
                                                        onClick={() => handleLawnMaintenanceWork('Edging')} />
                                                    <label className="form-check-label" htmlFor="edging">Edging</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="checkGroupLawnMaintenace" id="fertilizer"
                                                        onClick={() => handleLawnMaintenanceWork('Fertilizer application')} />
                                                    <label className="form-check-label" htmlFor="fertilizer">Fertilizer application</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="checkGroupLawnMaintenace" id="GOSR"
                                                        onClick={() => handleLawnMaintenanceWork('Grass or sod removal')} />
                                                    <label className="form-check-label" htmlFor="GOSR">Grass or sod removal</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="checkGroupLawnMaintenace" id="laying"
                                                        onClick={() => handleLawnMaintenanceWork('Laying sod')} />
                                                    <label className="form-check-label" htmlFor="laying">Laying sod</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="checkGroupLawnMaintenace" id="leaforDebris"
                                                        onClick={() => handleLawnMaintenanceWork('Leaf or debris removal')} />
                                                    <label className="form-check-label" htmlFor="leaforDebris">Leaf or debris removal</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="checkGroupLawnMaintenace" id="mulching"
                                                        onClick={() => handleLawnMaintenanceWork('Mulching')} />
                                                    <label className="form-check-label" htmlFor="mulching">Mulching</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="checkGroupLawnMaintenace" id="pest_control"
                                                        onClick={() => handleLawnMaintenanceWork('Pest control')} />
                                                    <label className="form-check-label" htmlFor="pest_control">Pest control</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="checkGroupLawnMaintenace" id="seed_or_reseed"
                                                        onClick={() => handleLawnMaintenanceWork('Seeding or Reseeding')} />
                                                    <label className="form-check-label" htmlFor="seed_or_reseed">Seeding or Reseeding</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="checkGroupLawnMaintenace" id="weeding"
                                                        onClick={() => handleLawnMaintenanceWork('Weeding')} />
                                                    <label className="form-check-label" htmlFor="weeding">Weeding</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="checkbox" name="checkGroupLawnMaintenace" id="not_sure"
                                                        onClick={() => handleLawnMaintenanceWork('Not sure')} />
                                                    <label className="form-check-label" htmlFor="not_sure">Not sure</label>
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }
                                {
                                    lawnCareType === 'Fall/ spring clean up' ?
                                        <div className="mb-3">
                                            <label className="form-label fs-6 mb-2">Will this request be a recurring service?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForRecurring" id="yesRecurring"
                                                        onClick={() => setRecurringService('Yes')} />
                                                    <label className="form-check-label" htmlFor="yesRecurring">Yes</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForRecurring" id="noRecurring" required
                                                        onClick={() => setRecurringService('No')} />
                                                    <label className="form-check-label" htmlFor="noRecurring">No</label>
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }
                                {
                                    lawnCareType !== 'Fall/ spring clean up' ?
                                        <div className="mb-3">
                                            <label className="form-label fs-6 mb-2">How often do you need lawn care?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForCareFreq" id="oneTime_care" required
                                                        onClick={() => handleLawnCareFrequency('One time service')} />
                                                    <label className="form-check-label" htmlFor="oneTime_care">One time service</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForCareFreq" id="every_weekCare"
                                                        onClick={() => handleLawnCareFrequency('Every week')} />
                                                    <label className="form-check-label" htmlFor="every_weekCare">Every week</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForCareFreq" id="every_other_weekCare"
                                                        onClick={() => handleLawnCareFrequency('Every other week')} />
                                                    <label className="form-check-label" htmlFor="every_other_weekCare">Every other week</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForCareFreq" id="once_a_monthCare"
                                                        onClick={() => handleLawnCareFrequency('Once a month')} />
                                                    <label className="form-check-label" htmlFor="once_a_monthCare">Once a month</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForCareFreq" id="othersCareFrequency"
                                                        onClick={() => handleLawnCareFrequency('other')} />
                                                    <label className="form-check-label" htmlFor="othersCareFrequency">Other</label>
                                                </div>
                                            </div>
                                            {
                                                otherLawnCareFrequency ?
                                                    <input type="text" className="form-control mt-2" id="OtherForCareFreq" placeholder='Other...' required
                                                        onChange={(e) => setLawnCareFrequency(e.target.value)} />
                                                    : ''
                                            }
                                        </div>
                                        : ''
                                }
                                <div className="mb-3">
                                    <label className="form-label fs-6 mb-2">Is grass taller than 6 inches?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGrouForGrassStatus" id="yesGrass" required
                                                onClick={() => handleGrassStatus('Yes')} />
                                            <label className="form-check-label" htmlFor="yesGrass">Yes</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGrouForGrassStatus" id="noGrass"
                                                onClick={() => handleGrassStatus('No')} />
                                            <label className="form-check-label" htmlFor="noGrass">No</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGrouForGrassStatus" id="notSureGrass"
                                                onClick={() => handleGrassStatus('Not Sure')} />
                                            <label className="form-check-label" htmlFor="notSureGrass">Not Sure</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGrouForGrassStatus" id="otherGrassStatus"
                                                onClick={() => handleGrassStatus('other')} />
                                            <label className="form-check-label" htmlFor="otherGrassStatus">Other</label>
                                        </div>
                                    </div>
                                    {
                                        otherGrassStatus ?
                                            <input type="text" className="form-control mt-2" id="OtherForGrass" placeholder='Other...' required
                                                onChange={(e) => setGrassStatus(e.target.value)} />
                                            : ''
                                    }
                                </div>
                            </div>
                            : ''
                    }

                    {
                        project !== 'Snow removal' ?
                            <div className="mb-3">
                                <label className="form-label fs-6 mb-2">What is the estimated size of the area you want to fill or cover?</label>
                                <div className="d-flex flex-wrap g-2">
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupArea" id="less_1000" required
                                            onClick={() => handleAreaForWork('less than 1000sqft')} />
                                        <label className="form-check-label" htmlFor="less_1000">less than 1000sqft</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupArea" id="1000 - 3000sqft"
                                            onClick={() => handleAreaForWork('1000 - 3000sqft')} />
                                        <label className="form-check-label" htmlFor="1000 - 3000sqft">1000 - 3000sqft</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupArea" id="1000_1500_sqft"
                                            onClick={() => handleAreaForWork('3000-10000sqft')} />
                                        <label className="form-check-label" htmlFor="1000_1500_sqft">3000-10000sqft</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupArea" id="10000_and_above_sqft"
                                            onClick={() => handleAreaForWork('10000 and above sqft')} />
                                        <label className="form-check-label" htmlFor="10000_and_above_sqft">10000 and above sqft</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupArea" id="Other4"
                                            onClick={() => handleAreaForWork('other')} />
                                        <label className="form-check-label" htmlFor="Other4">Other</label>
                                    </div>
                                </div>
                                {
                                    otherAreaForWork ?
                                        <input type="text" className="form-control mt-2" id="Other3" placeholder='Other...' required
                                            onChange={(e) => setAreaForWork(e.target.value)} />
                                        : ''
                                }
                            </div>
                            : ''
                    }
                    <div className="mb-3">
                        <label className="form-label fs-6 mb-2">What kind of location is this?</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForLocation" id="location_Home" required
                                    onClick={() => setLocationType('Home')} />
                                <label className="form-check-label" htmlFor="location_Home">Home</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForLocation" id="location_business"
                                    onClick={() => setLocationType('Business')} />
                                <label className="form-check-label" htmlFor="location_business">Business</label>
                            </div>
                        </div>
                    </div>

                    <div className="mb-3">
                        <label className="form-label fs-6 mb-2">When do you require this service?</label>
                        <div className='date-picker-wrapper'>
                            <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={appointDate}
                                onChange={(value) => setAppointDate(value)} minDate={new Date()}
                            />
                        </div>
                    </div>

                    <div className="mb-4">
                        <label htmlhtmlFor="otherInfo" className="form-label fs-6 mb-2">Are there any other details you d like to share?</label>
                        <textarea className="form-control" id="otherInfo" rows="4" placeholder='Write your description here...' required
                            onChange={(e) => setOtherInfo(e.target.value)}></textarea>
                    </div>
                    <div className='text-danger small mb-2'>TO QUALIFY FOR A REBATE, RATE, COMMENT & SUBMIT RECEIPT OF TRANSECTIONS MADE TO sales@couponranking.com </div>
                    <div className="btn-wrapper">
                        <button type="submit" className="w-100 btn btn-primary" onClick={(e) => handleSubmit(e)}>Next</button>
                    </div>
                    {props.loading ?
                        <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                            style={{ zIndex: 1030 }}>
                            <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                        </div>
                        : ''
                    }
                </div>
            ) :
                (<UserInfoForQuoteService state={serviceData} />)
            }
        </>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clickPerCount: (data, toast, navigate) => dispatch(actions.clickPerCount(data, toast, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(Landscaping))
