import React from 'react';
import { useState } from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { useEffect } from 'react';
// import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
// Our Packages Slick Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import catering from '../assets/icons/catering.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating'
const BusinessImg = [
    {
        id: 1,
        image: require('../assets/food-banner1.jpg'),
    },
    {
        id: 2,
        image: require('../assets/food-banner2.jpg'),
    },
    {
        id: 3,
        image: require('../assets/food-banner3.jpg'),
    },
];


const BusinessDetails = (props) => {
    // Slick Props
    var settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const { getBusinessDetails, businessDetails, getRatingList, ratingList, ratingSuccess } = props;
    const { state } = useLocation();
    const navigate = useNavigate()
    const [rating, setRating] = useState(0)
    const [ratingComment, setRatingComment] = useState('')
    const [seeAllProduct, setSeeAllProduct] = useState(false)
    useEffect(() => {
        let fetchBusinessDetails = async () => {
            await getBusinessDetails({ businessId: state.businessId })
            await getRatingList({ businessId: state.businessId })
        }
        fetchBusinessDetails()
        // for loading page from top after re-render from any section
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
        toast.success(ratingSuccess)
    }, [getBusinessDetails, state, getRatingList, ratingSuccess])

    let token = localStorage.getItem('token')
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))

    var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    var d = new Date();
    var dayName = days[d.getDay()];
    var dayString = `is_${dayName}_Open`

    // make function for showing address with city and state and country
    const getRequiredAddress = (str, from) => {
        var arr = str.split(',');
        arr.splice(0, from);
        return arr.join(',');
    }
    // handle direction count
    const sendOnDirectionPage = () => {
        let addr = businessDetails.address ? getRequiredAddress(businessDetails.address, 3) : businessDetails.city
        let businessInfo = {
            destLat: businessDetails.latitude,
            desLong: businessDetails.longitude,
            businessName: businessDetails.name,
            address: addr
        }
        let data = {
            businessId: businessDetails._id,
            clickType: 'direction'
        }
        props.clickPerCount(data)
        navigate(`/direction-to-business/${addr.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo })
    }
    //handle order counts
    const sendOnWebsite = (type) => {
        let data = {
            businessId: businessDetails._id,
            clickType: type
        }
        props.clickPerCount(data)
        window.open(`https://${businessDetails.website}`, '_blank', 'noopener,noreferrer')
    }
    // handle call clicks count
    const handleCallClick = () => {
        let data = {
            businessId: businessDetails._id,
            clickType: 'call'
        }
        props.clickPerCount(data)
    }
    //handle email clicks count
    const handleEmailClick = () => {
        if (!token) {
            navigate('/login')
        } else {
            let businessInfo = {
                email: businessDetails.email,
                businessName: businessDetails.name,
                country_code: businessDetails.country_code,
                phone_no: businessDetails.phone_no,
                businessId: businessDetails._id
            }
            navigate(`/send-email-to-business/:${businessDetails.name}`, { state: businessInfo })
        }
    }

    const checkRatingModal = (business) => {
        if (token !== null) {
            // setSelectedBusiness({ businessName: business.name, businessId: business._id })
        } else {
            navigate('/login')
        }
    }

    //submitreview
    const submitReview = () => {
        let creatorId = userInfo._id
        let data = {
            rating: rating,
            ratingFor: 'businessWebite',
            comment: ratingComment,
            creator_id: creatorId,
            receiver_id: businessDetails._id
        }
        props.makeRating(data)
    }

    // handle coupon click
    const handleCouponClick = (rewardtype) => {
        if (token !== null) {
            let couponData = {
                businessId: businessDetails._id,
                type: rewardtype === "coupon" ? "coupon" : "reward"
            }
            props.createAndGetCoupon(couponData)
        } else {
            navigate('/login')
        }
    }
    //    handle coupon direction click
    const sendOnDirectionPageForCouponModal = () => {
        let businessInfo = {
            destLat: businessDetails.latitude,
            desLong: businessDetails.longitude,
            businessName: businessDetails.name,
            address: businessDetails.address ? getRequiredAddress(businessDetails.address, 3).replaceAll(/ /g, "-").toLowerCase() : businessDetails.city
        }
        navigate(`/direction-to-business/${businessDetails.address}`, { state: businessInfo })
    }
    // send on all menu list page
    const handleAllMenu = () => {
        let productDetails = businessDetails.productDetails
        navigate(`/product-menu-details/${businessDetails.name}`, { state: { productDetails: productDetails, businessName: businessDetails.name } })
    }

    const handleDealClick = () => {
        if (!token) {
            navigate('/login')
        } else {
            let customerInfo = JSON.parse(localStorage.getItem('userInfo'));
            let mobileNo = customerInfo.country_code + customerInfo.mobile_no
            let customerName = customerInfo.name;
            let customerEmail = customerInfo.email;
            let data = {
                mobileNo: mobileNo,
                customerName: customerName,
                customerEmail: customerEmail,
                businessName: businessDetails.name,
                businessEmail: businessDetails.email,
                businessId: businessDetails._id,
                clickType: 'deal'
            }
            props.clickPerCount(data)
            window.open(`https://${businessDetails.website}`, '_blank', 'noopener,noreferrer')
        }
    }
    const handleBookAnAppointmentClick = () => {
        let businessInfo = {
            email: businessDetails.email,
            businessName: businessDetails.name,
            country_code: businessDetails.country_code,
            phone_no: businessDetails.phone_no,
            businessId: businessDetails._id,
            bookAppointmentService: businessDetails.bookAppointmentService
        }
        navigate(`/book-an-appointment/${businessDetails.name.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo });
    }
    const handleRequestAQuoteClick = () => {
        let businessInfo = {
            email: businessDetails.email,
            businessName: businessDetails.name,
            country_code: businessDetails.country_code,
            phone_no: businessDetails.mobile_no,
            businessId: businessDetails._id,
            requestQuoteService: businessDetails.requestQuoteService
        }
        navigate(`/request-a-quote/${businessDetails.name.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo });
    }


    return (
        <div className='business-details-page'>
            {/* title head */}
            <Helmet>
                <title>{businessDetails.name}</title>
            </Helmet>
            <ToastContainer />
            <div className='banner-section overflow-hidden'>
                {businessDetails.businessPicture && businessDetails.businessPicture.length > 0 ?
                    <Slider {...settings} className='custom-dots'>
                        {businessDetails.businessPicture && businessDetails.businessPicture.map((banner, index) => (
                            <div className='banner-cover' key={index}>
                                <img src={banner.pictureUrl} className='w-100 img-fluid object-fit-cover' alt='Cleaning' style={{ height: '60vh', display: 'initial' }} />
                            </div>
                        ))}
                    </Slider> :
                    <Slider {...settings} className='custom-dots'>
                        {BusinessImg.map((banner, index) => (
                            <div className='banner-cover' key={index}>
                                <img src={banner.image} className='w-100 img-fluid object-fit-cover' alt='Cleaning' style={{ height: '60vh', display: 'initial' }} />
                            </div>
                        ))}
                    </Slider>
                }

            </div>
            <div className='container mt-4 mb-5'>
                <div className='multiple-btn-wrapper d-flex flex-md-wrap gap-2 overflow-auto mb-3'>
                    <button className='bg-danger text-white border rounded-1 text-nowrap px-3 py-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#reviewModal" : ''} onClick={() => checkRatingModal()}><i className="bi bi-star me-1"></i> Write a review</button>
                    {businessDetails.isPhoneSelect ? <button type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='border rounded-1 text-nowrap px-3 py-2' onClick={() => handleCallClick()}><i className="bi bi-telephone me-1"></i> Call</button> : ''}
                    {businessDetails.isDirectionSelect ? <button className='border rounded-1 text-nowrap px-3 py-2' onClick={() => sendOnDirectionPage()}><i className="bi bi-signpost-split me-1"></i> Direction</button> : ''}
                    {businessDetails.isWebsiteSelect ? <button className='border rounded-1 text-nowrap px-3 py-2' onClick={() => sendOnWebsite('website')}><i className="bi bi-globe me-1"></i> Website</button> : ''}
                    {businessDetails.isEmailSelect ? <button className='border rounded-1 text-nowrap px-3 py-2' onClick={() => handleEmailClick()}><i className="bi bi-envelope me-1"></i>Email</button> : ''}
                    {businessDetails.isOrderNowSelect ? <button className='border rounded-1 text-nowrap px-3 py-2' onClick={() => sendOnWebsite('order')}><i className="bi bi-box2 me-1"></i> Order</button> : ''}
                    {
                        businessDetails.isCouponSelect ? businessDetails.couponText.includes('rewards') ?
                            <button className='border rounded-1 text-nowrap px-3 py-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                onClick={() => handleCouponClick('reward')}>
                                <i className="bi bi-file-earmark-text me-1"></i> {businessDetails.couponText ? businessDetails.couponText.split(',')[1] : 'Coupon message'}
                            </button> :
                            <button className='border rounded-1 text-nowrap px-3 py-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''}
                                onClick={() => handleCouponClick('coupon')}>
                                <i className="bi bi-file-earmark-text me-1"></i> {businessDetails.couponText ? businessDetails.couponText.split(',')[1] : 'Coupon message'}
                            </button>

                            : ''
                    }
                    {
                        businessDetails.isDealSelect ?
                            <button className='border rounded-1 text-nowrap px-3 py-2' onClick={() => handleDealClick()}><i className="bi bi-chat-dots me-1"></i>{businessDetails.dealText ? businessDetails.dealText.split(',')[1] : 'Deal message'}</button>
                            : ''
                    }
                    {
                        businessDetails.isCallForQuoteSelect ?
                            <button className='border rounded-1 text-nowrap px-3 py-2' onClick={handleRequestAQuoteClick}><i className="bi bi-envelope me-1"></i>Request a quote</button>
                            : ''
                    }
                    {
                        businessDetails.isBookForAppointmentSelect ?
                            <button className='border rounded-1 text-nowrap px-3 py-2' onClick={() => handleBookAnAppointmentClick()}>
                                <i className="bi bi-calendar2-week me-1"></i> Book An Appointment</button>
                            : ''
                    }
                    {/* infoDetails Modal */}
                    <div className="modal fade" id="infoDetails" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="infoDetailsLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content">
                                <div className="modal-body text-center p-4">
                                    <div className="link-item mb-3">
                                        <Link to={`tel:${businessDetails.country_code + businessDetails.mobile_no}`} className='fs-18 text-decoration-none text-primary'>
                                            <i className="bi bi-telephone-fill text-primary me-2"></i> {businessDetails.country_code + businessDetails.mobile_no}
                                        </Link>
                                    </div>
                                    <button type="button" className="btn btn-secondary px-5" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-12'>
                        {/* Business Shop section */}
                        <div className='business-info'>
                            <div className="section-label fs-2 text-capitalize text-start lh-sm mb-2">{businessDetails.name}</div>
                            <div className='name fw-semibold text-capitalize mb-1'>{businessDetails.subCategory && businessDetails.subCategory.length > 0 ? businessDetails.subCategory[0].subCategoryName : ''}</div>
                            <div className="review-wrapper d-flex gap-2 mb-1">
                                <div className="star-wrapper d-flex gap-1">
                                    {businessDetails.rating > 0 ?
                                        <Rating initialValue={businessDetails.rating} size={"20px"} readonly={true} allowFraction={true} />
                                        :
                                        <Rating initialValue={5} size={"20px"} readonly={true} />
                                    }
                                </div>
                                <div className='review-and-rating'>{businessDetails.rating > 0 ? businessDetails.rating.toFixed(1) : 5}
                                    <span className='ms-1'>({ratingList && ratingList.length > 0 ? ratingList.length : 1} Review)</span>
                                </div>
                            </div>
                            {Object.keys(businessDetails).length !== 0 ? businessDetails.isEcommerceSelect !== true ? <div className='timing'>{businessDetails.business_weekdays[dayString] == true ? `Open till ${businessDetails.business_weekdays[dayName].close_time_hour}:${businessDetails.business_weekdays[dayName].close_time_min} ${businessDetails.business_weekdays[dayName].close_time_am_pm}` : <span className='text-danger'>Closed now</span>}</div> : '' : ''}
                            {businessDetails.isEcommerceSelect !== true ? <div className='address'>{businessDetails.address ? getRequiredAddress(businessDetails.address, 1) : businessDetails.city}</div> : ''}
                        </div>
                        <hr className='my-4' />

                        {/* Business Description */}
                        <div className='business-desc'>
                            <h5 className='mb-2'>About the Business</h5>
                            <p>{businessDetails.businessDescription}</p>
                        </div>
                        <hr className='my-4' />

                        {/* Product List Section */}
                        <div className='product-list-desc'>
                            <h5 className='mb-3'>Menu or Product List</h5>
                            {Object.keys(businessDetails).length !== 0 && businessDetails.productDetails.slice(0, 1).map((ele, index) => (
                                <div className='menu-sections' key={ele._id}>
                                    {ele.categoryName ? <h5 className='menu-category text-capitalize fs-18 mb-2'>{ele.categoryName}</h5> : ''}
                                    {seeAllProduct ?
                                        ele.productList.map((products, i) => (
                                            <div className='list-inline menu-list' key={products._id}>
                                                {products.name !== '' ?
                                                    <div className='d-flex justify-content-between gap-2 border-top py-2'>
                                                        <div className='item-name text-capitalize'>{products.name}</div>
                                                        {
                                                            products.status === "none" || products.status === 'no_price' || products.status === 'call_for_quotes' ?
                                                                ''
                                                                : <div className='item-price'>${products.price}</div>
                                                        }
                                                    </div> :
                                                    ''
                                                }
                                            </div>
                                        ))
                                        :
                                        ele.productList.slice(0, 5).map((products, i) => (
                                            <div className='list-inline menu-list' key={products._id}>
                                                {products.name !== '' ?
                                                    <div className='d-flex justify-content-between gap-2 border-top py-2'>
                                                        <div className='item-name text-capitalize'>{products.name}</div>
                                                        {
                                                            products.status === "none" || products.status === 'no_price' || products.status === 'call_for_quotes' ?
                                                                ''
                                                                : <div className='item-price'>${products.price}</div>
                                                        }
                                                    </div> :
                                                    ''
                                                }
                                            </div>
                                        ))
                                    }
                                    {ele.productList.length > 5 ? seeAllProduct ? <div type='button' className='mt-3 fs-18' onClick={() => { setSeeAllProduct(false) }}>See Less....</div>
                                        : <div type='button' className='mt-3 fs-18' onClick={() => { setSeeAllProduct(true) }}>See More....</div> : ''}
                                </div>
                            ))}
                            {businessDetails.productDetails && businessDetails.productDetails.length > 1 ?
                                <h5 type='button' className='mt-4' onClick={() => handleAllMenu()}><i className='bi-menu-button-fill'></i> See All Menu....
                                </h5> : ""}
                        </div>
                        <hr className='my-4' />

                        {/* Business Map Section */}
                        {
                            businessDetails.isEcommerceSelect !== true ?
                                <div className='map-section'>
                                    <div className='row gx-lg-5'>
                                        <div className='col-lg-6'>
                                            <h5 className='mb-3'>Location Map</h5>
                                            <iframe src={`https://www.google.com/maps?q=${businessDetails.latitude},${businessDetails.longitude}&output=embed`} width="100%" height="250" style={{ border: 0, }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='Map'></iframe>
                                            <p className='fs-18 text-muted mb-0'>{businessDetails.address ? getRequiredAddress(businessDetails.address, 1) : businessDetails.city}</p>
                                        </div>
                                        <div className='col-lg-6 mt-4 mt-lg-0'>
                                            <h5 className='mb-3'>Hours of Operation</h5>
                                            {
                                                Object.keys(businessDetails).length !== 0 ?
                                                    <div className='open-close-time'>
                                                        <div className='d-flex mb-2'>
                                                            <span className='me-2 fw-medium' style={{ width: '100px' }}>Monday</span>
                                                            {businessDetails.business_weekdays.is_monday_Open ?
                                                                <span className='text-muted'>{businessDetails.business_weekdays['monday'].open_time_hour}:{businessDetails.business_weekdays['monday'].open_time_min} {businessDetails.business_weekdays['monday'].open_time_am_pm} -
                                                                    {businessDetails.business_weekdays['monday'].close_time_hour}:{businessDetails.business_weekdays['monday'].close_time_min} {businessDetails.business_weekdays['monday'].close_time_am_pm}</span>
                                                                :
                                                                <span className='text-danger'>Closed</span>
                                                            }
                                                        </div>
                                                        <div className='d-flex mb-2'>
                                                            <span className='me-2 fw-medium' style={{ width: '100px' }}>Tuesday</span>
                                                            {businessDetails.business_weekdays.is_tuesday_Open ?
                                                                <span className='text-muted'>{businessDetails.business_weekdays['tuesday'].open_time_hour}:{businessDetails.business_weekdays['tuesday'].open_time_min} {businessDetails.business_weekdays['tuesday'].open_time_am_pm} -
                                                                    {businessDetails.business_weekdays['tuesday'].close_time_hour}:{businessDetails.business_weekdays['tuesday'].close_time_min} {businessDetails.business_weekdays['tuesday'].close_time_am_pm}</span>
                                                                :
                                                                <span className='text-danger'>Closed</span>
                                                            }
                                                        </div>
                                                        <div className='d-flex mb-2'>
                                                            <span className='me-2 fw-medium' style={{ width: '100px' }}>Wednesday</span>
                                                            {businessDetails.business_weekdays.is_wednesday_Open ?
                                                                <span className='text-muted'>{businessDetails.business_weekdays['wednesday'].open_time_hour}:{businessDetails.business_weekdays['wednesday'].open_time_min} {businessDetails.business_weekdays['wednesday'].open_time_am_pm} -
                                                                    {businessDetails.business_weekdays['wednesday'].close_time_hour}:{businessDetails.business_weekdays['wednesday'].close_time_min} {businessDetails.business_weekdays['wednesday'].close_time_am_pm}</span>
                                                                :
                                                                <span className='text-danger'>Closed</span>
                                                            }
                                                        </div>
                                                        <div className='d-flex mb-2'>
                                                            <span className='me-2 fw-medium' style={{ width: '100px' }}>Thursday</span>
                                                            {businessDetails.business_weekdays.is_thursday_Open ?
                                                                <span className='text-muted'>{businessDetails.business_weekdays['thursday'].open_time_hour}:{businessDetails.business_weekdays['thursday'].open_time_min} {businessDetails.business_weekdays['thursday'].open_time_am_pm} -
                                                                    {businessDetails.business_weekdays['thursday'].close_time_hour}:{businessDetails.business_weekdays['thursday'].close_time_min} {businessDetails.business_weekdays['thursday'].close_time_am_pm}</span>
                                                                :
                                                                <span className='text-danger'>Closed</span>
                                                            }
                                                        </div>
                                                        <div className='d-flex mb-2'>
                                                            <span className='me-2 fw-medium' style={{ width: '100px' }}>Friday</span>
                                                            {businessDetails.business_weekdays.is_friday_Open ?
                                                                <span className='text-muted'>{businessDetails.business_weekdays['friday'].open_time_hour}:{businessDetails.business_weekdays['friday'].open_time_min} {businessDetails.business_weekdays['friday'].open_time_am_pm} -
                                                                    {businessDetails.business_weekdays['friday'].close_time_hour}:{businessDetails.business_weekdays['friday'].close_time_min} {businessDetails.business_weekdays['friday'].close_time_am_pm}</span>
                                                                :
                                                                <span className='text-danger'>Closed</span>
                                                            }
                                                        </div>
                                                        <div className='d-flex mb-2'>
                                                            <span className='me-2 fw-medium' style={{ width: '100px' }}>Saturday</span>
                                                            {businessDetails.business_weekdays.is_saturday_Open ?
                                                                <span className='text-muted'>{businessDetails.business_weekdays['saturday'].open_time_hour}:{businessDetails.business_weekdays['saturday'].open_time_min} {businessDetails.business_weekdays['saturday'].open_time_am_pm} -
                                                                    {businessDetails.business_weekdays['saturday'].close_time_hour}:{businessDetails.business_weekdays['saturday'].close_time_min} {businessDetails.business_weekdays['saturday'].close_time_am_pm}</span>
                                                                :
                                                                <span className='text-danger'>Closed</span>
                                                            }
                                                        </div>
                                                        <div className='d-flex mb-2'>
                                                            <span className='me-2 fw-medium' style={{ width: '100px' }}>Sunday</span>
                                                            {businessDetails.business_weekdays.is_sunday_Open ?
                                                                <span className='text-muted'>{businessDetails.business_weekdays['sunday'].open_time_hour}:{businessDetails.business_weekdays['sunday'].open_time_min} {businessDetails.business_weekdays['sunday'].open_time_am_pm} -
                                                                    {businessDetails.business_weekdays['sunday'].close_time_hour}:{businessDetails.business_weekdays['sunday'].close_time_min} {businessDetails.business_weekdays['sunday'].close_time_am_pm}</span>
                                                                :
                                                                <span className='text-danger'>Closed</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    : ''
                                            }

                                        </div>
                                    </div>
                                </div>
                                :
                                ''}

                        <hr className='my-4' />

                        {/* Amenities & More Section */}
                        <div className='amenities-section'>
                            <h5 className='mb-3'>Amenities & More</h5>
                            {
                                Object.keys(businessDetails).length !== 0 ?
                                    <div className="amenities-wrapper row row-cols-lg-3 row-cols-sm-2 mb-3">
                                        {businessDetails.amenities.includes('Offer Takeout') ? <div className="d-flex gap-2 mb-2"><i className="bi bi-box2-fill"></i> Offer Takeout</div> : ''}
                                        {businessDetails.amenities.includes('Offer Catering') ? <div className="d-flex gap-2 mb-2"><img src={catering} alt='Icon' style={{ width: '25px', marginTop: '-5px' }} /> Offer Catering</div> : ''}
                                        {businessDetails.amenities.includes('Good & Kids') ? <div className="d-flex gap-2 mb-2"><img src={require('../assets/icons/good-for-kids.png')} alt='Icon' style={{ height: '25px' }} /> Good & Kids</div> : ''}
                                        {businessDetails.amenities.includes('Free wi-fi') ? <div className="d-flex gap-2 mb-2"><i className="bi bi-router-fill"></i> Free Wi-Fi</div> : ''}
                                        {businessDetails.amenities.includes('TV') ? <div className="d-flex gap-2 mb-2"><i className="bi bi-display"></i> TV</div> : ''}
                                        {businessDetails.amenities.includes('Accept Credit Card') ? <div className="d-flex gap-2 mb-2"><i className="bi bi-credit-card"></i> Accept Credit Card </div> : ''}
                                        {businessDetails.amenities.includes('Waiter Service') ? <div className="d-flex gap-2 mb-2"><img src={require('../assets/icons/waiter.png')} alt='Icon' style={{ width: '15px', marginTop: '-7px' }} /> Waiter Service</div> : ''}
                                        {businessDetails.amenities.includes('Healt Score Green') ? <div className="d-flex gap-2 mb-2"><i className="bi bi-clipboard2-plus-fill"></i> Health Score Green</div> : ''}
                                        {businessDetails.amenities.includes('No Alchohal') ? <div className="d-flex gap-2 mb-2"><img src={require('../assets/icons/no-alcohol.png')} alt='Icon' style={{ width: '15px', marginTop: '-7px' }} /> No Alcohal</div> : ''}
                                        {businessDetails.amenities.includes('Offer Delivery') ? <div className="d-flex gap-2 mb-2"><i className="bi bi-truck"></i> Offer Delivery</div> : ''}
                                        {businessDetails.amenities.includes('Accept Apple Play') ? <div className="d-flex gap-2 mb-2"><i className="bi bi-apple"></i> Accept Apple Play</div> : ''}
                                        {businessDetails.amenities.includes('Wheel Chair Accessible') ? <div className="d-flex gap-2 mb-2"><img src={require('../assets/icons/wheelchair.png')} alt='Icon' style={{ width: '16px', marginTop: '-5px' }} /> Wheel chair Accesible</div> : ''}
                                        {businessDetails.amenities.includes('Private lot Parking') ? <div className="d-flex gap-2 mb-2"><i className="bi bi-p-square-fill"></i> Private Lot Parking</div> : ''}
                                        {businessDetails.amenities.includes('Bike Parking') ? <div className="d-flex gap-2 mb-2"><img src={require('../assets/icons/bike.png')} alt='Icon' style={{ width: '27px', marginTop: '-5px' }} /> Bike Parking</div> : ''}
                                        {businessDetails.amenities.includes('Virtual Constituation') ? <div className="d-flex gap-2 mb-2"><i className="bi bi-badge-vr-fill fs-18"></i> Virtual Constituation</div> : ''}
                                        {businessDetails.amenities.includes('Staff wear Mask') ? <div className="d-flex gap-2 mb-2"><i className="bi bi-mask"></i> Staff Wear Mask</div> : ''}
                                        {businessDetails.amenities.includes('Good for Kids') ? <div className="d-flex gap-2 mb-2"><img src={require('../assets/icons/good-for-kids.png')} alt='Icon' style={{ width: '16px', marginTop: '-5px' }} /> Good for Kids</div> : ''}
                                        {businessDetails.amenities.includes('Moderate Noise') ? <div className="d-flex gap-2 mb-2"><i className="bi bi-volume-down-fill"></i> Moderate Noise</div> : ''}
                                        {businessDetails.amenities.includes('Reservation Available') ? <div className="d-flex gap-2 mb-2"><img src={require('../assets/icons/reservation-available.png')} alt='Icon' style={{ width: '20px', marginTop: '-5px' }} /> Reservation Available</div> : ''}
                                    </div>
                                    : ''
                            }
                        </div>
                        <hr className='my-4' />

                        {/* Review Section */}
                        <div className='review-section'>
                            <h5 className='mb-3'>Recommended Reviews</h5>
                            <div className="card shadow border-0 p-4 mb-3">
                                <div className="row">
                                    <div className='col-lg-6'>
                                        <div className='d-flex align-items-center gap-3'>
                                            <div className='user-img'>
                                                <img className='img-fluid rounded-circle' src={require('../assets/Avatar.png')} alt='' style={{ width: '65px', height: '65px' }} />
                                            </div>
                                            <div className='user-info'>
                                                <div className='fs-18 fw-medium text-capitalize lh-sm'>Username</div>
                                                <div className='text-capitalize text-muted'>Location</div>
                                            </div>
                                        </div>

                                        <div className='overall-rating mt-3'>
                                            <h6 className='mb-1'>Overall Rating:</h6>
                                            <div className="review-wrapper d-flex flex-wrap me-2">
                                                <div className="star-wrapper d-flex gap-1">
                                                    {businessDetails.rating > 0 ?
                                                        <Rating initialValue={businessDetails.rating} size={"20px"} readonly={true} allowFraction={true} />
                                                        :
                                                        <Rating initialValue={5} size={"20px"} readonly={true} />
                                                    }
                                                </div>
                                                <div className='review-and-rating ms-2'>{businessDetails.rating > 0 ? businessDetails.rating.toFixed(1) : 5}
                                                    <span className='ms-1'>({ratingList && ratingList.length > 0 ? ratingList.length : 1} Review)</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 mt-3 mt-lg-0'>
                                        <div className="review-wrapper">
                                            <div className="star-wrapper d-flex gap-2 fs-5">
                                                <i className="bi bi-star-fill" style={{ color: '#dcdcdf' }}></i>
                                                <i className="bi bi-star-fill" style={{ color: '#dcdcdf' }}></i>
                                                <i className="bi bi-star-fill" style={{ color: '#dcdcdf' }}></i>
                                                <i className="bi bi-star-fill" style={{ color: '#dcdcdf' }}></i>
                                                <i className="bi bi-star-fill" style={{ color: '#dcdcdf' }}></i>
                                            </div>
                                            <p className='m-0 text-muted'>Start your review for <small className='fw-medium text-capitalize'>{businessDetails.name}</small></p>
                                            <button type='button' className='btn btn-primary rounded-1 fs-18 mt-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#reviewModal" : ''} onClick={() => checkRatingModal()}>Write a review</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='all-review-list mt-5'>
                                {ratingList && ratingList.map((details, index) => (
                                    <div className='review-item mt-5' key={index}>
                                        <div className='d-flex align-items-center gap-3 mb-3'>
                                            <div className='user-img'>
                                                <img className='img-fluid rounded-circle' src={(details.userInfo.profile_pic === '' || details.userInfo.profile_pic === "images/noimage.png") ? require('../assets/Avatar.png') : details.userInfo.profile_pic} alt='' style={{ width: '65px', height: '65px' }} />
                                            </div>
                                            <div className='user-info'>
                                                <div className='fs-18 fw-semibold text-capitalize lh-sm'>{details.userInfo.name}</div>
                                                <div className='text-capitalize text-muted'>{details.userInfo.city}{details.userInfo.state ? `/${details.userInfo.state}` : ''}{details.userInfo.country ? `/${details.userInfo.country}` : ''}</div>
                                            </div>
                                        </div>
                                        <div className="review-wrapper d-flex gap-2 mb-2">
                                            <div className="star-wrapper d-flex gap-1">
                                                <Rating initialValue={details.rating} size={"20px"} readonly={true} allowFraction={true} />
                                            </div>
                                            <div className='review-date'>{new Date(details.createdAt).toDateString()}</div>
                                        </div>
                                        <div className='review-desc mb-3'>{details.comment}</div>
                                        {/* <div className='review-img'>
                                            <div className='row g-2'>
                                                <div className='col-3 col-lg-2'>
                                                    <img className='img-fluid rounded' src={require('../assets/business-cafe.jpg')} alt='' />
                                                </div>
                                                <div className='col-3 col-lg-2'>
                                                    <img className='img-fluid rounded' src={require('../assets/business-cafe.jpg')} alt='' />
                                                </div>
                                                <div className='col-3 col-lg-2'>
                                                    <img className='img-fluid rounded' src={require('../assets/business-cafe.jpg')} alt='' />
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Review Model Popup */}
            <div className="modal fade" id="reviewModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="reviewModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-white px-sm-4">
                            <h1 className="modal-title fs-18 text-capitalize lh-sm" id="reviewModalLabel">{businessDetails.name}</h1>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body px-3 px-sm-4 py-4">
                            <div className="review-wrapper mb-2">
                                <div className="star-wrapper d-flex gap-2 fs-5 mb-1">
                                    <Rating emptySymbol="bi bi-star" fullSymbol="bi bi-star-fill"
                                        initialValue={rating}
                                        value={rating}
                                        onClick={(value) => setRating(value)}
                                    />
                                </div>
                                <p className='m-0 text-muted'>Write your review for <small className='fw-medium text-capitalize'>{businessDetails.name}</small></p>
                            </div>
                            <div className="review-message">
                                <textarea className="form-control small" id="dealMessage" name='dealMessage' rows="5" placeholder='Enter message here...' required
                                    onChange={(event) => setRatingComment(event.target.value)}
                                ></textarea>
                                <div className='invalid-feedback'></div>
                            </div>
                            <button className='w-100 btn btn-primary py-2 mt-3' data-bs-dismiss="modal" onClick={() => submitReview()}>Submit Review</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* coupon code with call and direction modal */}
            <div className="modal fade" id="couponModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="couponModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-white">
                            <h1 className="modal-title fs-18 text-capitalize lh-sm" id="couponModalLabel">Promo Code</h1>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body px-3 py-4">
                            <p className='fs-18 mb-0'>
                                Promo Code: <span className='fw-medium text-muted'>{props.couponDetails.coupon}</span>
                            </p>
                            <p className='fs-18 mb-2'>
                                Expire On: <span className='fw-medium text-muted'>{props.couponDetails.expiryDate ? new Date(props.couponDetails.expiryDate).toLocaleDateString('en-us') : ''}</span>
                            </p>
                            <p>
                                Makes sure you present your code to the cashier to redeem your coupon or when using the phone to redeem you coupon, read out the coupon code to the cashier and you successful redeem your coupon. ONE PROMO CODE PER ORDER (per purchase or per service). Multiple promo code per order will not be allowed.
                            </p>
                            <div className='d-flex gap-2'>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='w-50 bg-light border rounded-pill text-center py-2 px-3'><i className="bi bi-telephone-fill text-primary me-2"></i>Call</button>
                                <button type="button" className='w-50 bg-light border rounded-pill text-center py-2 px-3' data-bs-dismiss="modal" onClick={() => sendOnDirectionPageForCouponModal()}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* reward with call and direction modal */}
            <div className="modal fade" id="rewardModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="rewardModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-white">
                            <h1 className="modal-title fs-18 text-capitalize lh-sm" id="rewardModalLabel">Your Reward</h1>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body px-3 py-4">
                            <p className='fs-18 mb-0'>
                                Reward: <span className='fw-medium text-muted  mb-2'><Rating initialValue={props.couponDetails && props.couponDetails.rewardStar ? props.couponDetails.rewardStar : 0} size={"20px"} readonly={true} allowFraction={true} /></span>
                            </p>
                            <p className='fs-18 mb-2'>
                                Expire On: <span className='fw-medium text-muted'>{props.couponDetails.expiryDate ? new Date(props.couponDetails.expiryDate).toLocaleDateString('en-us') : ''}</span>
                            </p>
                            <p>
                                Make sure you present your rewards to the cashier to earn your points (stars) and redeem your credit or when ordering online, mention your name to the cashier to earn your star (point) and redeem your credit.
                            </p>
                            <div className='d-flex gap-2'>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='w-50 bg-light border rounded-pill text-center py-2 px-3'><i className="bi bi-telephone-fill text-primary me-2"></i>Call</button>
                                <button type="button" className='w-50 bg-light border rounded-pill text-center py-2 px-3' data-bs-dismiss="modal" onClick={() => sendOnDirectionPageForCouponModal()}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* loader section */}
            {props.loading ?
                <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                    style={{ zIndex: 1030 }}>
                    <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                </div>
                : ''}
        </div>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
        businessDetails: state.business.businessDetails,
        ratingList: state.rating.bussinessRatingListing,
        ratingSuccess: state.rating.success,
        couponDetails: state.rewardAndCoupon.couponDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getBusinessDetails: (data) => dispatch(actions.getBusinessDetails(data)),
        clickPerCount: (data) => dispatch(actions.clickPerCount(data)),
        getRatingList: (data) => dispatch(actions.getRatingList(data)),
        makeRating: (data) => dispatch(actions.makeRating(data)),
        createAndGetCoupon: (data) => dispatch(actions.createAndGetCoupon(data))
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(BusinessDetails))


