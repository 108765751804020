import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Helmet } from "react-helmet";
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import UserInfoForQuoteService from './UserInfoForQuoteService';

const vehicleBrandAndModal = [
    {
        name: 'Acura',
        modal: ['ILX', 'Integra', 'MDX', 'RDX', 'Rl', 'TL', 'TLX', 'TSX', 'ZSX']
    },
    {
        name: 'Alfa Romeo',
        modal: ['4C', 'Giulia', 'Stelvio']
    },
    {
        name: 'Audi',
        modal: ['A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'allroad', 'e-tron', 'Q3', 'Q4 e-tron', 'Q5', 'Q6', 'Q7', 'Q8', 'RS3', 'RS5', 'RS7', 'S3', 'S4', 'S5', 'S6', 'S7', 'S8', 'SQ5', 'SQ7', 'TT']
    },
    {
        name: 'BMW',
        modal: ['1 Series', '2 Series', '3 Series', '4 Series', '5 Series', '6 Series', '7 Series', '8 Series', 'i3', 'i4', 'M2', 'M3', 'M4', 'M5', 'M6', 'M7', 'M8', 'X1', 'X2', 'X3', 'X3 M', 'X4', 'X5', 'X5 M', 'X6', 'X6 M', 'X7', 'Z4']
    },
    {
        name: 'Buick',
        modal: ['Cascada', 'Enclave', 'Encore', 'Encore GX', 'Envision', 'LaCrosse', 'Lucerne', 'Regal', 'Verano']
    },
    {
        name: 'Cadillac',
        modal: ['ATS', 'ATS-V', 'CT4', 'CT5', 'CT6', 'CTS', 'DTS', 'ELR', 'Escalade', 'LYRIQ', 'SRX', 'XT4', 'XT5', 'XT6', 'XTS']
    },
    {
        name: 'Chevrolet',
        modal: ['Avalanche', 'Blazer', 'Bolt', 'Camaro', 'Captiva Sport', 'City Express', 'Cobalt', 'Colorado', 'Corvette', 'Cruze',
            'Equinox', 'Express 1500', 'Express 2500', 'Express 3500', 'HHR', 'Impala', 'Malibu', 'Silverado 1500', 'Silverado 2500', 'Silverado 3500',
            'Sonic', 'Spark', 'Spark EV', 'SS', 'Suburban 1500', 'Tahoe', 'Trailblazer', 'Traverse', 'Trax', 'Volt']
    },
    {
        name: 'Chrysler',
        modal: ['200', '300', 'Pacifica', 'Sebring', 'Town & Country', 'Voyager']
    },
    {
        name: 'Dodge',
        modal: ['Avenger', 'Caliber', 'Challenger', 'Charger', 'Dakota', 'Dart', 'Durango', 'Grand Caravan', 'Hornet', 'Journey', 'Ram 1500']
    },
    {
        name: 'Fiat',
        modal: ['124 Spider', '500', '500 Abarth', '500c Abarth', '500L', '500X']
    },
    {
        name: 'Fisker',
        modal: ['Ocean']
    },
    {
        name: 'Ford',
        modal: ['Bronco', 'Bronco Sport', 'C-MAX', 'C-350', 'EcoSport', 'Edge', 'Escape', 'Expedition', 'Explorer',
            'F-150 Lighting', 'F-250', 'F-350', 'Fiesta', 'Flex', 'Focus', 'Focus ST', 'Fusion', 'Maveric', 'Mustang',
            'Mustang MACH-E', 'Ranger', 'Taurus', 'Transit 150', 'Transit 250', 'Transit 350', 'Transit Connect']
    },
    {
        name: 'Genesis',
        modal: ['Electrified G80', 'G70', 'G80', 'G90', 'GV60', 'GV70', 'GV80']
    },
    {
        name: 'GMC',
        modal: ['Acadia', 'Canyon', 'Savana 3500', 'Sierra 1500', 'Sierra 2500', 'Sierra 3500', 'Terrain', 'Yukon']
    },
    {
        name: 'Honda',
        modal: ['Accord', 'Crosstour', 'Civic', 'Civic Type R', 'Clarity', 'CR-V', 'CR-Z', 'Element', 'Fit', 'HR-V', 'Insight',
            'Odyssey', 'Passport', 'Pilot', 'Ridgeline']
    },
    {
        name: 'Hyundai',
        modal: ['Accent', 'Azera', 'Elantra', 'Elantra N', 'Equus', 'Genesis', 'Genesis Coupe', 'IONIQ 5', 'IONIQ 6', 'Ioniq Electric', 'Ioniq',
            'Kona', 'Kona Electric', 'Kona N', 'Palisade', 'Santa Cruz', 'Santa Fe', 'Sonata', 'Tucson', 'Veloster', 'Venue']
    },
    {
        name: 'INFINITI',
        modal: ['EX', 'FX', 'G', 'JX', 'Q 40', 'Q 50', 'Q 60', 'Q 70', 'QX', 'QX 30', 'QX 50', 'QX 55', 'QX 60', 'QX 70', 'QX 80']
    },
    {
        name: 'Jaguar',
        modal: ['E-Pace', 'F-Pace', 'F-Type', 'I-Pace', 'XE', 'XF', 'XJ', 'XK']
    },
    {
        name: 'Jeep',
        modal: ['Cherokee', 'Compass', 'Gladiator', 'Grand Cherokee', 'Grand Wagoneer', 'Liberty', 'Patrioat', 'Renegade',
            'Wagoneer', 'Wrangler']
    },
    {
        name: 'KIA',
        modal: ['Cadenza', 'Carnival', 'EV6', 'Forte', 'K5', 'K900', 'Niro', 'Niro EV', 'Optima', 'Rio', 'Sedona', 'Seltos',
            'Sorento', 'Soul', 'Soul EV', 'Sportage', 'Stinger', 'Telluride']
    },
    {
        name: 'Land Rover',
        modal: ['Defender', 'Discovery', 'Discovery Sport', 'LR2', 'LR4', 'Range Rover', 'Range Rover Evoque', 'Range Rover Sport', 'Range Rover Velar']
    },
    {
        name: 'Lexus',
        modal: ['CT', 'ES', 'GS', 'GX', 'IS', 'LC', 'LS', 'LX', 'NX', 'RC', 'RX', 'RZ', 'UX']
    },
    {
        name: 'Lincoln',
        modal: ['Aviator', 'Continental', 'Corsair', 'MKC', 'MKS', 'MKT', 'MKS', 'MKZ', 'Nautilus', 'Navigator', 'Town Car']
    },
    {
        name: 'Lucid',
        modal: ['Air']
    },
    {
        name: 'Maserati',
        modal: ['Ghibli', 'GranTurismo', 'Grecale', 'Levante', 'Quattroporte']
    },
    {
        name: 'Mazda',
        modal: ['CX-3', 'CX-30', 'CX-5', 'CX-50', 'CX-7', 'CX-9', 'CX-90', 'MAZDA2', 'MAZDA3', 'MAZDA5', 'MAZDA6', 'MX-30', 'MX-5 Miata']
    },
    {
        name: 'Mercedes-Benz',
        modal: ['A-Class', 'B-Class', 'C-Class', 'CL-Class', 'CLA', 'CLS', 'E-Class', 'G-Class', 'GL-Class', 'GLA', 'GLB', 'GLC', 'GLE', 'GLK-Class', 'GLS',
            'M-Class', 'A-Class AMG', 'Mercedes-AMG C Class', 'Mercedes-AMG CLA', 'Mercedes-AMG E Class', 'Mercedes-AMG GLA',
            'Mercedes-AMG GLB', 'Mercedes-AMG GLC', 'Mercedes-AMG GLE', 'Mercedes-AMG GLS', 'Mercedes-AMG GT', 'Mercedes-AMG S Class',
            'Mercedes-AMG SLC', 'Mercedes-EQ EQB', 'Mercedes-EQ EQE', 'Mercedes-EQ EQE SUV', 'Mercedes-EQ EQS', 'Mercedes-Maybach S Class',
            'Metris', 'S-Class', 'SL', 'SLC', 'SLK']
    },
    {
        name: 'MINI',
        modal: ['Clubman', 'Convertible', 'Countryman', 'Coupe', 'Hardtop', 'Paceman', 'Roadster']
    },
    {
        name: 'Mitsubishi',
        modal: ['Eclipse', 'Eclipse Cross', 'i-MiEV', 'Lancer', 'Lancer Evolution', 'Mirage', 'Outlander', 'Outlander Sport']
    },
    {
        name: 'Nissan',
        modal: ['370Z', 'Altima', 'Ariya', 'Armada', 'cube', 'Frontier', 'JUKE', 'Kicks', 'LEAF', 'Maxima', 'Murano',
            'Pathfinder', 'Quest', 'Rogue', 'Rogue Sport', 'Sentra', 'Titan', 'Titan XD', 'Versa', 'Xterra']
    },
    {
        name: 'Polestar',
        modal: ['2']
    },
    {
        name: 'Porsche',
        modal: ['718 Boxter', '718 Cayman', '911', 'Boxster', 'Cayenne', 'Cayman', 'Macan', 'Panamera']
    },
    {
        name: 'Ram',
        modal: ['1500 Classic', '1500', '2500', '3500', 'ProMaster']
    },
    {
        name: 'Rivian',
        modal: ['R1S', 'R1T']
    },
    {
        name: 'Scion',
        modal: ['FR-S', 'iA', 'iM', 'iQ', 'tC', 'xB', 'xD']
    },
    {
        name: 'smart',
        modal: ['fortwo', 'fortwo electric drive']
    },
    {
        name: 'Subaru',
        modal: ['Ascent', 'BRZ', 'Crosstrek', 'Forester', 'Impreza', 'Legacy', 'Outback', 'Solterra', 'Tribeca', 'WRX', 'XV Crosstrek']
    },
    {
        name: 'Tesla',
        modal: ['Model 3', 'Model S', 'Model X', 'Model Y',]
    },
    {
        name: 'Toyota',
        modal: ['4 Runner', 'GR 86', 'Avalon', 'bZ4X', 'C-HR', 'Camry', 'Corolla', 'Corolla Cross', 'Crown', 'FJ Cruiser', 'GR Supra',
            'Highlander', 'Prius', 'Prius c', 'Prius v', 'RAV 4', 'Sequoia', 'Sienna', 'Tacoma', 'Tundra', 'Venza', 'Yaris']
    },
    {
        name: 'Volkswagen',
        modal: ['Arteon', 'Atlas', 'Beetle', 'CC', 'e-Golf', 'Eos', 'Jetta GLI', 'Golf', 'Golf GTI', 'Golf R', 'ID.4', 'Jetta', 'Jetta SportWagen',
            'New Beetle', 'Passat', 'Routan', 'Taos', 'Tiguan', 'Touareg']
    },
    {
        name: 'Volvo',
        modal: ['C30', 'C40 Recharge', 'C70', 'S40', 'S60', 'S80', 'S90', 'V60', 'V90', 'XC40', 'XC40 Recharge', 'XC60', 'XC70', 'XC90']
    },
]
const ExteriorColor = ['Black', 'Blue', 'Brown', 'Gold', 'Gray', 'Green', 'Orange', 'Red', 'Silver', 'White', 'Other']
const InteriorColor = ['Beige', 'Gray', 'Red', 'White', 'Other'];
const Features = ['360 Camera', 'Active Lane Keep Assistance', 'Adaptive Cruise Control', 'Alloy Wheels', 'Android Auto', 'Bottle Water', 'Apple CarPlay',
    'Automatic Emergency Braking', 'Automatic Self Parking', 'Blindspot Sensors', 'Bluetooth Music', 'Cooled Seates', 'Fold Down Rear Seats', 'GPS Navigation',
    'Hands Free Calling', 'Heads Up Display', 'Heated Mirror', 'Heated Seats', 'Heated Stearing Wheel', 'Home Link', 'Keyless Ignition', 'Lane Departure Warning',
    'Leather Interior', 'Park Assist', 'Power Adjustable Padals', 'Power Driver Seat', 'Power Mirrors', 'Power Passenger Seats', 'Power Sliding Doors', 'Power Tailgate/Hatch',
    'Premium Sound', 'Rear Seat Entertainment', 'Rear View Camera', 'Remote Start', 'Satellite Radio', 'Sunroof', 'Third Row Seat', 'Tilt Wheel', 'Tow Hitch', 'Trailer Brake Controller',
    'Trailer Towing Connector', 'USB Input', 'Wi-Fi Hot Spot', 'Xenon/HID/LED Headlamps/Taillamps'
]
const bodyType = ['SUV', 'Sedan', 'Truck', 'Coupe', 'Minivan', 'Convertible', 'Wagon', 'Hatchback', 'Not Sure', 'Other'];
const bodySize = ['Compact', 'Mid size', 'Full size', 'Not sure', 'Other'];
const mileage = ['0-50000', '51,000 to 100,000', '101,000 -150,000', 'Not sure', 'Other'];
const FuelType = ['Diesel', 'Electric', 'Gas', 'Hybrid', 'Plug Hybrid', 'Not sure', 'Other'];
const Drivetrain = ['4WD', 'AWD', 'FWD', 'RWD', 'Not sure', 'Other'];
const Transmissions = ['Automatic', 'Manual', 'Not sure', 'Other'];
const Cylinder = ['4 Cylinder', '6 Cylinder', '8 Cylinder', 'Not sure', 'Other'];
const ContactWay = ['Phone Call', 'Video call', 'Text', 'Message', 'Email', 'Chat', 'Other'];
const TradeInOrDownpayment = ['Trade in', '$1000', '$5000', '$10,000', 'Not sure', 'Other'];
const PaymentOption = ['Cash payment', 'Debit card', 'Credit card', 'Financing in full', 'Partial financing', 'Not sure', 'Other'];
const BudgetOption = ['$5000', '$10,000', '$20,000', '$30,000', '$40,000', 'Not sure', 'Other'];

const AutoDealership = (props) => {
    const [serviceData, setServiceData] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    let currentYear = new Date().getFullYear()
    const [vehicleStatus, setVehicleStatus] = useState('New');
    const [vehicleMake, setVehicleMake] = useState(vehicleBrandAndModal[0].name);
    const [vehicleModal, setVehicleModal] = useState(vehicleBrandAndModal[0].modal[0]);
    const [vehicleYear, setVehicleYear] = useState(`${currentYear}`);
    const [vehicleExteriorColor, setVehicleExteriorColor] = useState('Black');
    const [vehicleInteriorColor, setVehicleInteriorColor] = useState('Beige');
    const [vehicleFeatures, setVehicleFeatures] = useState([]);
    const [vehicleBodyType, setVehicleBodyType] = useState('');
    const [otherVehicleBodyType, setOtherVehicleBodyType] = useState(false);
    const [vehicleBodySize, setVehicleBodySize] = useState('');
    const [otherVehicleBodySize, setOtherVehicleBodySize] = useState(false);
    const [vehicleMileage, setVehicleMileage] = useState('');
    const [otherVehicleMileage, setOtherVehicleMileage] = useState(false);
    const [vehicleFuelType, setVehicleFuelType] = useState('');
    const [otherVehicleFuelType, setOtherVehicleFuelType] = useState(false);
    const [vehicleDrivetrain, setVehicleDrivetrain] = useState('');
    const [otherVehicleDrivetrain, setOtherVehicleDrivetrain] = useState(false);
    const [vehicleTransmissions, setVehicleTransmissions] = useState('');
    const [otherVehicleTransmissions, setOtherVehicleTransmissions] = useState(false);
    const [vehicleCylinder, setVehicleCylinder] = useState('');
    const [otherVehicleCylinder, setOtherVehicleCylinder] = useState(false);
    const [tradInOrDownpayment, setTradInOrDownpayment] = useState('');
    const [otherTradInOrDownpayment, setOtherTradInOrDownpayment] = useState(false);
    const [paymentOption, setPaymentOption] = useState('');
    const [otherPaymentOption, setOtherPaymentOption] = useState(false);
    const [budgetOption, setBudgetOption] = useState('');
    const [otherBudgetOption, setOtherBudgetOption] = useState(false);
    const [appointDate, setAppointDate] = useState(new Date());
    const [appointTime, setAppointTime] = useState('');
    const [contactMode, setContactMode] = useState('');
    const [otherContactMode, setOtherContactMode] = useState(false);
    const [otherInfo, setOtherInfo] = useState('')

    var newVehicleYear = [];
    var oldVehicleYear = [];
    for (let i = 0; i < 6; i++) {
        let nextYear = currentYear + i
        newVehicleYear.push(nextYear)
    }
    for (let i = 0; i < 50; i++) {
        let backYear = currentYear - i - 1
        oldVehicleYear.push(backYear)
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);

    const handleContactMode = (value) => {
        if (value === 'Other') {
            setOtherContactMode(true)
            setContactMode('')
        } else {
            setOtherContactMode(false)
            setContactMode(value)
        }
    }

    const handleMakeOfVehicle = (value) => {
        setVehicleMake(value)
        let modalName = vehicleBrandAndModal.find(item => item.name === value).modal[0]
        setVehicleModal(modalName)
    }
    const handleVehicleStatus = (value) => {
        setVehicleStatus(value)
        value === 'New' ? setVehicleYear(`${currentYear}`) : setVehicleYear(`${currentYear - 1}`)
    }

    const handleVehicleFeatures = (value) => {
        let updatedvehicleFeatures = [...vehicleFeatures]
        if (vehicleFeatures.includes(value)) {
            let valueIndex = updatedvehicleFeatures.indexOf(value)
            updatedvehicleFeatures.splice(valueIndex, 1)
            setVehicleFeatures(updatedvehicleFeatures)
        } else {
            updatedvehicleFeatures.push(value)
            setVehicleFeatures(updatedvehicleFeatures)
        }
    }

    const handleVehicleBodyType = (value) => {
        if (value === 'Other') {
            setOtherVehicleBodyType(true)
        } else {
            setVehicleBodyType(value)
            setOtherVehicleBodyType(false)
        }
    }

    const handleVehicleBodySize = (value) => {
        if (value === 'Other') {
            setOtherVehicleBodySize(true)
        } else {
            setVehicleBodySize(value)
            setOtherVehicleBodySize(false)
        }
    }

    const handleVehicleMileage = (value) => {
        if (value === 'Other') {
            setOtherVehicleMileage(true)
        } else {
            setVehicleMileage(value)
            setOtherVehicleMileage(false)
        }
    }

    const handleVehicleFuelType = (value) => {
        if (value === 'Other') {
            setOtherVehicleFuelType(true)
        } else {
            setVehicleFuelType(value)
            setOtherVehicleFuelType(false)
        }
    }

    const handleVehicleDrivetrain = (value) => {
        if (value === 'Other') {
            setOtherVehicleDrivetrain(true)
        } else {
            setVehicleDrivetrain(value)
            setOtherVehicleDrivetrain(false)
        }
    }

    const handleVehicleTransmissions = (value) => {
        if (value === 'Other') {
            setOtherVehicleTransmissions(true)
        } else {
            setVehicleTransmissions(value)
            setOtherVehicleTransmissions(false)
        }
    }

    const handleVehicleCylinder = (value) => {
        if (value === 'Other') {
            setOtherVehicleCylinder(true)
        } else {
            setVehicleCylinder(value)
            setOtherVehicleCylinder(false)
        }
    }

    const handleTradeIn = (value) => {
        if (value === 'Other') {
            setOtherTradInOrDownpayment(true)
        } else {
            setTradInOrDownpayment(value)
            setOtherTradInOrDownpayment(false)
        }
    }
    const handlePaymentOption = (value) => {
        if (value === 'Other') {
            setOtherPaymentOption(true)
        } else {
            setPaymentOption(value)
            setOtherPaymentOption(false)
        }
    }
    const handleBudget = (value) => {
        if (value === 'Other') {
            setOtherBudgetOption(true)
        } else {
            setBudgetOption(value)
            setOtherBudgetOption(false)
        }
    }

    const handleTimeChange = (event) => {
        var timeSplit = event.target.value.split(':'),
            hours,
            minutes,
            hours = timeSplit[0];
        minutes = timeSplit[1];
        // Check whether AM or PM
        let newformat = hours >= 12 ? 'PM' : 'AM';
        // Find current hour in AM-PM Format
        hours = hours % 12;

        // To display "0" as "12"
        hours = hours ? hours : 12;
        // minutes = minutes < 10 ? '0' + minutes : minutes;
        let selectedTime = hours + ':' + minutes + ' ' + newformat
        setAppointTime(selectedTime)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (vehicleStatus && vehicleMake && vehicleModal && vehicleYear && vehicleExteriorColor
            && vehicleInteriorColor && vehicleFeatures && vehicleBodyType && vehicleBodySize &&
            vehicleMileage && vehicleFuelType && vehicleDrivetrain && vehicleTransmissions &&
            vehicleCylinder && appointDate && contactMode && appointTime && tradInOrDownpayment &&
            budgetOption && paymentOption) {
            let UsDate = new Date(appointDate).toLocaleDateString("en-US")
            var quoteDetails = {
                "Requested Service": "Auto Dealership",
                "Used or New Vehicle?": vehicleStatus,
                "Make of the Vehicle?": vehicleMake,
                "Modal of the Vehicle": vehicleModal,
                "Year of the Vehicle": vehicleYear,
                "Exterior color of the vehicle": vehicleExteriorColor,
                "Interior color of the vehicle": vehicleInteriorColor,
                "Features of the vehicle": vehicleFeatures,
                "Body type of the vehicle": vehicleBodyType,
                "Size of vehicle": vehicleBodySize,
                "Mileage of the vehicle": vehicleMileage,
                "Fuel does the vehicle use": vehicleFuelType,
                "Drivetrain of the vehicle": vehicleDrivetrain,
                "Transmissions of the vehicle": vehicleTransmissions,
                "Cylinder of the vehicle": vehicleCylinder,
                "How much do you want to put down as a down payment or do you want to do a trade in?": tradInOrDownpayment,
                "Are you going to do financing or cash payment?": budgetOption,
                "What is your budget?": paymentOption,
                "What is the best way to contact you?": contactMode,
                "What date do you want to purchase the vehicle?": UsDate,
                "Best time for appointment": appointTime,
                "Any other information": otherInfo,
            }

            let data = {
                businessName: props.businessDetails.businessName,
                businessEmail: props.businessDetails.email,
                businessId: props.businessDetails.businessId,
                clickType: 'requestQuote',
                quoteDetails: quoteDetails
            }
            setServiceData(data)
            setIsSubmit(true)
        } else {
            toast.warning('Please Select All required field!', {
                autoClose: 700,
                hideProgressBar: true,
            })
        }
    }

    return (
        <>
            {!isSubmit ? (
                <div id="autoDealership" className='auto-dealership-sec card shadow-sm py-5 px-3 px-md-5 mt-4'>
                    <Helmet>
                        <title>Request Quote For Auto Dealership</title>
                    </Helmet>
                    <ToastContainer />
                    <div className="section-label fs-3 mb-4">Auto Dealership</div>
                    <div className="row gx-3">
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label htmlFor="vehicles" className="form-label">Select the vehicle?</label>
                                <select className="form-select" onChange={(e) => handleVehicleStatus(e.target.value)}>
                                    <option value='New'>New</option>
                                    <option value='Used'>Used</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label htmlFor="makeVehicles" className="form-label">Make of the Vehicles?</label>
                                <select className="form-select" onChange={(e) => handleMakeOfVehicle(e.target.value)}>
                                    {
                                        vehicleBrandAndModal.map((brand) => (
                                            <option key={brand.name} value={brand.name}>{brand.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label htmlFor="modelVehicles" className="form-label">Model of the Vehicles</label>
                                <select className="form-select" onChange={(e) => setVehicleModal(e.target.value)}>
                                    {
                                        vehicleBrandAndModal.find(value => value.name === vehicleMake)?.modal.map((e, i) => (
                                            <option key={e} value={e}>{e}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label htmlFor="vehicleYear" className="form-label">Year of the Vehicles</label>
                                <select className="form-select" onChange={(e) => setVehicleYear(e.target.value)}>
                                    {
                                        vehicleStatus === 'New' ?
                                            newVehicleYear?.map((e) => (
                                                <option key={e} value={e}>{e}</option>
                                            ))
                                            :
                                            oldVehicleYear?.map((e) => (
                                                <option key={e} value={e}>{e}</option>
                                            ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label htmlFor="vehiclesColor" className="form-label">What is the exterior color of the vehicle?</label>
                                <select className="form-select" onChange={(e) => setVehicleExteriorColor(e.target.value)}>
                                    {
                                        ExteriorColor.map((color) => (
                                            <option key={color} value={color}>{color}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label htmlFor="paymentMode" className="form-label">What is the interior color of the vehicle?</label>
                                <select className="form-select" onChange={(e) => setVehicleInteriorColor(e.target.value)}>
                                    {
                                        InteriorColor.map((color) => (
                                            <option key={color} value={color}>{color}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="chooseVehicleFeature" className="form-label fs-6 mb-2">Select the features you do like to have on your vehicle? </label>
                            <div className="d-flex flex-wrap gap-2">
                                {
                                    Features.map((value) => (
                                        <div className="form-check me-3" key={value}>
                                            <input className="form-check-input" type="checkbox" name="checkboxVehicleFeatures" id={value}
                                                onChange={() => handleVehicleFeatures(value)} />
                                            <label className="form-check-label" htmlFor={value}>{value}</label>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label fs-6 mb-2">What is the Body type of the vehicle?</label>
                            <div className="d-flex flex-wrap g-2">
                                {
                                    bodyType.map((vehicleBody) => (
                                        <div className="form-check me-3" key={vehicleBody}>
                                            <input className="form-check-input" type="radio" name="radioGroupForBodyType" id={vehicleBody}
                                                onClick={() => handleVehicleBodyType(vehicleBody)} />
                                            <label className="form-check-label" htmlFor={vehicleBody}>{vehicleBody}</label>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                otherVehicleBodyType ?
                                    <input type="text" id="chooseBodyType" className="form-control mt-2" placeholder='Enter your vehicle body type...' required
                                        onChange={(e) => setVehicleBodyType(e.target.value)} />
                                    : ''
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label fs-6 mb-2">What is the size of vehicle?</label>
                            <div className="d-flex flex-wrap g-2">
                                {
                                    bodySize.map((vehicleSize) => (
                                        <div className="form-check me-3" key={vehicleSize}>
                                            <input className="form-check-input" type="radio" name="radioGroupForBodySize" id={vehicleSize}
                                                onClick={() => handleVehicleBodySize(vehicleSize)} />
                                            <label className="form-check-label" htmlFor={vehicleSize}>{vehicleSize}</label>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                otherVehicleBodySize ?
                                    <input type="text" id="chooseBodySize" className="form-control mt-2" placeholder='Enter your vehicle size...' required
                                        onChange={(e) => setVehicleBodySize(e.target.value)} />
                                    : ''
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label fs-6 mb-2">Select the mileage of the vehicle</label>
                            <div className="d-flex flex-wrap g-2">
                                {
                                    mileage.map((mileageValue) => (
                                        <div className="form-check me-3" key={mileageValue}>
                                            <input className="form-check-input" type="radio" name="radioGroupForMileage" id={mileageValue}
                                                onClick={() => handleVehicleMileage(mileageValue)} />
                                            <label className="form-check-label" htmlFor={mileageValue}>{mileageValue}</label>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                otherVehicleMileage ?
                                    <input type="text" id="chooseMileage" className="form-control mt-2" placeholder='Enter your vehicle mileage...' required
                                        onChange={(e) => setVehicleMileage(e.target.value)} />
                                    : ''
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label fs-6 mb-2">What fuel does the vehicle use?</label>
                            <div className="d-flex flex-wrap g-2">
                                {
                                    FuelType.map((fuel) => (
                                        <div className="form-check me-3" key={fuel}>
                                            <input className="form-check-input" type="radio" name="radioGroupForFuel" id={fuel}
                                                onClick={() => handleVehicleFuelType(fuel)} />
                                            <label className="form-check-label" htmlFor={fuel}>{fuel}</label>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                otherVehicleFuelType ?
                                    <input type="text" id="chooseFuelType" className="form-control mt-2" placeholder='Enter your vehicle FuelType...' required
                                        onChange={(e) => setVehicleFuelType(e.target.value)} />
                                    : ''
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label fs-6 mb-2">What is the drivetrain of the vehicle?</label>
                            <div className="d-flex flex-wrap g-2">
                                {
                                    Drivetrain.map((fuel) => (
                                        <div className="form-check me-3" key={fuel}>
                                            <input className="form-check-input" type="radio" name="radioGroupForDrivetrain" id={fuel}
                                                onClick={() => handleVehicleDrivetrain(fuel)} />
                                            <label className="form-check-label" htmlFor={fuel}>{fuel}</label>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                otherVehicleDrivetrain ?
                                    <input type="text" id="chooseDrivetrain" className="form-control mt-2" placeholder='Enter your vehicle Drivetrain...' required
                                        onChange={(e) => setVehicleDrivetrain(e.target.value)} />
                                    : ''
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label fs-6 mb-2">What is the transmissions of the vehicle?</label>
                            <div className="d-flex flex-wrap g-2">
                                {
                                    Transmissions.map((trans) => (
                                        <div className="form-check me-3" key={trans}>
                                            <input className="form-check-input" type="radio" name="radioGroupForTransmissions" id={trans}
                                                onClick={() => handleVehicleTransmissions(trans)} />
                                            <label className="form-check-label" htmlFor={trans}>{trans}</label>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                otherVehicleTransmissions ?
                                    <input type="text" id="chooseTransmissions" className="form-control mt-2" placeholder='Enter your vehicle transmissions...' required
                                        onChange={(e) => setVehicleTransmissions(e.target.value)} />
                                    : ''
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label fs-6 mb-2">What is the cylinder of the vehicle?</label>
                            <div className="d-flex flex-wrap g-2">
                                {
                                    Cylinder.map((cylin) => (
                                        <div className="form-check me-3" key={cylin}>
                                            <input className="form-check-input" type="radio" name="radioGroupForCylinder" id={cylin}
                                                onClick={() => handleVehicleCylinder(cylin)} />
                                            <label className="form-check-label" htmlFor={cylin}>{cylin}</label>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                otherVehicleCylinder ?
                                    <input type="text" id="chooseCylinder" className="form-control mt-2" placeholder='Enter your vehicle Cylinder...' required
                                        onChange={(e) => setVehicleCylinder(e.target.value)} />
                                    : ''
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label fs-6 mb-2">How much do you want to put down as a down payment or do you want to do a trade in?</label>
                            <div className="d-flex flex-wrap g-2">
                                {
                                    TradeInOrDownpayment.map((tradeIn) => (
                                        <div className="form-check me-3" key={tradeIn}>
                                            <input className="form-check-input" type="radio" name="radioGroupForTradeIn" id={tradeIn}
                                                onClick={() => handleTradeIn(tradeIn)} />
                                            <label className="form-check-label" htmlFor={tradeIn}>{tradeIn}</label>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                otherTradInOrDownpayment ?
                                    <input type="text" id="chooseTradeIn" className="form-control mt-2" placeholder='Enter your Other option...' required
                                        onChange={(e) => setTradInOrDownpayment(e.target.value)} />
                                    : ''
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label fs-6 mb-2">Are you going to do financing or cash payment?</label>
                            <div className="d-flex flex-wrap g-2">
                                {
                                    PaymentOption.map((pay) => (
                                        <div className="form-check me-3" key={pay}>
                                            <input className="form-check-input" type="radio" name="radioGroupForPaymentOption" id={pay}
                                                onClick={() => handlePaymentOption(pay)} />
                                            <label className="form-check-label" htmlFor={pay}>{pay}</label>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                otherPaymentOption ?
                                    <input type="text" id="choosePaymentOption" className="form-control mt-2" placeholder='Enter your other option...' required
                                        onChange={(e) => setPaymentOption(e.target.value)} />
                                    : ''
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label fs-6 mb-2">What is your budget?</label>
                            <div className="d-flex flex-wrap g-2">
                                {
                                    BudgetOption.map((pay) => (
                                        <div className="form-check me-3" key={pay}>
                                            <input className="form-check-input" type="radio" name="radioGroupForBudget" id={pay}
                                                onClick={() => handleBudget(pay)} />
                                            <label className="form-check-label" htmlFor={pay}>{pay}</label>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                otherBudgetOption ?
                                    <input type="text" id="chooseBudget" className="form-control mt-2" placeholder='Enter your other option...' required
                                        onChange={(e) => setBudgetOption(e.target.value)} />
                                    : ''
                            }
                        </div>

                        <div className="mb-3">
                            <label htmlFor="chooseContactmode" className="form-label fs-6 mb-2">What is the best way to contact you?</label>
                            <div className="d-flex flex-wrap g-2">
                                {
                                    ContactWay.map((cntct) => (
                                        <div className="form-check me-3" key={cntct}>
                                            <input className="form-check-input" type="radio" name="radioGroupForcontact" id={cntct}
                                                onClick={() => handleContactMode(cntct)} />
                                            <label className="form-check-label" htmlFor={cntct}>{cntct}</label>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                otherContactMode ?
                                    <input type="text" className="form-control mt-2" id="otherContactMode" placeholder='Other contact...' required
                                        onChange={(e) => setContactMode(e.target.value)} />
                                    : ''
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label fs-6 mb-2">What date do you want to purchase the vehicle?</label>
                            <div className='date-picker-wrapper'>
                                <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={appointDate}
                                    onChange={(value) => setAppointDate(value)} minDate={new Date()}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="appointTime" className="form-label fs-6 mb-2">What is best time for appointment?</label>
                            <input type="time" id="appointTime" className="form-control" min="09:00" max="18:00" required
                                onChange={(e) => handleTimeChange(e)} />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="dealershipInfo" className="form-label">Any other information</label>
                            <textarea className="form-control" id="dealershipInfo" rows="4" placeholder='Any other info...' required
                                onChange={(e) => setOtherInfo(e.target.value)}></textarea>
                        </div>
                    </div>
                    <div className='text-danger small mb-2'>TO QUALIFY FOR A REBATE, RATE, COMMENT & SUBMIT RECEIPT OF TRANSECTIONS MADE TO sales@couponranking.com </div>
                    <div className="btn-wrapper">
                        <button type="submit" className="w-100 btn btn-primary"
                            onClick={(e) => handleSubmit(e)}>Next</button>
                    </div>
                    {props.loading ?
                        <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                            style={{ zIndex: 1030 }}>
                            <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                        </div>
                        : ''}
                </div>
            ) :
                (<UserInfoForQuoteService state={serviceData} />)
            }
        </>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clickPerCount: (data, toast, navigate) => dispatch(actions.clickPerCount(data, toast, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(AutoDealership))