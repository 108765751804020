import React, { useState } from 'react'
import { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import UserInfoForQuoteService from './UserInfoForQuoteService';

const vehicleBrandAndModal = [
    {
        name: 'Acura',
        modal: ['ILX', 'Integra', 'MDX', 'RDX', 'Rl', 'TL', 'TLX', 'TSX', 'ZSX']
    },
    {
        name: 'Alfa Romeo',
        modal: ['4C', 'Giulia', 'Stelvio']
    },
    {
        name: 'Audi',
        modal: ['A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'allroad', 'e-tron', 'Q3', 'Q4 e-tron', 'Q5', 'Q6', 'Q7', 'Q8', 'RS3', 'RS5', 'RS7', 'S3', 'S4', 'S5', 'S6', 'S7', 'S8', 'SQ5', 'SQ7', 'TT']
    },
    {
        name: 'BMW',
        modal: ['1 Series', '2 Series', '3 Series', '4 Series', '5 Series', '6 Series', '7 Series', '8 Series', 'i3', 'i4', 'M2', 'M3', 'M4', 'M5', 'M6', 'M7', 'M8', 'X1', 'X2', 'X3', 'X3 M', 'X4', 'X5', 'X5 M', 'X6', 'X6 M', 'X7', 'Z4']
    },
    {
        name: 'Buick',
        modal: ['Cascada', 'Enclave', 'Encore', 'Encore GX', 'Envision', 'LaCrosse', 'Lucerne', 'Regal', 'Verano']
    },
    {
        name: 'Cadillac',
        modal: ['ATS', 'ATS-V', 'CT4', 'CT5', 'CT6', 'CTS', 'DTS', 'ELR', 'Escalade', 'LYRIQ', 'SRX', 'XT4', 'XT5', 'XT6', 'XTS']
    },
    {
        name: 'Chevrolet',
        modal: ['Avalanche', 'Blazer', 'Bolt', 'Camaro', 'Captiva Sport', 'City Express', 'Cobalt', 'Colorado', 'Corvette', 'Cruze',
            'Equinox', 'Express 1500', 'Express 2500', 'Express 3500', 'HHR', 'Impala', 'Malibu', 'Silverado 1500', 'Silverado 2500', 'Silverado 3500',
            'Sonic', 'Spark', 'Spark EV', 'SS', 'Suburban 1500', 'Tahoe', 'Trailblazer', 'Traverse', 'Trax', 'Volt']
    },
    {
        name: 'Chrysler',
        modal: ['200', '300', 'Pacifica', 'Sebring', 'Town & Country', 'Voyager']
    },
    {
        name: 'Dodge',
        modal: ['Avenger', 'Caliber', 'Challenger', 'Charger', 'Dakota', 'Dart', 'Durango', 'Grand Caravan', 'Hornet', 'Journey', 'Ram 1500']
    },
    {
        name: 'Fiat',
        modal: ['124 Spider', '500', '500 Abarth', '500c Abarth', '500L', '500X']
    },
    {
        name: 'Fisker',
        modal: ['Ocean']
    },
    {
        name: 'Ford',
        modal: ['Bronco', 'Bronco Sport', 'C-MAX', 'C-350', 'EcoSport', 'Edge', 'Escape', 'Expedition', 'Explorer',
            'F-150 Lighting', 'F-250', 'F-350', 'Fiesta', 'Flex', 'Focus', 'Focus ST', 'Fusion', 'Maveric', 'Mustang',
            'Mustang MACH-E', 'Ranger', 'Taurus', 'Transit 150', 'Transit 250', 'Transit 350', 'Transit Connect']
    },
    {
        name: 'Genesis',
        modal: ['Electrified G80', 'G70', 'G80', 'G90', 'GV60', 'GV70', 'GV80']
    },
    {
        name: 'GMC',
        modal: ['Acadia', 'Canyon', 'Savana 3500', 'Sierra 1500', 'Sierra 2500', 'Sierra 3500', 'Terrain', 'Yukon']
    },
    {
        name: 'Honda',
        modal: ['Accord', 'Crosstour', 'Civic', 'Civic Type R', 'Clarity', 'CR-V', 'CR-Z', 'Element', 'Fit', 'HR-V', 'Insight',
            'Odyssey', 'Passport', 'Pilot', 'Ridgeline']
    },
    {
        name: 'Hyundai',
        modal: ['Accent', 'Azera', 'Elantra', 'Elantra N', 'Equus', 'Genesis', 'Genesis Coupe', 'IONIQ 5', 'IONIQ 6', 'Ioniq Electric', 'Ioniq',
            'Kona', 'Kona Electric', 'Kona N', 'Palisade', 'Santa Cruz', 'Santa Fe', 'Sonata', 'Tucson', 'Veloster', 'Venue']
    },
    {
        name: 'INFINITI',
        modal: ['EX', 'FX', 'G', 'JX', 'Q 40', 'Q 50', 'Q 60', 'Q 70', 'QX', 'QX 30', 'QX 50', 'QX 55', 'QX 60', 'QX 70', 'QX 80']
    },
    {
        name: 'Jaguar',
        modal: ['E-Pace', 'F-Pace', 'F-Type', 'I-Pace', 'XE', 'XF', 'XJ', 'XK']
    },
    {
        name: 'Jeep',
        modal: ['Cherokee', 'Compass', 'Gladiator', 'Grand Cherokee', 'Grand Wagoneer', 'Liberty', 'Patrioat', 'Renegade',
            'Wagoneer', 'Wrangler']
    },
    {
        name: 'KIA',
        modal: ['Cadenza', 'Carnival', 'EV6', 'Forte', 'K5', 'K900', 'Niro', 'Niro EV', 'Optima', 'Rio', 'Sedona', 'Seltos',
            'Sorento', 'Soul', 'Soul EV', 'Sportage', 'Stinger', 'Telluride']
    },
    {
        name: 'Land Rover',
        modal: ['Defender', 'Discovery', 'Discovery Sport', 'LR2', 'LR4', 'Range Rover', 'Range Rover Evoque', 'Range Rover Sport', 'Range Rover Velar']
    },
    {
        name: 'Lexus',
        modal: ['CT', 'ES', 'GS', 'GX', 'IS', 'LC', 'LS', 'LX', 'NX', 'RC', 'RX', 'RZ', 'UX']
    },
    {
        name: 'Lincoln',
        modal: ['Aviator', 'Continental', 'Corsair', 'MKC', 'MKS', 'MKT', 'MKS', 'MKZ', 'Nautilus', 'Navigator', 'Town Car']
    },
    {
        name: 'Lucid',
        modal: ['Air']
    },
    {
        name: 'Maserati',
        modal: ['Ghibli', 'GranTurismo', 'Grecale', 'Levante', 'Quattroporte']
    },
    {
        name: 'Mazda',
        modal: ['CX-3', 'CX-30', 'CX-5', 'CX-50', 'CX-7', 'CX-9', 'CX-90', 'MAZDA2', 'MAZDA3', 'MAZDA5', 'MAZDA6', 'MX-30', 'MX-5 Miata']
    },
    {
        name: 'Mercedes-Benz',
        modal: ['A-Class', 'B-Class', 'C-Class', 'CL-Class', 'CLA', 'CLS', 'E-Class', 'G-Class', 'GL-Class', 'GLA', 'GLB', 'GLC', 'GLE', 'GLK-Class', 'GLS',
            'M-Class', 'A-Class AMG', 'Mercedes-AMG C Class', 'Mercedes-AMG CLA', 'Mercedes-AMG E Class', 'Mercedes-AMG GLA',
            'Mercedes-AMG GLB', 'Mercedes-AMG GLC', 'Mercedes-AMG GLE', 'Mercedes-AMG GLS', 'Mercedes-AMG GT', 'Mercedes-AMG S Class',
            'Mercedes-AMG SLC', 'Mercedes-EQ EQB', 'Mercedes-EQ EQE', 'Mercedes-EQ EQE SUV', 'Mercedes-EQ EQS', 'Mercedes-Maybach S Class',
            'Metris', 'S-Class', 'SL', 'SLC', 'SLK']
    },
    {
        name: 'MINI',
        modal: ['Clubman', 'Convertible', 'Countryman', 'Coupe', 'Hardtop', 'Paceman', 'Roadster']
    },
    {
        name: 'Mitsubishi',
        modal: ['Eclipse', 'Eclipse Cross', 'i-MiEV', 'Lancer', 'Lancer Evolution', 'Mirage', 'Outlander', 'Outlander Sport']
    },
    {
        name: 'Nissan',
        modal: ['370Z', 'Altima', 'Ariya', 'Armada', 'cube', 'Frontier', 'JUKE', 'Kicks', 'LEAF', 'Maxima', 'Murano',
            'Pathfinder', 'Quest', 'Rogue', 'Rogue Sport', 'Sentra', 'Titan', 'Titan XD', 'Versa', 'Xterra']
    },
    {
        name: 'Polestar',
        modal: ['2']
    },
    {
        name: 'Porsche',
        modal: ['718 Boxter', '718 Cayman', '911', 'Boxster', 'Cayenne', 'Cayman', 'Macan', 'Panamera']
    },
    {
        name: 'Ram',
        modal: ['1500 Classic', '1500', '2500', '3500', 'ProMaster']
    },
    {
        name: 'Rivian',
        modal: ['R1S', 'R1T']
    },
    {
        name: 'Scion',
        modal: ['FR-S', 'iA', 'iM', 'iQ', 'tC', 'xB', 'xD']
    },
    {
        name: 'smart',
        modal: ['fortwo', 'fortwo electric drive']
    },
    {
        name: 'Subaru',
        modal: ['Ascent', 'BRZ', 'Crosstrek', 'Forester', 'Impreza', 'Legacy', 'Outback', 'Solterra', 'Tribeca', 'WRX', 'XV Crosstrek']
    },
    {
        name: 'Tesla',
        modal: ['Model 3', 'Model S', 'Model X', 'Model Y',]
    },
    {
        name: 'Toyota',
        modal: ['4 Runner', 'GR 86', 'Avalon', 'bZ4X', 'C-HR', 'Camry', 'Corolla', 'Corolla Cross', 'Crown', 'FJ Cruiser', 'GR Supra',
            'Highlander', 'Prius', 'Prius c', 'Prius v', 'RAV 4', 'Sequoia', 'Sienna', 'Tacoma', 'Tundra', 'Venza', 'Yaris']
    },
    {
        name: 'Volkswagen',
        modal: ['Arteon', 'Atlas', 'Beetle', 'CC', 'e-Golf', 'Eos', 'Jetta GLI', 'Golf', 'Golf GTI', 'Golf R', 'ID.4', 'Jetta', 'Jetta SportWagen',
            'New Beetle', 'Passat', 'Routan', 'Taos', 'Tiguan', 'Touareg']
    },
    {
        name: 'Volvo',
        modal: ['C30', 'C40 Recharge', 'C70', 'S40', 'S60', 'S80', 'S90', 'V60', 'V90', 'XC40', 'XC40 Recharge', 'XC60', 'XC70', 'XC90']
    },
]

const bodyType = ['SUV', 'Sedan', 'Truck', 'Coupe', 'Minivan', 'Convertible', 'Wagon', 'Hatchback', 'Not Sure', 'Other'];
const bodySize = ['Compact', 'Mid size', 'Full size', 'Not sure', 'Other'];
const mileage = ['0-50000', '51,000 to 100,000', '101,000 -150,000', 'Not sure', 'Other'];
const FuelType = ['Diesel', 'Electric', 'Gas', 'Hybrid', 'Plug Hybrid', 'Not sure', 'Other'];
const Drivetrain = ['4WD', 'AWD', 'FWD', 'RWD', 'Not sure', 'Other'];
const Transmissions = ['Automatic', 'Manual', 'Not sure', 'Other'];
const Cylinder = ['4 Cylinder', '6 Cylinder', '8 Cylinder', 'Not sure', 'Other'];
const ContactWay = ['Phone Call', 'Video call', 'Text', 'Message', 'Email', 'Chat', 'Other'];

const AutoRepair = (props) => {
    let currentYear = new Date().getFullYear()
    const [serviceData, setServiceData] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [repairService, setRepairService] = useState([]);
    const [batteryAndElectricSystemAns, setBatteryAndElectricSystem] = useState('');
    const [bAndESystemOther, setbAndESystemOther] = useState(false);
    const [vehicleYear, setVehicleYear] = useState(`${currentYear}`);
    const [vehicleBodyType, setVehicleBodyType] = useState('');
    const [otherVehicleBodyType, setOtherVehicleBodyType] = useState(false);
    const [vehicleBodySize, setVehicleBodySize] = useState('');
    const [otherVehicleBodySize, setOtherVehicleBodySize] = useState(false);
    const [vehicleMileage, setVehicleMileage] = useState('');
    const [otherVehicleMileage, setOtherVehicleMileage] = useState(false);
    const [vehicleFuelType, setVehicleFuelType] = useState('');
    const [otherVehicleFuelType, setOtherVehicleFuelType] = useState(false);
    const [vehicleDrivetrain, setVehicleDrivetrain] = useState('');
    const [otherVehicleDrivetrain, setOtherVehicleDrivetrain] = useState(false);
    const [vehicleTransmissions, setVehicleTransmissions] = useState('');
    const [otherVehicleTransmissions, setOtherVehicleTransmissions] = useState(false);
    const [vehicleCylinder, setVehicleCylinder] = useState('');
    const [otherVehicleCylinder, setOtherVehicleCylinder] = useState(false);
    const [brakeAns, setBrakeAns] = useState('')
    const [otherBrake, setOtherBrake] = useState(false)
    const [lightAns, setLightAns] = useState('')
    const [otherLight, setOtherLight] = useState(false)
    const [maintenanceAns, setMaintenanceAns] = useState('')
    const [otherMaintenance, setOtherMaintenance] = useState(false)
    const [wheelAndTiresAns, setWheelAndTiresAns] = useState('');
    const [otherWheelAndTires, setOtherWheelAndTires] = useState(false);
    const [windowAndMirrorAns, setWindowAndMirrorAns] = useState('');
    const [otherwindowAndMirror, setOtherWindowAndMirror] = useState(false);
    const [heatingAndAcAns, setHeatingAndAcAns] = useState('');
    const [otherHeatingAndAc, setOtherHeatingAndAc] = useState(false);
    const [streeringAns, setStreeringAns] = useState('');
    const [otherstreering, setOtherstreering] = useState(false);
    const [vehicleMake, setVehicleMake] = useState(vehicleBrandAndModal[0].name);
    const [vehicleModal, setVehicleModal] = useState(vehicleBrandAndModal[0].modal[0]);
    const [needTowingService, setNeedTowingService] = useState('')
    const [needShuttleSevice, setNeedShuttleSevice] = useState('')
    const [appointDate, setAppointDate] = useState(new Date());
    const [contactMode, setContactMode] = useState('');
    const [otherContactMode, setOtherContactMode] = useState(false);
    const [dropOffTime, setDropOffTime] = useState('');
    const [otherDetails, setOtherDetails] = useState('');

    var oldVehicleYear = [];
    for (let i = 0; i < 50; i++) {
        let backYear = currentYear - i
        oldVehicleYear.push(backYear)
    }

    useEffect(() => {
        //  scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);

    const handleRepairservice = (value) => {
        let updatedService = [...repairService]
        let IndexValue = updatedService.indexOf(value)
        if (updatedService.includes(value)) {
            updatedService.splice(IndexValue, 1)
            setRepairService(updatedService)
        } else {
            updatedService.push(value)
            setRepairService(updatedService)
        }
    }

    const handleMakeOfVehicle = (value) => {
        setVehicleMake(value)
        let modalName = vehicleBrandAndModal.find(item => item.name === value).modal[0]
        setVehicleModal(modalName)
    }
    //handle vehicle body type
    const handleVehicleBodyType = (value) => {
        if (value === 'Other') {
            setOtherVehicleBodyType(true)
        } else {
            setVehicleBodyType(value)
            setOtherVehicleBodyType(false)
        }
    }

    //handle bodysize
    const handleVehicleBodySize = (value) => {
        if (value === 'Other') {
            setOtherVehicleBodySize(true)
        } else {
            setVehicleBodySize(value)
            setOtherVehicleBodySize(false)
        }
    }
    // handle mileage
    const handleVehicleMileage = (value) => {
        if (value === 'Other') {
            setOtherVehicleMileage(true)
        } else {
            setVehicleMileage(value)
            setOtherVehicleMileage(false)
        }
    }

    //handle fueltype
    const handleVehicleFuelType = (value) => {
        if (value === 'Other') {
            setOtherVehicleFuelType(true)
        } else {
            setVehicleFuelType(value)
            setOtherVehicleFuelType(false)
        }
    }
    //handle Drivetrain
    const handleVehicleDrivetrain = (value) => {
        if (value === 'Other') {
            setOtherVehicleDrivetrain(true)
        } else {
            setVehicleDrivetrain(value)
            setOtherVehicleDrivetrain(false)
        }
    }

    //transmission
    const handleVehicleTransmissions = (value) => {
        if (value === 'Other') {
            setOtherVehicleTransmissions(true)
        } else {
            setVehicleTransmissions(value)
            setOtherVehicleTransmissions(false)
        }
    }

    // vehicle cylender
    const handleVehicleCylinder = (value) => {
        if (value === 'Other') {
            setOtherVehicleCylinder(true)
        } else {
            setVehicleCylinder(value)
            setOtherVehicleCylinder(false)
        }
    }

    // handle drop time
    const handleTimeChange = (event) => {
        var timeSplit = event.target.value.split(':'),
            hours,
            minutes,
            hours = timeSplit[0];
        minutes = timeSplit[1];
        // Check whether AM or PM
        let newformat = hours >= 12 ? 'PM' : 'AM';
        // Find current hour in AM-PM Format
        hours = hours % 12;

        // To display "0" as "12"
        hours = hours ? hours : 12;
        // minutes = minutes < 10 ? '0' + minutes : minutes;
        let selectedTime = hours + ':' + minutes + ' ' + newformat
        setDropOffTime(selectedTime)
    }

    const handleBatteryAndElectricSystem = (value) => {
        setbAndESystemOther(false)
        setBatteryAndElectricSystem(value)
    }

    //handle brake 
    const handleBrake = (value) => {
        setOtherBrake(false)
        setBrakeAns(value)
    }

    // handle Ligh
    const handleLight = (value) => {
        setOtherLight(false)
        setLightAns(value)
    }

    //handle maintenance
    const handleMaintenance = (value) => {
        setOtherMaintenance(false)
        setMaintenanceAns(value)
    }

    //handle Wheel and tires
    const handleWheelAndTires = (value) => {
        setOtherWheelAndTires(false)
        setWheelAndTiresAns(value)
    }

    // handle Windows and mirrors
    const handleWindowAndMirror = (value) => {
        setOtherWindowAndMirror(false)
        setWindowAndMirrorAns(value)
    }

    // handle Heating and air conditioning system
    const handleHeatingAndAc = (value) => {
        setOtherHeatingAndAc(false)
        setHeatingAndAcAns(value)
    }

    // handle Steering and suspension
    const handleSteering = (value) => {
        setOtherstreering(false)
        setStreeringAns(value)
    }

    const handleContactMode = (value) => {
        if (value === 'Other') {
            setOtherContactMode(true)
            setContactMode('')
        } else {
            setOtherContactMode(false)
            setContactMode(value)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (repairService.length !== 0 && vehicleMake && vehicleModal && vehicleYear &&
            vehicleBodyType && vehicleBodySize && vehicleMileage && vehicleFuelType &&
            vehicleDrivetrain && vehicleTransmissions && vehicleCylinder && appointDate && contactMode
            && dropOffTime && appointDate && contactMode) {
            let UsDate = new Date(appointDate).toLocaleDateString("en-US")
            var quoteDetails = {
                "Requested Service": "Auto Repair",
                "Make of the Vehicle?": vehicleMake,
                "Modal of the Vehicle": vehicleModal,
                "Year of the Vehicle": vehicleYear,
                "Body type of the vehicle": vehicleBodyType,
                "Size of vehicle": vehicleBodySize,
                "Mileage of the vehicle": vehicleMileage,
                "Fuel does the vehicle use": vehicleFuelType,
                "Drivetrain of the vehicle": vehicleDrivetrain,
                "Transmissions of the vehicle": vehicleTransmissions,
                "Cylinder of the vehicle": vehicleCylinder,
                "What is the best way to contact you?": contactMode,
                "When do you require this service?": UsDate,
                "What time do you want to drop off the vehicle?": dropOffTime,
                'What do you need repaired?': repairService
            }
            if (repairService.includes('Battery and electric system')) { quoteDetails['Battery and electric system'] = batteryAndElectricSystemAns }
            if (repairService.includes('Brake')) { quoteDetails['Brake'] = brakeAns }
            if (repairService.includes('Light')) { quoteDetails['Light'] = lightAns }
            if (repairService.includes('Maintenance')) { quoteDetails['Maintenance'] = maintenanceAns }
            if (repairService.includes('Wheel and tires')) { quoteDetails['Wheel and tires'] = wheelAndTiresAns }
            if (repairService.includes('Windows and mirrors')) { quoteDetails['Windows and mirrors'] = windowAndMirrorAns }
            if (repairService.includes('Heating and air conditioning system')) { quoteDetails['Heating and air conditioning system'] = heatingAndAcAns }
            if (repairService.includes('Steering and suspension')) { quoteDetails['Steering and suspension'] = streeringAns }
            if (needTowingService) { quoteDetails['Do you need towing service?'] = needTowingService }
            if (needShuttleSevice) { quoteDetails['Do you need shuttle service?'] = needShuttleSevice }
            if (otherDetails) { quoteDetails['Are there any other details you would like to share?'] = otherDetails }

            let data = {
                businessName: props.businessDetails.businessName,
                businessEmail: props.businessDetails.email,
                businessId: props.businessDetails.businessId,
                clickType: 'requestQuote',
                quoteDetails: quoteDetails
            }

            setServiceData(data)
            setIsSubmit(true)
        } else {
            toast.warning('Please Select All required field!', {
                autoClose: 700,
                hideProgressBar: true,
            })
        }
    }

    return (
        <>
            {
                !isSubmit ?
                    (
                        <div id="autoRepair" className='card shadow-sm py-4 py-md-5 px-3 px-md-5 mt-4'>
                            <Helmet>
                                <title>Request Quote For Auto Repair</title>
                            </Helmet>
                            <ToastContainer />
                            <div className="section-label fs-3 mb-4">Auto Repair</div>
                            <div className="mb-3">
                                <label className="form-label fs-6 mb-2">What do you need repaired?</label>
                                <div className="d-flex flex-wrap g-2">
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="checkbox" name="checkGroup" id="BES" required
                                            onChange={() => handleRepairservice('Battery and electric system')} />
                                        <label className="form-check-label" htmlFor="BES">Battery and electric system</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="checkbox" name="checkGroup" id="Brake"
                                            onChange={() => handleRepairservice('Brake')} />
                                        <label className="form-check-label" htmlFor="Brake">Brake</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="checkbox" name="checkGroup" id="ES"
                                            onChange={() => handleRepairservice('Exhaust System')} />
                                        <label className="form-check-label" htmlFor="ES">Exhaust System</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="checkbox" name="checkGroup" id="Light"
                                            onChange={() => handleRepairservice('Light')} />
                                        <label className="form-check-label" htmlFor="Light">Light</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="checkbox" name="checkGroup" id="Maintenance"
                                            onChange={() => handleRepairservice('Maintenance')} />
                                        <label className="form-check-label" htmlFor="Maintenance">Maintenance</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="checkbox" name="checkGroup" id="Transmission"
                                            onChange={() => handleRepairservice('Transmission')} />
                                        <label className="form-check-label" htmlFor="Transmission">Transmission</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="checkbox" name="checkGroup" id="WAT"
                                            onChange={() => handleRepairservice('Wheel and tires')} />
                                        <label className="form-check-label" htmlFor="WAT">Wheel and tires</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="checkbox" name="checkGroup" id="WAM"
                                            onChange={() => handleRepairservice('Windows and mirrors')} />
                                        <label className="form-check-label" htmlFor="WAM">Windows and mirrors </label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="checkbox" name="checkGroup" id="HACS"
                                            onChange={() => handleRepairservice('Heating and air conditioning system')} />
                                        <label className="form-check-label" htmlFor="HACS">Heating and air conditioning system</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="checkbox" name="checkGroup" id="SAS"
                                            onChange={() => handleRepairservice('Steering and suspension')} />
                                        <label className="form-check-label" htmlFor="SAS">Steering and suspension</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="checkbox" name="checkGroup" id="NS"
                                            onChange={() => handleRepairservice('Not sure')} />
                                        <label className="form-check-label" htmlFor="NS">Not sure</label>
                                    </div>
                                    {/* <div className="form-check me-3">
                            <input className="form-check-input" type="checkbox" name="checkGroup" id="OTH"
                             />
                            <label className="form-check-label" htmlFor="OTH">Other</label>
                        </div> */}
                                </div>
                                {/* 
                    <input type="text" className="form-control mt-2" id="chooseDrink" placeholder='Others...' required
                    /> */}


                            </div>

                            <div className="element-wrapper">
                                {/* If Choosen Battery and electric system */}
                                {repairService.includes('Battery and electric system') ?
                                    <div id="BES" className="mb-3">
                                        <div className="form-label fs-6 mb-2">Battery and electric system</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBES" id="Alternator" required
                                                    onChange={() => handleBatteryAndElectricSystem('Alternator')} />
                                                <label className="form-check-label" htmlFor="Alternator">Alternator</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBES" id="Battery"
                                                    onChange={() => handleBatteryAndElectricSystem('Battery')} />
                                                <label className="form-check-label" htmlFor="Battery">Battery</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBES" id="battery_cable"
                                                    onChange={() => handleBatteryAndElectricSystem('Battery cable')} />
                                                <label className="form-check-label" htmlFor="battery_cable">Battery cable</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBES" id="ignition_switch"
                                                    onChange={() => handleBatteryAndElectricSystem('Ignition switch')} />
                                                <label className="form-check-label" htmlFor="ignition_switch">Ignition switch</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBES" id="not_sure"
                                                    onChange={() => handleBatteryAndElectricSystem('Not sure')} />
                                                <label className="form-check-label" htmlFor="not_sure">Not sure</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBES" id="OTH"
                                                    onClick={() => setbAndESystemOther(true)} />
                                                <label className="form-check-label" htmlFor="OTH">Other</label>
                                            </div>
                                        </div>
                                        {
                                            bAndESystemOther ?
                                                <input type="text" className="form-control mt-2" id="OTH" placeholder='Other...' required
                                                    onChange={(e) => setBatteryAndElectricSystem(e.target.value)} />
                                                : ''
                                        }

                                    </div> :
                                    ''
                                }

                                {/* If Choosen Brake  */}
                                {repairService.includes('Brake') ?
                                    <div id="Brake" className="mb-3">
                                        <div className="form-label fs-6 mb-2">Brake</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBrake" id="ABS" required
                                                    onChange={() => handleBrake('ABS control module')} />
                                                <label className="form-check-label" htmlFor="ABS">ABS control module</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBrake" id="brake_line"
                                                    onChange={() => handleBrake('Brake line')} />
                                                <label className="form-check-label" htmlFor="brake_line">Brake line</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBrake" id="BMC"
                                                    onChange={() => handleBrake('Brake master cylinder')} />
                                                <label className="form-check-label" htmlFor="BMC">Brake master cylinder</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBrake" id="brake_pads"
                                                    onChange={() => handleBrake('Brake pads')} />
                                                <label className="form-check-label" htmlFor="brake_pads">Brake pads</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBrake" id="DR"
                                                    onChange={() => handleBrake('Drum and rotor')} />
                                                <label className="form-check-label" htmlFor="DR">Drum and rotor</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBrake" id="not_sure"
                                                    onChange={() => handleBrake('Not sure')} />
                                                <label className="form-check-label" htmlFor="not_sure">Not sure</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForBrake" id="OTH"
                                                    onClick={() => setOtherBrake(true)} />
                                                <label className="form-check-label" htmlFor="OTH">Other</label>
                                            </div>
                                        </div>
                                        {
                                            otherBrake ?
                                                <input type="text" className="form-control mt-2" id="OTH" placeholder='Other...' required
                                                    onChange={(e) => setBrakeAns(e.target.value)} />
                                                : ''
                                        }
                                    </div>
                                    : ''}

                                {/* If Choosen Heating and air conditioning system */}
                                {
                                    repairService.includes('Heating and air conditioning system') ?
                                        <div id="HACS" className="mb-3">
                                            <div className="form-label fs-6 mb-2">Heating and air conditioning system</div>
                                            <div className="d-flex flex-wrap g-2">
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForHACS" id="AC_compressor" required
                                                        onChange={() => handleHeatingAndAc('AC compressor')} />
                                                    <label className="form-check-label" htmlFor="AC_compressor">AC compressor</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForHACS" id="AC condenser"
                                                        onChange={() => handleHeatingAndAc('AC condenser')} />
                                                    <label className="form-check-label" htmlFor="AC condenser">AC condenser</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForHACS" id="AC_cevaporator"
                                                        onChange={() => handleHeatingAndAc('AC evaporator')} />
                                                    <label className="form-check-label" htmlFor="AC_cevaporator">AC evaporator</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForHACS" id="not_sure"
                                                        onChange={() => handleHeatingAndAc('Not sure')} />
                                                    <label className="form-check-label" htmlFor="not_sure">Not sure</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForHACS" id="OTH"
                                                        onClick={() => setOtherHeatingAndAc(true)} />
                                                    <label className="form-check-label" htmlFor="OTH">Other</label>
                                                </div>
                                            </div>
                                            {
                                                otherHeatingAndAc ?
                                                    <input type="text" className="form-control mt-2" id="OTH" placeholder='Other...' required
                                                        onChange={(e) => setHeatingAndAcAns(e.target.value)} />
                                                    : ''
                                            }

                                        </div>
                                        : ''
                                }


                                {/* If Choosen Light */}
                                {
                                    repairService.includes('Light') ?
                                        <div id="Light" className="mb-3">
                                            <div className="form-label fs-6 mb-2">Light</div>
                                            <div className="d-flex flex-wrap g-2">
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForLight" id="BLS" required
                                                        onChange={() => handleLight('Brake light switch')} />
                                                    <label className="form-check-label" htmlFor="BLS">Brake light switch</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForLight" id="headlight"
                                                        onChange={() => handleLight('Headlight')} />
                                                    <label className="form-check-label" htmlFor="headlight">Headlight</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForLight" id="taillight"
                                                        onChange={() => handleLight('Taillight')} />
                                                    <label className="form-check-label" htmlFor="taillight">Taillight</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForLight" id="NS"
                                                        onChange={() => handleLight('Not sure')} />
                                                    <label className="form-check-label" htmlFor="NS">Not sure</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForLight" id="Other"
                                                        onChange={() => setOtherLight(true)} />
                                                    <label className="form-check-label" htmlFor="Other">Other</label>
                                                </div>
                                            </div>
                                            {
                                                otherLight ?
                                                    <input type="text" className="form-control mt-2" id="" placeholder='Other...' required
                                                        onChange={(e) => setLightAns(e.target.value)} />
                                                    : ''
                                            }
                                        </div>
                                        : ''
                                }

                                {/* If Choosen Maintenance */}
                                {
                                    repairService.includes('Maintenance') ?
                                        <div id="Maintenance" className="mb-3">
                                            <div className="form-label fs-6 mb-2">Maintenance</div>
                                            <div className="d-flex flex-wrap g-2">
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForMaintenance" id="BFR" required
                                                        onChange={() => handleMaintenance('Battery and fluid recycling')} />
                                                    <label className="form-check-label" htmlFor="BFR">Battery and fluid recycling</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForMaintenance" id="FF"
                                                        onChange={() => handleMaintenance('Fluid and filters')} />
                                                    <label className="form-check-label" htmlFor="FF">Fluid and filters</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForMaintenance" id="FSC"
                                                        onChange={() => handleMaintenance('Fuel system cleaning')} />
                                                    <label className="form-check-label" htmlFor="FSC">Fuel system cleaning</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForMaintenance" id="oil_change"
                                                        onChange={() => handleMaintenance('Oil change')} />
                                                    <label className="form-check-label" htmlFor="oil_change">Oil change</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForMaintenance" id="RM"
                                                        onChange={() => handleMaintenance('Routine maintenance')} />
                                                    <label className="form-check-label" htmlFor="RM">Routine maintenance</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForMaintenance" id="WA"
                                                        onChange={() => handleMaintenance('Wheel alignment')} />
                                                    <label className="form-check-label" htmlFor="WA">Wheel alignment</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForMaintenance" id="Other"
                                                        onClick={() => setOtherMaintenance(true)} />
                                                    <label className="form-check-label" htmlFor="Other">Other</label>
                                                </div>
                                            </div>
                                            {
                                                otherMaintenance ?
                                                    <input type="text" className="form-control mt-2" id="" placeholder='Other...' required
                                                        onChange={(e) => setMaintenanceAns(e.target.value)}
                                                    />
                                                    : ''
                                            }
                                        </div>
                                        : ''
                                }

                                {/* If Choosen Steering and suspension */}
                                {
                                    repairService.includes('Steering and suspension') ?
                                        <div id="SAS" className="mb-3">
                                            <div className="form-label fs-6 mb-2">Steering and suspension</div>
                                            <div className="d-flex flex-wrap g-2">
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForSteering" id="CS" required
                                                        onChange={() => handleSteering('Coil spring')} />
                                                    <label className="form-check-label" htmlFor="CS">Coil spring</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForSteering" id="CA"
                                                        onChange={() => handleSteering('Control arm')} />
                                                    <label className="form-check-label" htmlFor="CA">Control arm</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForSteering" id="PSP"
                                                        onChange={() => handleSteering('Power steering pump')} />
                                                    <label className="form-check-label" htmlFor="PSP">Power steering pump</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForSteering" id="NS"
                                                        onChange={() => handleSteering('Not sure')} />
                                                    <label className="form-check-label" htmlFor="NS">Not sure</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForSteering" id="Other"
                                                        onClick={() => setOtherstreering(true)} />
                                                    <label className="form-check-label" htmlFor="Other">Other</label>
                                                </div>
                                            </div>
                                            {
                                                otherstreering ?
                                                    <input type="text" className="form-control mt-2" id="" placeholder='Other...' required
                                                        onChange={(e) => setStreeringAns(e.target.value)} />
                                                    : ''
                                            }

                                        </div>
                                        : ''
                                }

                                {/* If Wheel and tires */}
                                {
                                    repairService.includes('Wheel and tires') ?
                                        <div id="wheelAndTires" className="mb-3">
                                            <div className="form-label fs-6 mb-2">Wheel and tires</div>
                                            <div className="d-flex flex-wrap g-2">
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForWheel" id="TB" required
                                                        onChange={() => handleWheelAndTires('Tire balancing')} />
                                                    <label className="form-check-label" htmlFor="TB">Tire balancing</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForWheel" id="TI"
                                                        onChange={() => handleWheelAndTires('Tire installation')} />
                                                    <label className="form-check-label" htmlFor="TI">Tire installation</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForWheel" id="TPMS"
                                                        onChange={() => handleWheelAndTires('Tire pressure monitoring system')} />
                                                    <label className="form-check-label" htmlFor="TPMS">Tire pressure monitoring system</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForWheel" id="TR"
                                                        onChange={() => handleWheelAndTires('Tire repair')} />
                                                    <label className="form-check-label" htmlFor="TR">Tire repair</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForWheel" id="tire_rethreading"
                                                        onChange={() => handleWheelAndTires('Tire rethreading')} />
                                                    <label className="form-check-label" htmlFor="tire_rethreading">Tire rethreading</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForWheel" id="tire_rotation"
                                                        onChange={() => handleWheelAndTires('Tire rotation')} />
                                                    <label className="form-check-label" htmlFor="tire_rotation">Tire rotation</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForWheel" id="wheel_alignment"
                                                        onChange={() => handleWheelAndTires('Wheel alignment')} />
                                                    <label className="form-check-label" htmlFor="wheel_alignment">Wheel alignment</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForWheel" id="NA"
                                                        onChange={() => handleWheelAndTires('Not sure')} />
                                                    <label className="form-check-label" htmlFor="NA">Not sure</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForWheel" id="Other"
                                                        onClick={() => setOtherWheelAndTires(true)} />
                                                    <label className="form-check-label" htmlFor="Other">Other</label>
                                                </div>
                                            </div>
                                            {
                                                otherWheelAndTires ?
                                                    <input type="text" className="form-control mt-2" id="" placeholder='Other...' required
                                                    />
                                                    : ''
                                            }

                                        </div>
                                        : ''
                                }

                                {/* If Windows and mirrors */}
                                {repairService.includes('Windows and mirrors') ?
                                    <div id="windowsAndMirrors" className="mb-3">
                                        <div className="form-label fs-6 mb-2">Windows and mirrors </div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForWandM" id="DTR" required
                                                    onChange={() => handleWindowAndMirror('Defrost tab repair')} />
                                                <label className="form-check-label" htmlFor="DTR">Defrost tab repair</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForWandM" id="MR"
                                                    onChange={() => handleWindowAndMirror('Mirror repair')} />
                                                <label className="form-check-label" htmlFor="MR">Mirror repair</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForWandM" id="MR"
                                                    onChange={() => handleWindowAndMirror('Mirror replacement')} />
                                                <label className="form-check-label" htmlFor="MR">Mirror replacement</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForWandM" id="switch_replacement"
                                                    onChange={() => handleWindowAndMirror('Switch replacement')} />
                                                <label className="form-check-label" htmlFor="switch_replacement">Switch replacement</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForWandM" id="window_repair"
                                                    onChange={() => handleWindowAndMirror('Window repair')} />
                                                <label className="form-check-label" htmlFor="window_repair">Window repair</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForWandM" id="window_replacement"
                                                    onChange={() => handleWindowAndMirror('Window replacement')} />
                                                <label className="form-check-label" htmlFor="window_replacement">Window replacement</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForWandM" id="windshield_repair"
                                                    onChange={() => handleWindowAndMirror('Windshield repair')} />
                                                <label className="form-check-label" htmlFor="windshield_repair">Windshield repair</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForWandM" id="windshield_replacement"
                                                    onChange={() => handleWindowAndMirror('Windshield replacement')} />
                                                <label className="form-check-label" htmlFor="windshield_replacement">Windshield replacement</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForWandM" id="wiper_replacement"
                                                    onChange={() => handleWindowAndMirror('Wiper replacement')} />
                                                <label className="form-check-label" htmlFor="wiper_replacement">Wiper replacement</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForWandM" id="NA"
                                                    onChange={() => handleWindowAndMirror('Not sure')} />
                                                <label className="form-check-label" htmlFor="NA">Not sure</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForWandM" id="Other"
                                                    onClick={() => setOtherWindowAndMirror(true)} />
                                                <label className="form-check-label" htmlFor="Other">Other</label>
                                            </div>
                                        </div>
                                        {
                                            otherwindowAndMirror ?
                                                <input type="text" className="form-control mt-2" id="" placeholder='Other...' required
                                                    onChange={(e) => setWindowAndMirrorAns(e.target.value)} />
                                                : ''
                                        }
                                    </div>
                                    : ''
                                }
                            </div>

                            <div className="row gx-3 mb-1">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="makeVehicles" className="form-label">Make of the Vehicles?</label>
                                    <select className="form-select" onChange={(e) => handleMakeOfVehicle(e.target.value)}>
                                        {
                                            vehicleBrandAndModal.map((brand) => (
                                                <option key={brand.name} value={brand.name}>{brand.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="modelVehicles" className="form-label">Model of the Vehicles</label>
                                    <select className="form-select" onChange={(e) => setVehicleModal(e.target.value)}>
                                        {
                                            vehicleBrandAndModal.find(value => value.name === vehicleMake)?.modal.map((e, i) => (
                                                <option key={e} value={e}>{e}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label className="form-label fs-6 mb-2">Select the year of the vehicle? </label>
                                    <select className="form-select" onChange={(e) => setVehicleYear(e.target.value)}>
                                        {
                                            oldVehicleYear?.map((e) => (
                                                <option key={e} value={e}>{e}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-12 mb-2">
                                    <label className="form-label fs-6 mb-2">What is the Body type of the vehicle?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        {
                                            bodyType.map((vehicleBody) => (
                                                <div className="form-check me-3" key={vehicleBody}>
                                                    <input className="form-check-input" type="radio" name="radioGroupForBodyType" id={vehicleBody}
                                                        onClick={() => handleVehicleBodyType(vehicleBody)} />
                                                    <label className="form-check-label" htmlFor={vehicleBody}>{vehicleBody}</label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {
                                        otherVehicleBodyType ?
                                            <input type="text" id="chooseBodyType" className="form-control mt-2" placeholder='Enter your vehicle body type...' required
                                                onChange={(e) => setVehicleBodyType(e.target.value)} />
                                            : ''
                                    }
                                </div>
                                <div className="mb-3">
                                    <label className="form-label fs-6 mb-2">What is the size of vehicle?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        {
                                            bodySize.map((vehicleSize) => (
                                                <div className="form-check me-3" key={vehicleSize}>
                                                    <input className="form-check-input" type="radio" name="radioGroupForBodySize" id={vehicleSize}
                                                        onClick={() => handleVehicleBodySize(vehicleSize)} />
                                                    <label className="form-check-label" htmlFor={vehicleSize}>{vehicleSize}</label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {
                                        otherVehicleBodySize ?
                                            <input type="text" id="chooseBodySize" className="form-control mt-2" placeholder='Enter your vehicle size...' required
                                                onChange={(e) => setVehicleBodySize(e.target.value)} />
                                            : ''
                                    }
                                </div>
                                <div className="mb-3">
                                    <label className="form-label fs-6 mb-2">Select the mileage of the vehicle</label>
                                    <div className="d-flex flex-wrap g-2">
                                        {
                                            mileage.map((mileageValue) => (
                                                <div className="form-check me-3" key={mileageValue}>
                                                    <input className="form-check-input" type="radio" name="radioGroupForMileage" id={mileageValue}
                                                        onClick={() => handleVehicleMileage(mileageValue)} />
                                                    <label className="form-check-label" htmlFor={mileageValue}>{mileageValue}</label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {
                                        otherVehicleMileage ?
                                            <input type="text" id="chooseMileage" className="form-control mt-2" placeholder='Enter your vehicle mileage...' required
                                                onChange={(e) => setVehicleMileage(e.target.value)} />
                                            : ''
                                    }
                                </div>
                                <div className="mb-3">
                                    <label className="form-label fs-6 mb-2">What fuel does the vehicle use?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        {
                                            FuelType.map((fuel) => (
                                                <div className="form-check me-3" key={fuel}>
                                                    <input className="form-check-input" type="radio" name="radioGroupForFuel" id={fuel}
                                                        onClick={() => handleVehicleFuelType(fuel)} />
                                                    <label className="form-check-label" htmlFor={fuel}>{fuel}</label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {
                                        otherVehicleFuelType ?
                                            <input type="text" id="chooseFuelType" className="form-control mt-2" placeholder='Enter your vehicle FuelType...' required
                                                onChange={(e) => setVehicleFuelType(e.target.value)} />
                                            : ''
                                    }
                                </div>
                                <div className="mb-3">
                                    <label className="form-label fs-6 mb-2">What is the drivetrain of the vehicle?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        {
                                            Drivetrain.map((fuel) => (
                                                <div className="form-check me-3" key={fuel}>
                                                    <input className="form-check-input" type="radio" name="radioGroupForDrivetrain" id={fuel}
                                                        onClick={() => handleVehicleDrivetrain(fuel)} />
                                                    <label className="form-check-label" htmlFor={fuel}>{fuel}</label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {
                                        otherVehicleDrivetrain ?
                                            <input type="text" id="chooseDrivetrain" className="form-control mt-2" placeholder='Enter your vehicle Drivetrain...' required
                                                onChange={(e) => setVehicleDrivetrain(e.target.value)} />
                                            : ''
                                    }
                                </div>
                                <div className="mb-3">
                                    <label className="form-label fs-6 mb-2">What is the transmissions of the vehicle?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        {
                                            Transmissions.map((trans) => (
                                                <div className="form-check me-3" key={trans}>
                                                    <input className="form-check-input" type="radio" name="radioGroupForTransmissions" id={trans}
                                                        onClick={() => handleVehicleTransmissions(trans)} />
                                                    <label className="form-check-label" htmlFor={trans}>{trans}</label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {
                                        otherVehicleTransmissions ?
                                            <input type="text" id="chooseTransmissions" className="form-control mt-2" placeholder='Enter your vehicle transmissions...' required
                                                onChange={(e) => setVehicleTransmissions(e.target.value)} />
                                            : ''
                                    }
                                </div>
                                <div className="mb-3">
                                    <label className="form-label fs-6 mb-2">What is the cylinder of the vehicle?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        {
                                            Cylinder.map((cylin) => (
                                                <div className="form-check me-3" key={cylin}>
                                                    <input className="form-check-input" type="radio" name="radioGroupForCylinder" id={cylin}
                                                        onClick={() => handleVehicleCylinder(cylin)} />
                                                    <label className="form-check-label" htmlFor={cylin}>{cylin}</label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {
                                        otherVehicleCylinder ?
                                            <input type="text" id="chooseCylinder" className="form-control mt-2" placeholder='Enter your vehicle Cylinder...' required
                                                onChange={(e) => setVehicleCylinder(e.target.value)} />
                                            : ''
                                    }
                                </div>
                                <div className="col-12 mb-2">
                                    <div className="d-flex flex-wrap align-items-center">
                                        <label className="form-label fs-6 me-3">Do you need towing service?</label>
                                        <div className="d-flex flex-wrap">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="radioGroupForTowing" id="Yes" required
                                                    onClick={() => setNeedTowingService('Yes')} />
                                                <label className="form-check-label" htmlFor="Yes">Yes</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="radioGroupForTowing" id="No"
                                                    onClick={() => setNeedTowingService('No')} />
                                                <label className="form-check-label" htmlFor="No">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mb-2">
                                    <div className="d-flex flex-wrap align-items-center">
                                        <label className="form-label fs-6 me-3">Do you need shuttle service?</label>
                                        <div className="d-flex flex-wrap">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="radioGroupForShuttle" id="Yes1" required
                                                    onClick={() => setNeedShuttleSevice('Yes')} />
                                                <label className="form-check-label" htmlFor="Yes1">Yes</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="radioGroupForShuttle" id="No1"
                                                    onClick={() => setNeedShuttleSevice('No')} />
                                                <label className="form-check-label" htmlFor="No1">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="chooseContactmode" className="form-label fs-6 mb-2">What is the best way to contact you?</label>
                                <div className="d-flex flex-wrap g-2">
                                    {
                                        ContactWay.map((cntct) => (
                                            <div className="form-check me-3" key={cntct}>
                                                <input className="form-check-input" type="radio" name="radioGroupForcontact" id={cntct}
                                                    onClick={() => handleContactMode(cntct)} />
                                                <label className="form-check-label" htmlFor={cntct}>{cntct}</label>
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    otherContactMode ?
                                        <input type="text" className="form-control mt-2" id="otherContactMode" placeholder='Other contact...' required
                                            onChange={(e) => setContactMode(e.target.value)} />
                                        : ''
                                }
                            </div>

                            <div className="mb-3">
                                <label className="form-label fs-6 mb-2">When do you require this service?</label>
                                <div className='date-picker-wrapper'>
                                    <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={appointDate}
                                        onChange={(value) => setAppointDate(value)} minDate={new Date()}
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="dropOffTime" className="form-label fs-6 mb-2">What time do you want to drop off the vehicle?</label>
                                <input type="time" id="dropOffTime" className="form-control" min="09:00" max="18:00" required
                                    onChange={(e) => handleTimeChange(e)} />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="otherInfo" className="form-label fs-6 mb-2">Are there any other details you'd like to share?</label>
                                <textarea className="form-control" id="otherInfo" rows="4" placeholder='Write your description here...' required
                                    onChange={(e) => setOtherDetails(e.target.value)}></textarea>
                            </div>
                            <div className='text-danger small mb-2'>TO QUALIFY FOR A REBATE, RATE, COMMENT & SUBMIT RECEIPT OF TRANSECTIONS MADE TO sales@couponranking.com </div>
                            <div className="btn-wrapper">
                                <button type="submit" className="w-100 btn btn-primary" onClick={(e) => handleSubmit(e)}>Next</button>
                            </div>
                            {props.loading ?
                                <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                                    style={{ zIndex: 1030 }}>
                                    <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                                </div>
                                : ''}
                        </div>
                    )
                    : (<UserInfoForQuoteService state={serviceData} />)
            }
        </>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clickPerCount: (data, toast, navigate) => dispatch(actions.clickPerCount(data, toast, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(AutoRepair))