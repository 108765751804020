import React, { useState, useRef } from 'react';
import { Helmet } from "react-helmet";
import Banner3 from '../assets/bg-banner3.jpg';
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from 'react-google-autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserInfoForBookAppointment from '../components/UserInfoForBookAppointment';
import {
    BeautySalonServices, DentalService, AutoRepairService, AutoDetailingService, CleanServices,
    HandymanServices, LawyerServices, WeddingPlannerService, BusinessCousetingService
} from "../helper/BookAppointmentServices";
import Geocode from "react-geocode";
import { Button } from 'react-bootstrap';
Geocode.setApiKey("AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q");
Geocode.enableDebug();
const PaymentMethod = ["Cash app", "Zelle", "Cash", "Check", "Debit card", "Credit Card", "Insurance", "Not Sure", "Other"];
const ContactWay = ['Phone Call', 'Video call', 'Text', 'Message', 'Email', 'Chat', 'Other'];
const ServiceFrequency = ['Once', 'Once a week', 'Bi- weekly', 'Monthly', 'Quarterly', 'Yearly', "Not Sure", "Other"];
const BudgetData = ["$5.00 - $49.99 ", "$50.00 - $299.99", "$300.00 - $699.99", "$700.00 - $999.99", "$1000.00 - $1999.99", "Not Sure", "Other"];

const BookAppointment = (props) => {
    let navigate = useNavigate();
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);
    const { clickPerCount } = props
    const { state } = useLocation();
    const bookAppointmentService = state?.bookAppointmentService;
    const ref = useRef(null);
    const [serviceData, setServiceData] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [appointDate, setAppointDate] = useState(new Date());
    const [appointTime, setAppointTime] = useState('');
    const [appointService, setAppointService] = useState('');
    const [isOtherService, setIsOtherService] = useState(false)
    const [subService, setSubService] = useState([]);
    const [isOtherSubService, setIsOtherSubService] = useState(false)
    const [salonOtherAns, setSalonOtherAns] = useState('')
    const [massageOtherAns, massageSalonOtherAns] = useState('');
    const [massageSalonOtherAnsOther, setMassageSalonOtherAnsOther] = useState(false);
    const [physicalTherapyOtherAns1, setPhysicalTherapyOtherAns1] = useState('');
    const [physicalTherapyOtherAnsOther1, setPhysicalTherapyAnsOther1] = useState(false);
    const [physicalTherapyOtherAns2, setPhysicalTherapyOtherAns2] = useState('');
    const [physicalTherapyTreatmentNeed, setPhysicalTherapyTreatmentNeed] = useState('');
    const [physicalTherapyTreatmentAddress, setPhysicalTherapyTreatmentAddress] = useState('');
    const [physicalTherapyOtherAnsOther2, setPhysicalTherapyAnsOther2] = useState(false);
    const [autoDetailingotherans1, setAutoDetailsAns1] = useState('');
    const [autoDetailingotherans2, setAutoDetailsAns2] = useState('');
    const [homeService, setHomeService] = useState('');
    const [customerAddress, setCustomerAddress] = useState('');
    const [payment, setPaymentMethod] = useState('');
    const [otherPaymentMethod, setOtherPaymentMethod] = useState(false);
    const [contactMode, setContactMode] = useState('');
    const [otherContactMode, setOtherContactMode] = useState(false);
    const [budgetForService, setBudget] = useState('');
    const [otherBudget, setOtherBudget] = useState(false);
    const [serviceFrequency, setServiceFrequency] = useState('');
    const [otherServiceFrequency, setOtherServiceFrequency] = useState(false);
    const [otherInfo, setOtherInfo] = useState('');
    const [comingSoon, setCommingSoon] = useState(false);

    const handleDateChange = (value) => {
        setAppointDate(value)
    }
    const handleService = (value) => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: "center" });
        if (value === 'other') {
            setIsOtherService(true)
            setAppointService('')
        } else {
            setIsOtherService(false)
            setSubService([])
            setAppointService(value)
        }
        setIsOtherSubService(false)
    }
    const handleTimeChange = (event) => {
        var timeSplit = event.target.value.split(':'),
            hours,
            minutes,
            hours = timeSplit[0];
        minutes = timeSplit[1];
        // Check whether AM or PM
        let newformat = hours >= 12 ? 'PM' : 'AM';

        // Find current hour in AM-PM Format
        hours = hours % 12;

        // To display "0" as "12"
        hours = hours ? hours : 12;
        // minutes = minutes < 10 ? '0' + minutes : minutes;
        let selectedTime = hours + ':' + minutes + ' ' + newformat
        setAppointTime(selectedTime)
    }

    const handleSubserviceChange = (value, type) => {
        if (value !== 'Other') {
            setIsOtherSubService(false)
            if (type === 'radio') {
                setSubService([value])
            } else if (type === 'check') {
                let valueCheck = subService.includes(value)
                if (valueCheck) {
                    let getIndex = subService.indexOf(value)
                    let updatedSubService = [...subService]
                    updatedSubService.splice(getIndex, 1)
                    setSubService(updatedSubService)
                } else {
                    let updatedSubService = [...subService]
                    updatedSubService.push(value)
                    setSubService(updatedSubService)
                }
            }
        } else {
            setSubService([])
            setIsOtherSubService(true)
        }
    }

    const handleOtherSubServiceChange = (e) => {
        e.target.value !== '' ? setSubService([e.target.value]) : setSubService([])
    }

    const handleOtherService = (e) => {
        setAppointService(e.target.value)
        setSubService([])
    }

    const handleMassageSalonOtherAns = (value) => {
        if (value === 'Other') {
            setMassageSalonOtherAnsOther(true)
        } else {
            setMassageSalonOtherAnsOther(false)
            massageSalonOtherAns(value)
        }
    }
    const handlePhysicalTherapyOtherAns1 = (value) => {
        if (value === 'Other') {
            setPhysicalTherapyAnsOther1(true)
        } else {
            setPhysicalTherapyAnsOther1(false)
            setPhysicalTherapyOtherAns1(value)
        }
    }
    const handlePhysicalTherapyOtherAns2 = (value) => {
        if (value === 'Other') {
            setPhysicalTherapyAnsOther2(true)
        } else {
            setPhysicalTherapyAnsOther2(false)
            setPhysicalTherapyOtherAns2(value)
        }
    }
    const handlePaymentMethod = (value) => {
        if (value === 'Other') {
            setOtherPaymentMethod(true)
            setPaymentMethod('')
        } else {
            setOtherPaymentMethod(false)
            setPaymentMethod(value)
        }
    }
    const handleContactMode = (value) => {
        if (value === 'Other') {
            setOtherContactMode(true)
            setContactMode('')
        } else {
            setOtherContactMode(false)
            setContactMode(value)
        }
    }
    const handleLocationSelection = (place) => {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q', {
            method: 'GET'
        }).then((response) => response.json())
            .then((data) => {
                if (data.results[1]) {
                    const address = data.results[0].formatted_address
                    setCustomerAddress(address)
                }
            })
            .catch((error) => {
                toast.error(error, {
                    autoClose: 700,
                    hideProgressBar: true,
                })
            })
    };
    const handleBudget = (value) => {
        if (value === 'Other') {
            setOtherBudget(true)
            setBudget('')
        } else {
            setOtherBudget(false)
            setBudget(value)
        }
    }
    const handleServiceFrequency = (value) => {
        if (value === 'Other') {
            setOtherServiceFrequency(true)
            setServiceFrequency('')
        } else {
            setOtherServiceFrequency(false)
            setServiceFrequency(value)
        }
    }

    const handleSubmit = () => {
        if (!appointDate || !appointTime || !bookAppointmentService || (subService.length === 0 && !isOtherService)
            || !contactMode || !payment || !homeService || (homeService === 'Yes' && !customerAddress)
            || !budgetForService || !serviceFrequency) {
            toast.warning('Please Select All required field!', {
                autoClose: 700,
                hideProgressBar: true,
            })
        } else {
            var appointOtherQuestion1, appointOtherQuestion2, appointOtherQuestion3, appointOtherQuestion4 = '';
            var appointOtherAnswer1, appointOtherAnswer2, appointOtherAnswer3, appointOtherAnswer4 = '';
            if (bookAppointmentService === 'Beauty salon') {
                appointOtherQuestion1 = 'Name of your beauty specialist if applicable'
                appointOtherAnswer1 = salonOtherAns
            } else if (bookAppointmentService === 'Massage') {
                appointOtherQuestion1 = 'How long do you want the session to last?'
                appointOtherAnswer1 = massageOtherAns
            } else if (bookAppointmentService === 'Auto Detailing') {
                appointOtherQuestion1 = 'Do you need cleanup of hair or pet related odors and stains'
                appointOtherAnswer1 = autoDetailingotherans1
                appointOtherQuestion2 = 'Do you want your vehicles disinfected?'
                appointOtherAnswer2 = autoDetailingotherans2
            } else if (bookAppointmentService === 'Physical Therapy') {
                appointOtherQuestion1 = 'How long do you want the session to last?'
                appointOtherAnswer1 = physicalTherapyOtherAns1
                appointOtherQuestion2 = 'How often will you need treatment?'
                appointOtherAnswer2 = physicalTherapyOtherAns2
                appointOtherQuestion3 = 'Do need the business to come to you?'
                appointOtherAnswer3 = physicalTherapyTreatmentNeed
                appointOtherQuestion4 = 'What address or location do you need the service?'
                appointOtherAnswer4 = physicalTherapyTreatmentAddress
            }
            // change date formate in USA format
            const date = new Date(appointDate);
            let UsDate = date.toLocaleDateString("en-US")
            let data = {
                businessName: state.businessName,
                businessEmail: state.email,
                businessId: state.businessId,
                clickType: 'bookAppointment',
                appointDate: UsDate,
                appointTime: appointTime,
                paymentMethod: payment,
                contactMode: contactMode,
                budgetForService: budgetForService,
                serviceFrequency: serviceFrequency,
                homeService: homeService,
                customerAddress: homeService === 'Yes' ? customerAddress : '',
                appointService: bookAppointmentService,
                appointSubService: subService,
                appointOtherInfo: otherInfo,
                appointOtherQuestion1: appointOtherQuestion1,
                appointOtherAnswer1: appointOtherAnswer1,
                appointOtherQuestion2: appointOtherQuestion2,
                appointOtherAnswer2: appointOtherAnswer2,
                appointOtherQuestion3: appointOtherQuestion3,
                appointOtherAnswer3: appointOtherAnswer3,
                appointOtherQuestion4: appointOtherQuestion4,
                appointOtherAnswer4: appointOtherAnswer4,
            }
            setServiceData(data)
            setIsSubmit(true)
        }
    }

    return (
        <>
            {!isSubmit ? (
                <div className='book-appointment-page section-bg-image pt-4 pb-5' style={{ backgroundImage: `url(${Banner3})` }}>
                    <ToastContainer />
                    {/* title head */}
                    <Helmet>
                        <title>Book Your Appointment With {state.businessName}</title>
                    </Helmet>
                    <div className="container position-relative">
                        {
                            !comingSoon ?
                                <div className="form-wrapper mx-auto" style={{ maxWidth: '900px' }}>
                                    <form className='w-100 bg-white border rounded py-5 px-3 px-md-5'>
                                        <div className="section-label fs-4 bg-light mb-3 py-2" style={{ borderBlock: '1px solid #ccc' }}>{bookAppointmentService}</div>
                                        <div className="mb-3">
                                            <label htmlFor="appointmentDate" className="form-label w-100 fs-6">Preffered date</label>
                                            <div className='date-picker-wrapper'>
                                                <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={appointDate}
                                                    onChange={(value) => handleDateChange(value)} minDate={new Date()}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="appointmentTime" className="form-label fs-6">Preffered time</label>
                                            <input type="time" id="appointmentTime" className="form-control" min='01:00' max='03:00' required
                                                onChange={(value) => handleTimeChange(value)} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="homemobileService" className="form-label fs-6">Do you want home (mobile) service?</label>
                                            <div className="d-flex flex-wrap">
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForhomeservice" id='homeServiceYes' required
                                                        onClick={() => setHomeService('Yes')} />
                                                    <label className="form-check-label" htmlFor='homeServiceYes'>Yes</label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="radioGroupForhomeservice" id='homeServiceNo' required
                                                        onClick={() => setHomeService('No')} />
                                                    <label className="form-check-label" htmlFor='homeServiceNo'>No</label>
                                                </div>
                                            </div>

                                        </div>
                                        {
                                            homeService === 'Yes' ?
                                                <div className="mb-3">
                                                    <label htmlFor="CustomerAddress" className="form-label fs-6 mb-2">Customer address</label>
                                                    <Autocomplete
                                                        className="form-control"
                                                        placeholder='Please enter address...'
                                                        onPlaceSelected={(e) => handleLocationSelection(e)}
                                                        options={{
                                                            fields: ["formatted_address", "geometry", "name"],
                                                            strictBounds: false,
                                                            types: [],
                                                        }}
                                                    />
                                                </div>
                                                : ''
                                        }
                                        <div className="mb-3">
                                            <label htmlFor="choosePayment" className="form-label fs-6 mb-2">What is your payment method for this service?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                {
                                                    PaymentMethod.map((pay) => (
                                                        <div className="form-check me-3" key={pay}>
                                                            <input className="form-check-input" type="radio" name="radioGroupPay" id={pay} required
                                                                onClick={() => handlePaymentMethod(pay)} />
                                                            <label className="form-check-label" htmlFor={pay}>{pay}</label>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            {
                                                otherPaymentMethod ?
                                                    <input type="text" className="form-control mt-2" id="otherPayment" placeholder='Other payment method...' required
                                                        onChange={(e) => setPaymentMethod(e.target.value)} />
                                                    : ''
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="chooseBudgetBookappointment" className="form-label fs-6 mb-2">What is your budget for this service?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                {
                                                    BudgetData.map((budge) => (
                                                        <div className="form-check me-3" key={budge}>
                                                            <input className="form-check-input" type="radio" name="radioGroupBudgetbookapt" id={budge} required
                                                                onClick={() => handleBudget(budge)} />
                                                            <label className="form-check-label" htmlFor={budge}>{budge}</label>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                            {
                                                otherBudget ?
                                                    <input type="text" className="form-control mt-2" id="otherBudget" placeholder='Other budget...' required
                                                        onChange={(e) => setBudget(e.target.value)} />
                                                    : ''
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="chooseServiceTimeBookapt" className="form-label fs-6 mb-2">How often do you need the service?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                {
                                                    ServiceFrequency.map((freq) => (
                                                        <div className="form-check me-3" key={freq}>
                                                            <input className="form-check-input" type="radio" name="radioGroupServiceFrequencybookapt" id={freq} required
                                                                onClick={() => handleServiceFrequency(freq)} />
                                                            <label className="form-check-label" htmlFor={freq}>{freq}</label>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                            {
                                                otherServiceFrequency ?
                                                    <input type="text" className="form-control mt-2" id="otherServiceFrequency" placeholder='Other service frequency...' required
                                                        onChange={(e) => setServiceFrequency(e.target.value)} />
                                                    : ''
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="chooseContactmode" className="form-label fs-6 mb-2">What is the best way to contact you?</label>
                                            <div className="d-flex flex-wrap g-2">
                                                {
                                                    ContactWay.map((cntct) => (
                                                        <div className="form-check me-3" key={cntct}>
                                                            <input className="form-check-input" type="radio" name="radioGroupForcontact" id={cntct}
                                                                onClick={() => handleContactMode(cntct)} />
                                                            <label className="form-check-label" htmlFor={cntct}>{cntct}</label>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            {
                                                otherContactMode ?
                                                    <input type="text" className="form-control mt-2" id="otherContactMode" placeholder='Other contact...' required
                                                        onChange={(e) => setContactMode(e.target.value)} />
                                                    : ''
                                            }
                                        </div>

                                        <div className="mb-3">
                                            <div className="element-wrapper mt-3">
                                                {(() => {
                                                    switch (bookAppointmentService) {
                                                        case 'Beauty salon':
                                                            return (<div id="Beauty_Salon" className="mb-3">
                                                                <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                                                <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                                                    {BeautySalonServices.map((service) => (
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="BS_radioGroup" id={service} required
                                                                                onChange={() => handleSubserviceChange(service, 'radio')} />
                                                                            <label className="form-check-label" htmlFor={service}>{service}</label>
                                                                        </div>
                                                                    ))}
                                                                    {isOtherSubService ?
                                                                        <input type="text" className="form-control mt-2" id="MOS_OTH" placeholder='Others...' required
                                                                            onChange={(e) => handleOtherSubServiceChange(e)} />
                                                                        : ''}
                                                                </div>
                                                                <div className='other-wrapper mt-2'>
                                                                    <div className='mb-2'>
                                                                        <label className='form-label fs-6 mb-1'>Name of your beauty specialist if applicable</label>
                                                                        <input type="text" className="form-control" id="BS_OTH" placeholder='beauty specialist if any...' required
                                                                            onChange={(e) => setSalonOtherAns(e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="Notsure_radioGroup" id="BS_NOTSURE"
                                                                            onChange={(e) => setSalonOtherAns('Not Sure')} />
                                                                        <label className="form-check-label" htmlFor="BS_NOTSURE">Not Sure</label>
                                                                    </div>
                                                                </div>
                                                            </div>)
                                                        case 'Dentist':
                                                            return (<div id="Dental_Services" className="mb-3">
                                                                <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                                                <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                                                    {
                                                                        DentalService.map((service) => (
                                                                            <div className="form-check pe-2">
                                                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id={service} required
                                                                                    onChange={() => handleSubserviceChange(service, 'radio')} />
                                                                                <label className="form-check-label" htmlFor={service}>{service}</label>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                                {isOtherSubService ?
                                                                    <input type="text" className="form-control mt-2" id="DS_OTH" placeholder='Other...' required
                                                                        onChange={(e) => handleOtherSubServiceChange(e)} />
                                                                    : ''}
                                                            </div>)
                                                        case 'Pet Service':
                                                            return <div id="Pet_Service" className="mb-3">
                                                                <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                                                <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="Bath"
                                                                            onChange={() => handleSubserviceChange('Bath', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Bath">Bath</label>
                                                                    </div>

                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="Brushing"
                                                                            onChange={() => handleSubserviceChange('Brushing', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Brushing">Brushing</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="EAEC"
                                                                            onChange={() => handleSubserviceChange('Eye and ear cleaning', 'check')} />
                                                                        <label className="form-check-label" htmlFor="EAEC">Eye and ear cleaning</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="Hair_trimming"
                                                                            onChange={() => handleSubserviceChange('Hair trimming', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Hair_trimming">Hair trimming</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="Nail_trimming"
                                                                            onChange={() => handleSubserviceChange('Nail trimming', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Nail_trimming">Nail trimming</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="Teeth_brushing"
                                                                            onChange={() => handleSubserviceChange('Teeth brushing', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Teeth_brushing">Teeth brushing</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="De-shedding_treatments"
                                                                            onChange={() => handleSubserviceChange('De-shedding treatments', 'check')} />
                                                                        <label className="form-check-label" htmlFor="De-shedding_treatments">De-shedding treatments</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="AG_expression"
                                                                            onChange={() => handleSubserviceChange('Anal gland expression', 'check')} />
                                                                        <label className="form-check-label" htmlFor="AG_expression">Anal gland expression</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="Dematting_treatments"
                                                                            onChange={() => handleSubserviceChange('Dematting treatments', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Dematting_treatments">Dematting treatments</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="FADT"
                                                                            onChange={() => handleSubserviceChange('Flea and deodorizing treatments', 'check')} />
                                                                        <label className="form-check-label" htmlFor="FADT">Flea and deodorizing treatments</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="Deep_conditioning"
                                                                            onChange={() => handleSubserviceChange('Deep conditioning', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Deep_conditioning">Deep conditioning</label>
                                                                    </div>

                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="training"
                                                                            onChange={() => handleSubserviceChange('Training', 'check')} />
                                                                        <label className="form-check-label" htmlFor="training">Training</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="boarding"
                                                                            onChange={() => handleSubserviceChange('Boarding', 'check')} />
                                                                        <label className="form-check-label" htmlFor="boarding">Boarding</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="veterinary"
                                                                            onChange={() => handleSubserviceChange('Veterinary', 'check')} />
                                                                        <label className="form-check-label" htmlFor="veterinary">Veterinary</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="daycare"
                                                                            onChange={() => handleSubserviceChange('Daycare', 'check')} />
                                                                        <label className="form-check-label" htmlFor="daycare">Daycare</label>
                                                                    </div>
                                                                    {/* <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="PH_OTH" />
                                                <label className="form-check-label" htmlFor="PH_OTH">Other</label>
                                            </div> */}

                                                                    {/* <input type="text" className="form-control mt-2" id="PH_OTH" placeholder='Other...' required /> */}
                                                                </div>
                                                            </div>
                                                        case 'Massage':
                                                            return (<div id="Message" className="mb-3">
                                                                <div className="form-label fs-6 mb-2">What type of massage do you need?</div>
                                                                <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="message_radioGroup" id="aromatherapy" required
                                                                            onChange={() => handleSubserviceChange('Aromatherapy', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="aromatherapy">Aromatherapy</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="message_radioGroup" id="hot_stone"
                                                                            onChange={() => handleSubserviceChange('Hot stone', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="hot_stone">Hot stone</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="message_radioGroup" id="neuromuscular"
                                                                            onChange={() => handleSubserviceChange('Neuromuscular', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="neuromuscular">Neuromuscular</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="message_radioGroup" id="deep_tissue"
                                                                            onChange={() => handleSubserviceChange('Deep tissue', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="deep_tissue">Deep tissue</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="message_radioGroup" id="lymphatic_drainage"
                                                                            onChange={() => handleSubserviceChange('Lymphatic drainage', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="lymphatic_drainage">Lymphatic drainage </label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="message_radioGroup" id="prenatal" required
                                                                            onChange={() => handleSubserviceChange('Prenatal', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="prenatal">Prenatal</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="message_radioGroup" id="foot_massage"
                                                                            onChange={() => handleSubserviceChange('Foot massage', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="foot_massage">Foot massage</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="message_radioGroup" id="myofascial"
                                                                            onChange={() => handleSubserviceChange('Myofascial', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="myofascial">Myofascial</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="message_radioGroup" id="reflexology"
                                                                            onChange={() => handleSubserviceChange('Reflexology', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="reflexology">Reflexology</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="message_radioGroup" id="shiatsu"
                                                                            onChange={() => handleSubserviceChange('Shiatsu', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="shiatsu">Shiatsu</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="message_radioGroup" id="spots"
                                                                            onChange={() => handleSubserviceChange('Spots', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="spots">Spots</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="message_radioGroup" id="Swedish"
                                                                            onChange={() => handleSubserviceChange('Swedish', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="Swedish">Swedish</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="message_radioGroup" id="Thai"
                                                                            onChange={() => handleSubserviceChange('Thai', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="Thai">Thai</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="message_radioGroup" id="therapeutic"
                                                                            onChange={() => handleSubserviceChange('Therapeutic', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="therapeutic">Therapeutic</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="message_radioGroup" id="trigger_points"
                                                                            onChange={() => handleSubserviceChange('Trigger_points', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="trigger_points">Trigger_points</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="message_radioGroup" id="MSG_OTH"
                                                                            onChange={() => handleSubserviceChange('Other')} />
                                                                        <label className="form-check-label" htmlFor="MSG_OTH">Other</label>
                                                                    </div>
                                                                    {isOtherSubService ?
                                                                        <input type="text" className="form-control mt-2" id="MSG_OTH" placeholder='Other...' required
                                                                            onChange={(e) => handleOtherSubServiceChange(e)} />
                                                                        : ''}
                                                                </div>
                                                                <div className='other-wrapper mt-2'>
                                                                    <div className="form-label fs-6 mb-2">How long do you want the session to last?</div>
                                                                    <div className="d-flex flex-wrap g-2">
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="message_radioGroup1" id="30_min"
                                                                                onChange={(e) => handleMassageSalonOtherAns('30 minutes')} />
                                                                            <label className="form-check-label" htmlFor="30_min">30 minutes</label>
                                                                        </div>
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="message_radioGroup1" id="45_min"
                                                                                onChange={(e) => handleMassageSalonOtherAns('45 minutes')} />
                                                                            <label className="form-check-label" htmlFor="45_min">45 minutes</label>
                                                                        </div>
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="message_radioGroup1" id="60_min"
                                                                                onChange={(e) => handleMassageSalonOtherAns('60 minutes')} />
                                                                            <label className="form-check-label" htmlFor="60_min">60 min</label>
                                                                        </div>
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="message_radioGroup1" id="90_min"
                                                                                onChange={(e) => handleMassageSalonOtherAns('90 minutes')} />
                                                                            <label className="form-check-label" htmlFor="90_min">90 min</label>
                                                                        </div>
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="message_radioGroup1" id="MSG_NOTSURE"
                                                                                onChange={(e) => handleMassageSalonOtherAns('Not Sure')} />
                                                                            <label className="form-check-label" htmlFor="MSG_NOTSURE">Not Sure</label>
                                                                        </div>
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="message_radioGroup1" id="MSG_OTH1"
                                                                                onChange={(e) => handleMassageSalonOtherAns('Other')} />
                                                                            <label className="form-check-label" htmlFor="MSG_OTH1">Other</label>
                                                                        </div>
                                                                        {massageSalonOtherAnsOther ?
                                                                            <input type="text" className="form-control mt-2" id="MSG_OTH1" placeholder='Other...' required
                                                                                onChange={(e) => massageSalonOtherAns(e.target.value)} />
                                                                            : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            )
                                                        case 'Physical training':
                                                            <div id="Physical_Training" className="mb-3">
                                                                <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                                                <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="Cardio_Fitness"
                                                                            onChange={() => handleSubserviceChange('Cardio Fitness', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Cardio_Fitness">Cardio Fitness</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="Group_Training"
                                                                            onChange={() => handleSubserviceChange('Group Training', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Group_Training">Group Training</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="Nutrition_Counseling"
                                                                            onChange={() => handleSubserviceChange('Nutrition Counseling', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Nutrition_Counseling">Nutrition Counseling</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="PPNE"
                                                                            onChange={() => handleSubserviceChange('PRE/POST NATAL EXERCISE', 'check')} />
                                                                        <label className="form-check-label" htmlFor="PPNE">PRE/POST NATAL EXERCISE</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="Senior_Fitness"
                                                                            onChange={() => handleSubserviceChange('Senior Fitness', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Senior_Fitness">Senior Fitness</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="Sports_Conditioning"
                                                                            onChange={() => handleSubserviceChange('Sports Conditioning', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Sports_Conditioning">Sports Conditioning</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="Specialty_Services"
                                                                            onChange={() => handleSubserviceChange('Specialty Services', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Specialty_Services">Specialty Services</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="Strength_Training"
                                                                            onChange={() => handleSubserviceChange('Strength Training', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Strength_Training">Strength Training</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="Stretch"
                                                                            onChange={() => handleSubserviceChange('Stretch', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Stretch">Stretch</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="WLT"
                                                                            onChange={() => handleSubserviceChange('Weight Loss & Toning', 'check')} />
                                                                        <label className="form-check-label" htmlFor="WLT">Weight Loss & Toning</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="WRT"
                                                                            onChange={() => handleSubserviceChange('Weight & Resistance Training', 'check')} />
                                                                        <label className="form-check-label" htmlFor="WRT">Weight & Resistance Training</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="YTF"
                                                                            onChange={() => handleSubserviceChange('Youth & Teen Fitness', 'check')} />
                                                                        <label className="form-check-label" htmlFor="YTF">Youth & Teen Fitness</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="VPT"
                                                                            onChange={() => handleSubserviceChange('Virtual Personal Training', 'check')} />
                                                                        <label className="form-check-label" htmlFor="VPT">Virtual Personal Training</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        case 'Chiropractor':
                                                            return (<div id="Chiropractic_Services" className="mb-3">
                                                                <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                                                <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="CS_radioGroup1" id="therapeutic_exercises" required
                                                                            onChange={() => handleSubserviceChange('Therapeutic exercises', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="therapeutic_exercises">Therapeutic exercises</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="CS_radioGroup1" id="therapeutics_stretching"
                                                                            onChange={() => handleSubserviceChange('Therapeutics stretching', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="therapeutics_stretching">Therapeutics stretching</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="CS_radioGroup1" id="spinal_traction"
                                                                            onChange={() => handleSubserviceChange('Spinal traction', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="spinal_traction">Spinal traction</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="CS_radioGroup1" id="STMT"
                                                                            onChange={() => handleSubserviceChange('Soft tissue manual therapy', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="STMT">Soft tissue manual therapy</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="CS_radioGroup1" id="muscle_stimulation"
                                                                            onChange={() => handleSubserviceChange('Muscle stimulation', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="muscle_stimulation">Muscle stimulation</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="CS_radioGroup1" id="TENS"
                                                                            onChange={() => handleSubserviceChange('Transcutaneous electric nerve stimulation', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="TENS">Transcutaneous electric nerve stimulation</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="CS_radioGroup1" id="ultrasound"
                                                                            onChange={() => handleSubserviceChange('Ultrasound', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="ultrasound">Ultrasound</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="CS_radioGroup1" id="IAH"
                                                                            onChange={() => handleSubserviceChange('Ice and heat', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="IAH">Ice and heat</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="CS_radioGroup1" id="DANC"
                                                                            onChange={() => handleSubserviceChange('Diet and nutritional counseling', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="DANC">Diet and nutritional counseling</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="CS_radioGroup1" id="lifestyle_modification"
                                                                            onChange={() => handleSubserviceChange('Lifestyle modification', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="lifestyle_modification">Lifestyle modification</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="CS_radioGroup1" id="chiropractic_care"
                                                                            onChange={() => handleSubserviceChange('Chiropractic care', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="chiropractic_care">Chiropractic care</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="CS_radioGroup1" id="hydrotherapy"
                                                                            onChange={() => handleSubserviceChange('Hydrotherapy', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="hydrotherapy">Hydrotherapy</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="CS_radioGroup1" id="pelvic_stabilization"
                                                                            onChange={() => handleSubserviceChange('Pelvic stabilization', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="pelvic_stabilization">Pelvic stabilization</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="CS_radioGroup1" id="CS_OTH1"
                                                                            onChange={() => handleSubserviceChange('Other')} />
                                                                        <label className="form-check-label" htmlFor="CS_OTH1">Other</label>
                                                                    </div>
                                                                </div>
                                                                {isOtherSubService ?
                                                                    <input type="text" className="form-control mt-2" id="CS_OTH1" placeholder='Other...' required
                                                                        onChange={(e) => handleOtherSubServiceChange(e)} />
                                                                    : ''}
                                                            </div>)
                                                        case 'Physical Therapy':
                                                            return <div id="Physical_Therapy" className="mb-3">
                                                                <div className="form-label fs-6 mb-2">What do you need a therapy to address?</div>
                                                                <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="chronic_pain"
                                                                            onChange={() => handleSubserviceChange('Chronic pain', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="chronic_pain">Chronic pain</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="muscle_strain"
                                                                            onChange={() => handleSubserviceChange('Muscle strain', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="muscle_strain">Muscle strain</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="physical_therapy"
                                                                            onChange={() => handleSubserviceChange('Physical therapy', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="physical_therapy">Physical therapy</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="posture"
                                                                            onChange={() => handleSubserviceChange('Posture', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="posture">Posture</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="work_injuries"
                                                                            onChange={() => handleSubserviceChange('Work injuries', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="work_injuries">Work injuries</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="back_pain"
                                                                            onChange={() => handleSubserviceChange('Back pain', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="back_pain">Back pain</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="home_therapy"
                                                                            onChange={() => handleSubserviceChange('Home therapy', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="home_therapy">Home therapy</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="pediatric_therapy"
                                                                            onChange={() => handleSubserviceChange('Pediatric therapy', 'radiio')} />
                                                                        <label className="form-check-label" htmlFor="pediatric_therapy">Pediatric therapy</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="athletic_training"
                                                                            onChange={() => handleSubserviceChange('Athletic training', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="athletic_training">Athletic training</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="occupational_hand_therapy"
                                                                            onChange={() => handleSubserviceChange('Occupational / hand therapy', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="occupational_hand_therapy">Occupational / hand therapy</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="sports_injury"
                                                                            onChange={() => handleSubserviceChange('Sports injury', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="sports_injury">Sports injury</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="therapy_not_sure"
                                                                            onChange={() => handleSubserviceChange('Not sure', 'radio')} />
                                                                        <label className="form-check-label" htmlFor="therapy_not_sure">Not sure</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="Therapy_OTH"
                                                                            onChange={() => handleSubserviceChange('Other')} />
                                                                        <label className="form-check-label" htmlFor="Therapy_OTH">Other</label>
                                                                    </div>
                                                                    {isOtherSubService ?
                                                                        <input type="text" className="form-control mt-2" id="MOS_OTH" placeholder='Others therapy...' required
                                                                            onChange={(e) => handleOtherSubServiceChange(e)} />
                                                                        : ''}
                                                                </div>
                                                                <div className='other-wrapper mt-2'>
                                                                    <div className="form-label fs-6 mb-2">How long do you want the session to last?</div>
                                                                    <div className="d-flex flex-wrap g-2">
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="therapy_radioGroup1" id="30_minT"
                                                                                onChange={(e) => handlePhysicalTherapyOtherAns1('30 minutes')} />
                                                                            <label className="form-check-label" htmlFor="30_minT">30 minutes</label>
                                                                        </div>
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="therapy_radioGroup1" id="45_minT"
                                                                                onChange={(e) => handlePhysicalTherapyOtherAns1('45 minutes')} />
                                                                            <label className="form-check-label" htmlFor="45_minT">45 minutes</label>
                                                                        </div>
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="therapy_radioGroup1" id="60_minT"
                                                                                onChange={(e) => handlePhysicalTherapyOtherAns1('60 minutes')} />
                                                                            <label className="form-check-label" htmlFor="60_minT">60 min</label>
                                                                        </div>
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="therapy_radioGroup1" id="90_minT"
                                                                                onChange={(e) => handlePhysicalTherapyOtherAns1('90 minutes')} />
                                                                            <label className="form-check-label" htmlFor="90_minT">90 min</label>
                                                                        </div>
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="therapy_radioGroup1" id="therapy_NOTSURE"
                                                                                onChange={(e) => handlePhysicalTherapyOtherAns1('Not Sure')} />
                                                                            <label className="form-check-label" htmlFor="therapy_NOTSURE">Not Sure</label>
                                                                        </div>
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="therapy_radioGroup1" id="MSG_OTH1"
                                                                                onChange={(e) => handlePhysicalTherapyOtherAns1('Other')} />
                                                                            <label className="form-check-label" htmlFor="MSG_OTH1">Other</label>
                                                                        </div>
                                                                        {physicalTherapyOtherAnsOther1 ?
                                                                            <input type="text" className="form-control mt-2" id="therapy_OTH1" placeholder='Other session...' required
                                                                                onChange={(e) => setPhysicalTherapyOtherAns1(e.target.value)} />
                                                                            : ''}
                                                                    </div>
                                                                </div>
                                                                <div className='other-wrapper mt-2'>
                                                                    <div className="form-label fs-6 mb-2">How often will you need treatment?</div>
                                                                    <div className="d-flex flex-wrap g-2">
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="therapy_radioGroup2" id="oneTimeT"
                                                                                onChange={(e) => handlePhysicalTherapyOtherAns2('One time')} />
                                                                            <label className="form-check-label" htmlFor="oneTimeT">One time</label>
                                                                        </div>
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="therapy_radioGroup2" id="OAWT"
                                                                                onChange={(e) => handlePhysicalTherapyOtherAns2('Once a week')} />
                                                                            <label className="form-check-label" htmlFor="OAWT">Once a week</label>
                                                                        </div>
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="therapy_radioGroup2" id="TOMT"
                                                                                onChange={(e) => handlePhysicalTherapyOtherAns2('Twice a month')} />
                                                                            <label className="form-check-label" htmlFor="TOMT">Twice a month</label>
                                                                        </div>
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="therapy_radioGroup2" id="treat_NOTSURE"
                                                                                onChange={(e) => handlePhysicalTherapyOtherAns2('Not Sure')} />
                                                                            <label className="form-check-label" htmlFor="treat_NOTSURE">Not Sure</label>
                                                                        </div>
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="therapy_radioGroup2" id="MSG_OTH1"
                                                                                onChange={(e) => handlePhysicalTherapyOtherAns2('Other')} />
                                                                            <label className="form-check-label" htmlFor="MSG_OTH1">Other</label>
                                                                        </div>
                                                                        {physicalTherapyOtherAnsOther2 ?
                                                                            <input type="text" className="form-control mt-2" id="therapy_OTH2" placeholder='Other...' required
                                                                                onChange={(e) => setPhysicalTherapyOtherAns2(e.target.value)} />
                                                                            : ''}
                                                                    </div>
                                                                </div>

                                                                <div className='other-wrapper mt-2'>
                                                                    <div className="form-label fs-6 mb-2">Do need the business to come to you?</div>
                                                                    <div className="d-flex flex-wrap g-2">
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="therapy_radioGroup3" id="treatmentYes"
                                                                                onChange={(e) => setPhysicalTherapyTreatmentNeed('yes')} />
                                                                            <label className="form-check-label" htmlFor="treatmentYes">Yes</label>
                                                                        </div>
                                                                        <div className="form-check pe-2">
                                                                            <input className="form-check-input" type="radio" name="therapy_radioGroup3" id="treatmentNo"
                                                                                onChange={(e) => setPhysicalTherapyTreatmentNeed('no')} />
                                                                            <label className="form-check-label" htmlFor="treatmentNo">No</label>
                                                                        </div>

                                                                    </div>
                                                                    {physicalTherapyTreatmentNeed === 'yes' ?
                                                                        <div className="d-flex flex-wrap g-2 mt-2">
                                                                            <div className="form-label fs-6 mb-1">What address or location do you need the service?</div>
                                                                            <input type="text" className="form-control mt-2" id="therapy_OTH2" placeholder='address you need the service...'
                                                                                onChange={(e) => setPhysicalTherapyTreatmentAddress(e.target.value)} />
                                                                        </div>
                                                                        : ''}
                                                                </div>

                                                            </div>
                                                        case 'Auto Repair':
                                                            return (<div id="Auto_Repair" className="mb-3">
                                                                <div className="form-label fs-6 mb-2">What type of services do you need?</div>
                                                                <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                                                    {
                                                                        AutoRepairService.map((auto) => (
                                                                            <div className="form-check pe-2">
                                                                                <input className="form-check-input" type="radio" name="autorepair_radioGroup" id={auto} required
                                                                                    onChange={() => handleSubserviceChange(auto, 'radio')} />
                                                                                <label className="form-check-label" htmlFor={auto}>{auto}</label>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                                {isOtherSubService ?
                                                                    <input type="text" className="form-control mt-2" id="AUTOREPAIR_OTH" placeholder='Others...' required
                                                                        onChange={(e) => handleOtherSubServiceChange(e)} />
                                                                    : ''}
                                                            </div>)
                                                        case 'Auto Detailing':
                                                            return <div id="Auto_Detailing" className="mb-3">
                                                                <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                                                <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                                                    {AutoDetailingService.map((detailing) => (
                                                                        <div className="form-check pe-2" key={detailing}>
                                                                            <input className="form-check-input" type="checkbox" name="AD_CheckGroup" id={detailing}
                                                                                onChange={() => handleSubserviceChange(detailing, 'check')} />
                                                                            <label className="form-check-label" htmlFor={detailing}>{detailing}</label>
                                                                        </div>
                                                                    ))}

                                                                    <div className="w-100 mt-2">
                                                                        <label className="form-label fs-6 me-3">Do you need cleanup of hair or pet related odors and stains</label>
                                                                        <div className="d-flex flex-wrap">
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="radioGroupYess" id="Yes" required
                                                                                    onClick={() => setAutoDetailsAns1('Yes')} />
                                                                                <label className="form-check-label" htmlFor="Yes">Yes</label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="radioGroupYess" id="No"
                                                                                    onClick={() => setAutoDetailsAns1('No')} />
                                                                                <label className="form-check-label" htmlFor="No">No</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="w-100 mt-2">
                                                                        <label className="form-label fs-6 me-3">Do you want your vehicles disinfected?</label>
                                                                        <div className="d-flex flex-wrap">
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="radioGroupYess1" id="Yes1" required
                                                                                    onClick={() => setAutoDetailsAns2('Yes')} />
                                                                                <label className="form-check-label" htmlFor="Yes1">Yes</label>
                                                                            </div>
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="radioGroupYess1" id="No1"
                                                                                    onClick={() => setAutoDetailsAns2('No')} />
                                                                                <label className="form-check-label" htmlFor="No1">No</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        case 'Cleaning services':
                                                            return (<div id="Cleaning_Service" className="mb-3">
                                                                <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                                                <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                                                    {
                                                                        CleanServices.map((service) => (
                                                                            <div className="form-check pe-2" key={service}>
                                                                                <input className="form-check-input" type="radio" name="CS_CheckGroup" id={service} required
                                                                                    onChange={() => handleSubserviceChange(service, 'radio')} />
                                                                                <label className="form-check-label" htmlFor={service}>{service}</label>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                                {isOtherSubService ?
                                                                    <input type="text" className="form-control mt-2" id="CleaningService_OTH" placeholder='Others...' required
                                                                        onChange={(e) => handleOtherSubServiceChange(e)} />
                                                                    : ''}
                                                            </div>)
                                                        case 'Wedding planner':
                                                            return <div id="Wedding_Planner" className="mb-3">
                                                                <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                                                <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                                                    {
                                                                        WeddingPlannerService.map((wedding) => (
                                                                            <div className="form-check pe-2" key={wedding}>
                                                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id={wedding} required
                                                                                    onChange={() => handleSubserviceChange(wedding, 'check')} />
                                                                                <label className="form-check-label" htmlFor={wedding}>{wedding}</label>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        case 'Handyman':
                                                            return <div id="Handyman" className="mb-3">
                                                                <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                                                <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                                                    {
                                                                        HandymanServices.map((handyman) => (
                                                                            <div className="form-check pe-2" key={handyman}>
                                                                                <input className="form-check-input" type="radio" name="Handyman_radioGroup" id={handyman}
                                                                                    onChange={() => handleSubserviceChange(handyman, 'radio')} />
                                                                                <label className="form-check-label" htmlFor={handyman}>{handyman}</label>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                                {isOtherSubService ?
                                                                    <input type="text" className="form-control mt-2" id="handyman_OTH" placeholder='Others...' required
                                                                        onChange={(e) => handleOtherSubServiceChange(e)} />
                                                                    : ''}
                                                            </div>
                                                        case 'Business consulting':
                                                            return <div id="Business_Consulting" className="mb-3">
                                                                <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                                                <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                                                    {
                                                                        BusinessCousetingService.map((consulting) => (
                                                                            <div className="form-check pe-2" key={consulting}>
                                                                                <input className="form-check-input" type="radio" name="BC_radiogroup" id={consulting}
                                                                                    onChange={() => handleSubserviceChange(consulting, 'radio')} />
                                                                                <label className="form-check-label" htmlFor={consulting}>{consulting}</label>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                                {isOtherSubService ?
                                                                    <input type="text" className="form-control mt-2" id="BusinessCosulting_OTH" placeholder='Others...' required
                                                                        onChange={(e) => handleOtherSubServiceChange(e)} />
                                                                    : ''}
                                                            </div>
                                                        case 'Lawyers':
                                                            return <div id="Lawyer_Services" className="mb-3">
                                                                <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                                                <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                                                    {
                                                                        LawyerServices.map((law) => (
                                                                            <div className="form-check pe-2" key={law}>
                                                                                <input className="form-check-input" type="radio" name="LS_radiogroup" id={law}
                                                                                    onChange={() => handleSubserviceChange(law, 'radio')} />
                                                                                <label className="form-check-label" htmlFor={law}>{law}</label>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                                {isOtherSubService ?
                                                                    <input type="text" className="form-control mt-2" id="lawyer_OTH" placeholder='Others...' required
                                                                        onChange={(e) => handleOtherSubServiceChange(e)} />
                                                                    : ''}
                                                            </div>
                                                        case 'Housing (apartment, real estate or property management)':
                                                            return <div id="Housing" className="mb-3">
                                                                <div className="form-label fs-6 mb-2">What type of amenities do you need?</div>
                                                                <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Swimming Pool"
                                                                            onChange={() => handleSubserviceChange('Swimming Pool', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Swimming Pool">Swimming Pool</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Barbecue_Areas"
                                                                            onChange={() => handleSubserviceChange('Barbecue Areas', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Barbecue_Areas">Barbecue Areas</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Firepits"
                                                                            onChange={() => handleSubserviceChange('Firepits', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Firepits">Firepits</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Gym_Fitness_Centers"
                                                                            onChange={() => handleSubserviceChange('Gym/Fitness Centers', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Gym_Fitness_Centers">Gym/Fitness Centers</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="YC_Rooms"
                                                                            onChange={() => handleSubserviceChange('Yoga/Cardio Rooms', 'check')} />
                                                                        <label className="form-check-label" htmlFor="YC_Rooms">Yoga/Cardio Rooms</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Community_Clubhouse"
                                                                            onChange={() => handleSubserviceChange('Community Clubhouse', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Community_Clubhouse">Community Clubhouse</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="De-shedding_treatments"
                                                                            onChange={() => handleSubserviceChange('De-shedding treatments', 'check')} />
                                                                        <label className="form-check-label" htmlFor="De-shedding_treatments">De-shedding treatments</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Playground"
                                                                            onChange={() => handleSubserviceChange('Playground', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Playground">Playground</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="RL_Areas"
                                                                            onChange={() => handleSubserviceChange('Rooftop Lounge Areas', 'check')} />
                                                                        <label className="form-check-label" htmlFor="RL_Areas">Rooftop Lounge Areas</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Community_Garden"
                                                                            onChange={() => handleSubserviceChange('Community Garden', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Community_Garden">Community Garden</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Media_Rooms"
                                                                            onChange={() => handleSubserviceChange('Media Rooms', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Media_Rooms">Media Rooms</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Common_Areas"
                                                                            onChange={() => handleSubserviceChange('Common Areas', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Common_Areas">Common Areas</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Pet_friendly_Units"
                                                                            onChange={() => handleSubserviceChange('Pet-friendly Units', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Pet_friendly_Units">Pet-friendly Units</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Pet_Washing_Stations"
                                                                            onChange={() => handleSubserviceChange('Pet Washing Stations', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Pet_Washing_Stations">Pet Washing Stations</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Dog_Parks"
                                                                            onChange={() => handleSubserviceChange('Dog Parks', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Dog_Parks">Dog Parks</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Secured_Garage"
                                                                            onChange={() => handleSubserviceChange('Secured Garage', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Secured_Garage">Secured Garage</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="APSCCPS"
                                                                            onChange={() => handleSubserviceChange('Assigned Parking Spaces, Carport, Covered Parking Spaces', 'check')} />
                                                                        <label className="form-check-label" htmlFor="APSCCPS">Assigned Parking Spaces, Carport, Covered Parking Spaces</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="ATP_Transport"
                                                                            onChange={() => handleSubserviceChange('Access to Public Transportation', 'check')} />
                                                                        <label className="form-check-label" htmlFor="ATP_Transport">Access to Public Transportation</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="EVC_Station"
                                                                            onChange={() => handleSubserviceChange('Electric Vehicle Charging Stations', 'check')} />
                                                                        <label className="form-check-label" htmlFor="EVC_Station">Electric Vehicle Charging Stations</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Guest_Parking"
                                                                            onChange={() => handleSubserviceChange('Guest Parking', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Guest_Parking">Guest Parking</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Bike_Storage"
                                                                            onChange={() => handleSubserviceChange('Bike Storage', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Bike_Storage">Bike Storage</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="BR_Centers"
                                                                            onChange={() => handleSubserviceChange('Bike Repair Centers', 'check')} />
                                                                        <label className="form-check-label" htmlFor="BR_Centers">Bike Repair Centers</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Laundry_Facilities"
                                                                            onChange={() => handleSubserviceChange('Laundry Facilities', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Laundry_Facilities">Laundry Facilities</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Laundry_Services"
                                                                            onChange={() => handleSubserviceChange('Laundry Services', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Laundry_Services">Laundry Services</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Package_Lockers"
                                                                            onChange={() => handleSubserviceChange('Package Lockers', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Package_Lockers">Package Lockers</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Gated_Community"
                                                                            onChange={() => handleSubserviceChange('Gated Community', 'check')} />
                                                                        <label className="form-check-label" htmlFor="Gated_Community">Gated Community</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="SGD"
                                                                            onChange={() => handleSubserviceChange('Security Guards/Doormen', 'check')} />
                                                                        <label className="form-check-label" htmlFor="SGD">Security Guards/Doormen</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="C_E_C"
                                                                            onChange={() => handleSubserviceChange('Community Events/Classes', 'check')} />
                                                                        <label className="form-check-label" htmlFor="C_E_C">Community Events/Classes</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="ES_Space"
                                                                            onChange={() => handleSubserviceChange('Extra Storage Space', 'check')} />
                                                                        <label className="form-check-label" htmlFor="ES_Space">Extra Storage Space</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="OR_Payments"
                                                                            onChange={() => handleSubserviceChange('Online Rent Payments', 'check')} />
                                                                        <label className="form-check-label" htmlFor="OR_Payments">Online Rent Payments</label>
                                                                    </div>
                                                                    <div className="form-check pe-2">
                                                                        <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="OM_Requests"
                                                                            onChange={() => handleSubserviceChange('Online Maintenance Requests', 'check')} />
                                                                        <label className="form-check-label" htmlFor="OM_Requests">Online Maintenance Requests</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        default: return setCommingSoon(true);
                                                    }
                                                })()}
                                            </div>
                                        </div>

                                        <div className="mb-4">
                                            <label htmlFor="otherInfo" className="form-label fs-6">Other information</label>
                                            <textarea className="form-control" id="otherInfo" rows="4" placeholder='Write your description here...' required
                                                onChange={(e) => setOtherInfo(e.target.value)}></textarea>
                                        </div>
                                        <div className='text-danger small mb-2'>TO QUALIFY FOR A REBATE, RATE, COMMENT & SUBMIT RECEIPT OF TRANSECTIONS MADE TO sales@couponranking.com </div>
                                        <div className="btn-wrapper">
                                            <button type="submit" className="w-100 btn btn-primary" onClick={() => handleSubmit()}>Next</button>
                                        </div>
                                    </form>
                                </div>
                                :
                                <div className='bg-secondary bg-opacity-50 rounded-3 text-white text-center mx-auto p-4 p-md-5' style={{ maxWidth: '700px' }}>
                                    <h1>Coming Soon</h1>
                                    <p>This service will start soon..</p>
                                    <Button variant='primary' className='px-4 py-2' onClick={() => navigate('/')}>Back to Home</Button>
                                </div>
                        }

                    </div>
                </div>
            ) :
                (<UserInfoForBookAppointment state={serviceData} />)
            }
        </>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clickPerCount: (data, toast, navigate) => dispatch(actions.clickPerCount(data, toast, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(BookAppointment))
