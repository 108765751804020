
const BeautySalonServices = ['Nail care', 'Eye care', 'Body shaping and fitness', 'Hair care', 'Make up', 'Facial treatment', 'Skin care', 'Waxing', 'Anti aging treatment',
    'Hair coloring', 'African hair braiding', 'Barber shop', 'Tattoo shop', 'Other']

const DentalService = ['Orthodontics', 'Cosmetic dentistry', 'Dentistry', 'Prosthodontics', 'Endodontics', 'Pediatrics dentistry',
    'Dental extraction', 'Dental restoration', 'Dental surgery', 'Denture', 'Dental implants', 'Teeth cleaning', 'Restoration dentistry',
    'Crown', 'Oral hygiene', 'Teeth whitening', 'Periodontics', 'Dantal braces', 'Invisalign', 'Root canal', 'Dental sealant', 'Root canal treatment',
    'Veneer', 'Bridge', 'Other']

const AutoRepairService = ['Computer diagnostic', 'Lube/oil/filter change', 'Radiator flush /fill services', 'Transmission fluid service', 'Timing belt replacement',
    'Air recharge & diagnostic service', 'Tire rotation & balance only', 'Battery replacement', 'Anti lock system diagnosis’s', 'Axle work bearing/seals',
    'Shock and struct replacement', 'Starter/alternator/heater cores', 'Suspension system services', 'Alignment', 'Anti lock system diagnosis',
    'Fleet service/maintenance', 'Towing', 'Roadside assistance', 'Other']

const AutoDetailingService = ['Engine cleaning', 'Exterior detailing', 'Full service package', 'Headlight restoration', 'Interior detailing',
    'Upholstery and carpet cleaning', 'Wheel and rim detailing', 'Window cleaning']

const CleanServices = ['Basic house cleaning', 'Deep cleaning', 'Laundry service', 'Green service', 'Sensitization service',
    'Ceiling and wall cleaning', 'Blind cleaning', 'Curtains cleaning', 'Carpet cleaning', 'Upholstery cleaning',
    'Chimney sweeping', 'Office cleaning', 'Disaster cleaning and restoration', 'Window cleaning', 'Pressure washing',
    'Restroom cleaning', 'Janitorial services', 'School cleaning', 'Medical cleaning', 'Sports cleaning', 'Move out cleaning',
    'Move in cleaning', 'Tile/floor cleaning', 'Duct cleaning', 'Gutter cleaning', 'After event cleaning', 'Airbnb cleaning',
    'Home organization', 'Apartment cleaning', 'Kitchen cleaning', 'Roof and gutter cleaning', 'Dryer vent and duct cleaning', 'Solar panel cleaning',
    'Other'
]

const HandymanServices = ['Assembly', 'Caulking', 'Cleaning', 'Home Proofing Services', 'Installation', 'Painting', 'Repair', 'Floors',
    'Gutters', 'Painting', 'Fencing', 'Drywall', 'Carpentry', 'Bathroom repair', 'Kitchen repair', 'Project for pets', 'Tile install', 'Preventive maintenance',
    'House security', 'Power washing', 'Furniture assembly', 'Drywall repair', 'Deck and patio', 'Garage', 'Basement', 'Other'
];


const LawyerServices = ['Commercial service', 'Employment service', 'Due diligence investigation', 'Civil litigation', 'Personal', 'Real estate law', 'Immigration',
    'Business law', 'Debt collection defense', 'Estate planning', 'Tenant/landlord legal issue', 'Business formation', 'Traffic ticket defense', 'Accident law', 'Bankruptcy law', 'Injury law',
    'Probates & successors', 'Criminal litigation', 'Criminal litigation', 'Sales & purchase of properties', 'Consultation for legal information and advice',
    'Consultation to review documents (such as agreements, leases, wills, corporation documents, do-it-yourself court forms)',
    'Mediation, arbitration, or other third party services', 'Preparation of documents', 'Representing clients in negotiations (possibly under “collaborative lawyering” agreement where both attorneys withdraw if either side chooses to litigate)',
    'Representing clients in court, arbitration, administrative and legislative hearings']

const WeddingPlannerService = ['Wedding Concept & Design(ThemeDecoration)', 'Venue Selection', 'Catering and Menu Selection', 'Music and Entertainment', 'Officiate',
    'Staging and Audio/Visual', 'Lighting Design', 'Invitations & Guest Management', 'Comprehensive detailed ‘Wedding Day Itinerary',
    'Wedding Invitation & Stationary', 'Reception Management', 'Trousseau & Personal Shopping', 'Accommodations Search & Selection',
    'Transportation', 'Contract Review & Negotiation', 'Rehearsal Coordination', 'Event Timeline', 'Budgeting', 'Photography/Videography',
    'Wedding Cinematography', 'Delivery and set-up of all wedding day items', 'Follow up with vendors', 'Personalized Attention to your wedding shopping (wedding trousseau)',
    'Cakes','Dress and attire','Event rental','Photo booths','Ceremony music','Bands','Favors and gifts','Travel agent','Jewelry']
 

const BusinessCousetingService = ['Strategy consulting', 'Marketing consulting', 'Operations consulting', 'Management consulting', 'Financial consulting',
    'HR consulting', 'Compliance consulting', 'IT/Technology consulting', 'Legal consultant', 'Social media consultant',
    'Sustainability consultant', 'Sales consultant', 'Wellness/Fitness consultant', 'Growth Marketing consultant', 'Career coaching consultancy',
    'PR consultancy', 'SEO consulting', 'Leadership consulting', 'Product development consulting', 'Design consultant', 'Brand consultant','Other']

module.exports = { BeautySalonServices, DentalService, AutoRepairService, AutoDetailingService, CleanServices, HandymanServices, LawyerServices, WeddingPlannerService,BusinessCousetingService }