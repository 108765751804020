import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from 'react-router-dom';
import Banner3 from '../assets/bg-banner3.jpg';
import RealState from '../components/RealState';
import AutoDealership from '../components/AutoDealership';
import Photography from '../components/Photography';
import { OtherServices } from '../components/OtherServices';
import CateringServices from '../components/CateringServices';
import RideTaxi from '../components/RideTaxi';
import DeliveryService from '../components/DeliveryService'
import CleaningServices from '../components/CleaningServices';
import AutoRepair from '../components/AutoRepair';
// import { Insurance } from '../components/Insurance';
// import { AutoDetailing } from '../components/AutoDetailing';
import Remodeling from '../components/Remodeling';
import RestaurantAndEvent from '../components/RestaurantAndEvent';
// import { Plumbing } from '../components/Plumbing';
// import { Electrician } from '../components/Electrician';
// import { HeatingCoolingVentilation } from '../components/HeatingCoolingVentilation';
// import { Painting } from '../components/Painting';
// import { Roofing } from '../components/Roofing';
// import { Handyman } from '../components/Handyman';
import Entertainment from '../components/Entertainment';
import Landscaping from '../components/Landscaping';
import FlowerAndPlant from '../components/FlowerAndPlant';
import MovingCompony from '../components/MovingCompony';

export const RequestQuote = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);
    const { state } = useLocation();
    let requestQuoteService = state.requestQuoteService;

    const renderServicePage = () => {
        switch (requestQuoteService) {
            case 'Auto Repair':
                return <AutoRepair businessDetails={state} />;
            case 'Catering Service':
                return <CateringServices businessDetails={state} />;
            case 'Restaurant & Event Venue':
                return <RestaurantAndEvent businessDetails={state} />;
            // case 'Bakery & Caffe':
            //     return <CateringServices businessDetails={state} />;
            case 'Entertainment & Event Planning':
                return <Entertainment businessDetails={state} />;
            case 'Photography & videography':
                return <Photography businessDetails={state} />;
            case 'Flower & plant':
                return <FlowerAndPlant businessDetails={state} />;
            // case 'Beauty salon':
            //     return <CateringServices businessDetails={state} />;
            // case 'Pet care':
            //     return <CateringServices businessDetails={state} />;
            case 'Auto Dealership':
                return <AutoDealership businessDetails={state} />;
            case 'Ride service':
                return <RideTaxi businessDetails={state} />;
            case 'Delivery Service':
                return <DeliveryService businessDetails={state} />;
            case 'Cleaning Service':
                return <CleaningServices businessDetails={state} />;
            case 'Moving Companies & Storage facilities':
                return <MovingCompony businessDetails={state} />;
            // case 'Duct cleaning':
            //     return <CateringServices businessDetails={state} />;
            // case 'Power/Pressure washing':
            //     return <CateringServices businessDetails={state} />;
            // case 'Dry cleaning and alternations':
            //     return <CateringServices businessDetails={state} />;
            // case 'Care Services':
            //     return <CateringServices businessDetails={state} />;
            // case 'Tutoring (Lesson) services':
            //     return <CateringServices businessDetails={state} />;
            // case 'Counseling Services':
            //     return <CateringServices businessDetails={state} />;
            // case 'Estate Planning (Lawyer & Real Estate)':
            //     return <CateringServices businessDetails={state} />;
            // case 'Financial & Accounting Services':
            //     return <CateringServices businessDetails={state} />;
            // case 'Insurance':
            //     return <Insurance  businessDetails={state} />;
            case 'Real Estate (Apartment Rental)':
                return <RealState businessDetails={state} />;
            case 'Landscaping (Lawn Mow) Services':
                return <Landscaping businessDetails={state} />;
            case 'Remodeling':
                return <Remodeling businessDetails={state} />;
            // case 'Electrical Services':
            //     return <CateringServices businessDetails={state} />;
            // case 'Plumbing':
            //     return <Plumbing businessDetails={state} />;
            // case 'Heating, Ventilation & Air conditioning':
            //     return <HeatingCoolingVentilation businessDetails={state} />;
            // case 'Painting':
            //     return <Painting  businessDetails={state} />;
            // case 'Roofing':
            //     return <Roofing  businessDetails={state} />;
            // case 'Tree and Shrubs Services':
            //     return <CateringServices businessDetails={state} />;
            // case 'Locksmiths':
            //     return <CateringServices businessDetails={state} />;
            // case 'Grocery':
            //     return <CateringServices businessDetails={state} />;
            // case 'Home Maintenance store':
            //     return <CateringServices businessDetails={state} />;
            // case 'Furniture Store':
            //     return <CateringServices businessDetails={state} />;
            // case 'Wine Store':
            //     return <CateringServices businessDetails={state} />;
            // case 'Shopping':
            //     return <CateringServices businessDetails={state} />;
            // case 'Cell phone repair':
            //     return <CateringServices businessDetails={state} />;
            // case 'Personal shopper':
            //     return <CateringServices businessDetails={state} />;

            // Add more cases for other services
            default:
                // return <ComingSoon />;
                return <div className='userinfo-quote-service py-4'>
                    <div className='container'>
                        <div className='text-content-center'>
                            <h1>Coming soon....</h1>
                        </div>
                    </div>
                </div>
        }
    };

    return (
        <>
            <Helmet>
                <title>Request Quote | Couponranking</title>
            </Helmet>
            <div className='requestQuote-page section-bg-image pt-4 pb-5' style={{ backgroundImage: `url(${Banner3})` }}>
                <div className="container">
                    <div className="form-wrapper mx-auto" style={{ maxWidth: '900px' }}>
                        <form className='card border-0 shadow p-3 p-lg-5'>
                            {renderServicePage()}
                        </form>
                    </div>
                </div >
            </div>
        </>
    )
}
