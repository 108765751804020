import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
// Import Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
import { Rating } from 'react-simple-star-rating'
import { useLocation, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

const Business = [
    {
        image: require('../assets/business-cleaning.jpg'),
        name: "Johnson Cleaning",
        timing: "Open 11:00 am - 8:00pm",
        address: "Champaign, Il.",
    },
    {
        image: require('../assets/business-repair.jpg'),
        name: "Rick Auto Repair",
        timing: "Close 10:00pm - 8:00am",
        address: "Danvile, Il.",
    },
    {
        image: require('../assets/business-grocery.jpg'),
        name: "Jason Grocery Store",
        timing: "Open 11:00 am - 8:00pm",
        address: "Champaign, Il.",
    },
    {
        image: require('../assets/business-cafe.jpg'),
        name: "Johny Cafe",
        timing: "Open 11:00 am - 8:00pm",
        address: "Danvile, Il.",
    },
    {
        image: require('../assets/business-cleaning.jpg'),
        name: "Johnson Cleaning",
        timing: "Open 11:00 am - 8:00pm",
        address: "Champaign, Il.",
    },
    {
        image: require('../assets/business-cleaning.jpg'),
        name: "Johnson Cleaning",
        timing: "Open 11:00 am - 8:00pm",
        address: "Champaign, Il.",
    },
    {
        image: require('../assets/business-repair.jpg'),
        name: "Rick Auto Repair",
        timing: "Close 10:00pm - 8:00am",
        address: "Danvile, Il.",
    },
    {
        image: require('../assets/business-grocery.jpg'),
        name: "Jason Grocery Store",
        timing: "Open 11:00 am - 8:00pm",
        address: "Champaign, Il.",
    },
    {
        image: require('../assets/business-cafe.jpg'),
        name: "Johny Cafe",
        timing: "Open 11:00 am - 8:00pm",
        address: "Danvile, Il.",
    },
    {
        image: require('../assets/business-cleaning.jpg'),
        name: "Johnson Cleaning",
        timing: "Open 11:00 am - 8:00pm",
        address: "Champaign, Il.",
    },
    {
        image: require('../assets/business-cafe.jpg'),
        name: "Johny Cafe",
        timing: "Open 11:00 am - 8:00pm",
        address: "Danvile, Il.",
    },
    {
        image: require('../assets/business-cleaning.jpg'),
        name: "Johnson Cleaning",
        timing: "Open 11:00 am - 8:00pm",
        address: "Champaign, Il.",
    }
];

const BusinessImg = [
    {
        id: 1,
        image: require('../assets/business-cleaning.jpg'),
    },
    {
        id: 2,
        image: require('../assets/business-repair.jpg'),
    },
    {
        id: 3,
        image: require('../assets/business-grocery.jpg'),
    },
    {
        id: 4,
        image: require('../assets/business-cafe.jpg'),
    }
];

export const CouponAndReward = (props) => {
    let city = localStorage.getItem('city');
    let state = localStorage.getItem('state');
    const { getCouponBusinessList, businessList } = props;
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        async function getLocationforBusiness() {
            // You can await here
            let lat = await localStorage.getItem("latitude")
            let long = await localStorage.getItem("longitude")
            let data = {
                latitude: parseFloat(lat),
                longitude: parseFloat(long),
                type: 'coupon'
            }
            return await getCouponBusinessList(data)
        }
        getLocationforBusiness()
    }, [getCouponBusinessList])

    const navigate = useNavigate()
    let token = localStorage.getItem('token')
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const getRequiredAddress = (str, from) => {
        var arr = str.split(',');
        arr.splice(0, from);
        return arr.join(',');
    }
    var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    var d = new Date();
    var dayName = days[d.getDay()];
    var dayString = `is_${dayName}_Open`

    const [callDetails, setCallDetails] = useState({
        country_code: '',
        mobile_no: ''
    })
    const [rating, setRating] = useState(0)
    const [ratingComment, setRatingComment] = useState('')
    const [selectedReviewBusiness, setSelectedBusiness] = useState({
        businessName: '',
        businessId: ''
    })

    const [couponDirection, setCouponDirection] = useState({
        destLat: '',
        desLong: '',
        businessName: '',
        address: ''
    })

    // pagination functionality start
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + 9;  //number here represent how many item you want to show on page
    let couponBusinnessList = businessList.filter((dd) => dd.isCouponSelect || dd.isDealSelect)
    const currentItems = couponBusinnessList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(couponBusinnessList.length / 9);
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * 9) % couponBusinnessList.length;
        setItemOffset(newOffset);
    };
    //pagination functionality end

    const submitReview = () => {
        let creatorId = userInfo._id
        let data = {
            rating: rating,
            ratingFor: 'businessWebite',
            comment: ratingComment,
            creator_id: creatorId,
            receiver_id: selectedReviewBusiness.businessId
        }
        props.makeRating(data)
    }

    const sendOnDirectionPageForCouponModal = () => {
        let businessInfo = {
            destLat: couponDirection.destLat,
            desLong: couponDirection.desLong,
            businessName: couponDirection.businessName,
            address: couponDirection.address
        }
        navigate(`/direction-to-business/${couponDirection.address.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo })
    }

    const handleCouponClick = (business, type) => {
        if (token !== null) {
            setCallDetails({ country_code: business.country_code, mobile_no: business.mobile_no })
            setCouponDirection({
                destLat: business.latitude,
                desLong: business.longitude,
                businessName: business.name,
                address: business.address
            })
            if (type === 'coupon') {
                let couponData = {
                    businessId: business._id,
                    type: "coupon"
                }
                props.createAndGetCoupon(couponData)
            } else if (type === 'reward') {
                let rewardData = {
                    businessId: business._id,
                    type: "reward"
                }
                props.createAndGetCoupon(rewardData)
            }

        } else {
            navigate('/login')
        }
    }

    //handle business details on view details
    const handleBusinessDetails = (event, business) => {
        let addr = business.address ? getRequiredAddress(business.address, 1) : business.city ? business.city : business.name;
        let subcategoryname = business.subCategory.length > 0 ? business.subCategory[0].subCategoryName.replaceAll(/ /g, "-").toLowerCase() : "subcategory"
        navigate(`/business-details/${business.name.replaceAll(/ /g, "-").toLowerCase()}/${subcategoryname}/${addr.replaceAll(/ /g, "-").toLowerCase()}`, { state: { businessId: business._id } })
    }

    const checkRatingModal = (business) => {
        if (token !== null) {
            setSelectedBusiness({ businessName: business.name, businessId: business._id })
        } else {
            navigate('/login')
        }
    }

    const handleBookAnAppointmentClick = (business) => {
        let businessInfo = {
            email: business.email,
            businessName: business.name,
            country_code: business.country_code,
            phone_no: business.phone_no,
            businessId: business._id,
            bookAppointmentService: business.bookAppointmentService
        }
        navigate(`/book-an-appointment/${business.name.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo })
    }

    // handle request a quote
    const handleRequestAQuoteClick = (business) => {
        let businessInfo = {
            email: business.email,
            businessName: business.name,
            country_code: business.country_code,
            phone_no: business.mobile_no,
            businessId: business._id,
            requestQuoteService: business.requestQuoteService
        }
        navigate(`/request-a-quote/${business.name.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo })
    }

    return (
        <div className='nearby-business-sec pt-4 pb-5'>
            <Helmet>
                <title>Coupon and Rewards near {city},{state}</title>
            </Helmet>
            <div className='container'>
                <div className='section-header mw-100 text-center mb-4'>
                    <div className='section-label fs-3'>{`Top 20 best coupons and reward for businesses ${city && state ? `in ${city},${state}` : 'near me'}`}</div>
                    <div className='section-sublabel text-dark text-opacity-75'>
                        Attention customers! Introducing our new rewards program, complete with exclusive coupons just for you! Save big on you next purchase and earn point towards even more discount. Plus, be the first to know new products and sales. Sign up now and start reaping the benefits of being a loyal customer, Don’t miss out
                    </div>
                </div>

                <div className='row g-3'>
                    {
                        currentItems && currentItems.length > 0 ?
                            currentItems.map((business, index) => (
                                <div className='col-xl-4 col-lg-4 col-md-6' key={index}>
                                    <div className='business-details border rounded-3 overflow-hidden' key={index}>
                                        <div className='business-img position-relative'>
                                            <Swiper slidesPerView={1} loop={true} modules={[Autoplay, Pagination]} className="mySwiper custom-dots"
                                                autoplay={{
                                                    delay: 2500,
                                                    disableOnInteraction: false,
                                                }}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                            >
                                                {business.businessPicture && business.businessPicture.length > 0 ?
                                                    business.businessPicture.map((item, idx) => (
                                                        <SwiperSlide><img src={item.pictureUrl} key={idx} className='img-fluid' alt='Cleaning' /></SwiperSlide>
                                                    ))
                                                    :
                                                    BusinessImg.map((item, idx) => (
                                                        <SwiperSlide><img src={item.image} key={idx} className='img-fluid' alt='Cleaning' /></SwiperSlide>
                                                    ))
                                                }
                                            </Swiper>
                                            {business.isCouponSelect ?
                                                <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm z-1'
                                                    style={{ width: '50px', height: '50px' }}>
                                                    {business.couponText.split(',')[0]}</div> : ''}
                                            {business.isDealSelect ? <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm z-1' style={{ width: '50px', height: '50px' }}>{business.dealText.split(',')[0]}</div> : ''}
                                            {/* <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>20% OFF</div> */}
                                        </div>
                                        <div className="business-info bg-white text-center p-3 pb-4">
                                            <div className='name fs-18 fw-medium text-capitalize mb-1'>{business.name}</div>
                                            <div className='review-wrapper d-flex align-items-center justify-content-center gap-2 mb-1'>
                                                <div className='rating-number'>{business.rating > 0 ? business.rating.toFixed(1) : 5}</div>
                                                <div className='review-star d-flex justify-content-center gap-1'>
                                                    {business.rating > 0 ?
                                                        <Rating initialValue={business.rating} size={"20px"} readonly={true} allowFraction={true} />
                                                        :
                                                        <Rating initialValue={5} size={"20px"} readonly={true} />
                                                    }
                                                </div>
                                                <div className='rating-number'>{business.ratingCount > 0 ? `(${business.ratingCount})` : '(1)'}</div>
                                            </div>
                                            <div className='name text-capitalize mb-1'>{business.subCategory.length > 0 ? business.subCategory[0].subCategoryName : ''}</div>
                                            <div className='service-rate text-muted text-capitalize'>{business.business_weekdays[dayString] == true ? `Open till ${business.business_weekdays[dayName].close_time_hour}:${business.business_weekdays[dayName].close_time_min} ${business.business_weekdays[dayName].close_time_am_pm}` : 'Closed today'}</div>
                                            <div className='address text-muted text-truncate pb-1'>{business.address ? getRequiredAddress(business.address, 1) : business.city}</div>
                                            {business.isCouponSelect ? business.couponText.includes('rewards') ?
                                                <div type="button" className='text-white shadow p-3 w-100 mt-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#rewardModal" : ''}
                                                    style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                    onClick={() => handleCouponClick(business, 'reward')}
                                                >
                                                    {business.couponText ? business.couponText.split(',')[1] : 'Click here for the deal min order $30'}
                                                </div>
                                                :
                                                <div type="button" className='text-white shadow p-3 w-100 mt-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#couponModal" : ''} style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                    onClick={() => handleCouponClick(business, 'coupon')}
                                                >{business.couponText ? business.couponText.split(',')[1] : 'Click here for the deal min order $30'}</div> : ''}
                                            {/* <div className='text-white shadow lh-sm px-3 py-2 mt-2' style={{ background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }} >Click here for the deal min order $30</div> */}
                                            <div className='d-flex gap-2 mt-2'>
                                                {business.isCallForQuoteSelect ? <div type='button' className='d-flex align-items-center justify-content-center text-white px-3 py-2 flex-fill lh-sm' style={{ background: 'linear-gradient(to bottom right, #00cc66, #FF9A5A)', minHeight: '55px', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }} onClick={() => handleRequestAQuoteClick(business)}>Request a quote</div> : ''}
                                                {business.isBookForAppointmentSelect ? <div type='button' className='d-flex align-items-center justify-content-center text-white px-3 py-2 flex-fill lh-sm' style={{ background: 'linear-gradient(to bottom right, #0073e6, #FF6666)', minHeight: '55px', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                    onClick={() => handleBookAnAppointmentClick(business)}>Book An Appointment</div> : ''}
                                            </div>
                                            <div className='write-review mt-2'>
                                                <button className='btn btn-danger w-100 d-inline-flex align-items-center justify-content-center py-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#reviewModal1" : ''} onClick={() => checkRatingModal(business)}><i className="bi bi-pencil me-2"></i>Write a review</button>
                                            </div>
                                            <button type='button' to={'/business-details'} className='w-100 btn btn-primary mt-2' onClick={(event) => handleBusinessDetails(event, business)}>View Detail</button>
                                        </div>
                                    </div>
                                </div>
                            ))
                            :
                            <div className='fs-3 text-danger text-center'>
                                There is no business in your area!
                            </div>
                    }
                    {/* pagination section */}
                    <div className='pagination-wrapper pt-3'>
                        <ReactPaginate
                            className="pagination justify-content-center m-1"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            nextLabel="&raquo;"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={1}
                            marginPagesDisplayed={2}
                            pageCount={pageCount}
                            previousLabel="&laquo;"
                            renderOnZeroPageCount={null}
                            containerClassName="pagination"
                            activeClassName="active"
                        />
                    </div>
                </div>
            </div>
            {/* infoDetails Modal */}
            <div className="modal fade" id="infoDetails" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="infoDetailsLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-body text-center p-4">
                            <div className="link-item mb-3">
                                <Link to={`tel:${callDetails.country_code + callDetails.mobile_no}`} className='fs-18 text-decoration-none text-primary'>
                                    <i className="bi bi-telephone-fill text-primary me-2"></i> {callDetails.country_code + callDetails.mobile_no}
                                </Link>
                            </div>
                            <button type="button" className="btn btn-secondary px-5" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Review Model Popup */}
            <div className="modal fade" id="reviewModal1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="reviewModal1Label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-white px-sm-4">
                            <h1 className="modal-title fs-18 text-capitalize lh-sm" id="reviewModal1Label">{selectedReviewBusiness.businessName}</h1>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => setRating(0)}></button>
                        </div>
                        <div className="modal-body px-3 px-sm-4 py-4">
                            <div className="review-wrapper mb-2">
                                <div className="star-wrapper d-flex gap-2 fs-5 mb-1">
                                    <Rating emptySymbol="bi bi-star" fullSymbol="bi bi-star-fill"
                                        initialValue={rating}
                                        value={rating}
                                        onClick={(value) => setRating(value)}
                                    />
                                </div>
                                <p className='m-0 text-muted'>Write your review for <small className='fw-medium text-capitalize'>{selectedReviewBusiness.businessName}</small></p>
                            </div>
                            <div className="review-message">
                                <textarea className="form-control small" id="dealMessage" rows="5" placeholder='Enter message here...' required
                                    onChange={(event) => setRatingComment(event.target.value)}
                                ></textarea>
                                <div className='invalid-feedback'></div>
                            </div>
                            <button className='w-100 btn btn-primary py-2 mt-3' data-bs-dismiss="modal" onClick={() => submitReview()}>Submit Review</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* coupon code with call and direction modal */}
            <div className="modal fade" id="couponModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="couponModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-white">
                            <h1 className="modal-title fs-18 text-capitalize lh-sm" id="couponModalLabel">Promo Code</h1>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body px-3 py-4">
                            <p className='fs-18 mb-0'>
                                Promo Code: <span className='fw-medium text-muted'>{props.couponDetails.coupon}</span>
                            </p>
                            <p className='fs-18 mb-2'>
                                Expire On: <span className='fw-medium text-muted'>{props.couponDetails.expiryDate ? new Date(props.couponDetails.expiryDate).toLocaleDateString('en-us') : ''}</span>
                            </p>
                            <p>
                                Makes sure you present your code to the cashier to redeem your coupon or when using the phone to redeem you coupon, read out the coupon code to the cashier and you successful redeem your coupon. ONE PROMO CODE PER ORDER (per purchase or per service). Multiple promo code per order will not be allowed.
                            </p>
                            <div className='d-flex gap-2'>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='w-50 bg-light border rounded-pill text-center py-2 px-3'><i className="bi bi-telephone-fill text-primary me-2"></i>Call</button>
                                <button type="button" className='w-50 bg-light border rounded-pill text-center py-2 px-3' data-bs-dismiss="modal" onClick={() => sendOnDirectionPageForCouponModal()}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* reward with call and direction modal */}
            <div className="modal fade" id="rewardModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="rewardModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-white">
                            <h1 className="modal-title fs-18 text-capitalize lh-sm" id="rewardModalLabel">Your Reward</h1>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body px-3 py-4">
                            <p className='fs-18 mb-0'>
                                Reward: <span className='fw-medium text-muted  mb-2'><Rating initialValue={props.couponDetails && props.couponDetails.rewardStar ? props.couponDetails.rewardStar : 0} size={"20px"} readonly={true} allowFraction={true} /></span>
                            </p>
                            <p className='fs-18 mb-2'>
                                Expire On: <span className='fw-medium text-muted'>{props.couponDetails.expiryDate ? new Date(props.couponDetails.expiryDate).toLocaleDateString('en-us') : ''}</span>
                            </p>
                            <p>
                                Make sure you present your rewards to the cashier to earn your points (stars) and redeem your credit or when ordering online, mention your name to the cashier to earn your star (point) and redeem your credit.
                            </p>
                            <div className='d-flex gap-2'>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='w-50 bg-light border rounded-pill text-center py-2 px-3'><i className="bi bi-telephone-fill text-primary me-2"></i>Call</button>
                                <button type="button" className='w-50 bg-light border rounded-pill text-center py-2 px-3' data-bs-dismiss="modal" onClick={() => sendOnDirectionPageForCouponModal()}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
        businessList: state.business.couponBusinessList,
        couponDetails: state.rewardAndCoupon.couponDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCouponBusinessList: (data) => dispatch(actions.getCouponBusinessList(data)),
        clickPerCount: (data) => dispatch(actions.clickPerCount(data)),
        makeRating: (data) => dispatch(actions.makeRating(data)),
        createAndGetCoupon: (data) => dispatch(actions.createAndGetCoupon(data))
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(CouponAndReward))
