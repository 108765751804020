import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Helmet } from "react-helmet";
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserInfoForQuoteService from './UserInfoForQuoteService';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import Autocomplete from 'react-google-autocomplete';
import Geocode from "react-geocode";
import { getDistance } from 'geolib';

Geocode.setApiKey("AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q");
Geocode.enableDebug();
let Budget = ["$0.99 - $4.99", "$5.00 - $9.99", "$10.00 - $14.99", "$15.00 - $19.99", "Not Sure", "Other"];
let Tip = ["$0.00", "$5.00", "$10.00", "$15.00", "$20.00", "Not Sure", "Other"];
let PaymentMethod = ["Cash app", "Zelle", "Cash", "Debit card", "Credit Card", "Not Sure", "Other"];
const VehicleProblem = ['Fuel shortage', 'Battery replacement', 'Accident', 'It will not start', 'Engine issue', 'Vehicle jumpstart', 'Tire change', 'Fuel, oil or fluid delivery', 'Not Sure', 'Other']
const DeliveryService = (props) => {
    const [serviceData, setServiceData] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [userBusinessName, setUserBusinessName] = useState('')
    const [deliveryServiceType, setDeliveryServiceType] = useState('');
    const [otherDeliveryServiceType, setOtherDeliveryServiceType] = useState(false);
    const [vehicleTypeForTow, setVehicleTypeForTow] = useState('');
    const [otherVehicleTypeForTow, setotherVehicleTypeForTow] = useState(false);
    const [towingReason, setTowingReason] = useState('');
    const [otherTowingReason, setotherTowingReason] = useState(false);
    const [weightEquipment, setWeightEquipment] = useState();
    const [otherWeightEquipment, setotherWeightEquipment] = useState(false);
    const [vehicleTypeForEquipment, setVehicleTypeForEquipment] = useState();
    const [otherVehicleTypeForEquipment, setotherVehicleTypeForEquipment] = useState(false);
    const [vehicleTypeForTransport, setVehicleTypeForTransport] = useState();
    const [otherVehicleTypeForTransport, setotherVehicleTypeForTransport] = useState(false);
    const [transportVehicleCount, setTransportVehicleCount] = useState();
    const [otherTransportVehicleCount, setOtherTransportVehicleCount] = useState(false);
    const [transportingVehicle, setTransportingVehicle] = useState();
    const [otherTransportingVehicle, setOtherTransportingVehicle] = useState(false);
    const [equipmentTransport, setEquipmentTransport] = useState();
    const [otherEquipmentTransport, setOtherEquipmentTransport] = useState(false);
    const [productDelivery, setProductDelivery] = useState();
    const [otherProductDelivery, setOtherProductDelivery] = useState(false);
    const [locationType, setLocationType] = useState('');
    const [otherLocationType, setOtherLocationType] = useState(false);
    const [contactMode, setContactMode] = useState('');
    const [otherContactMode, setOtherContactMode] = useState(false);
    const [budgetForService, setBudget] = useState('');
    const [otherBudget, setOtherBudget] = useState(false);
    const [tipForService, setTip] = useState('');
    const [otherTip, setOtherTip] = useState(false);
    const [payment, setPaymentMethod] = useState('');
    const [otherPaymentMethod, setOtherPaymentMethod] = useState(false);
    const [pickupLocation, setPickupLocation] = useState('');
    const [pickupLat, setPickupLat] = useState('');
    const [pickupLong, setPickupLong] = useState('');
    const [dropOffLocation, setDropOffLocation] = useState('');
    const [dropLat, setDropLat] = useState('');
    const [dropLong, setDropLong] = useState('');
    const [pickUpDate, setPickUpDate] = useState(new Date());
    const [pickupTime, setPickUpTime] = useState('');
    const [dropOffDate, setDropOffDate] = useState(new Date());
    const [dropOffTime, setDropOffTime] = useState('');
    const [otherInfo, setOtherInfo] = useState('');
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);

    const handleDeliveryServiceType = (value) => {
        if (value === 'other') {
            setOtherDeliveryServiceType(true)
            setDeliveryServiceType('')
        } else {
            setOtherDeliveryServiceType(false)
            setDeliveryServiceType(value)
        }
    }
    const handleVehicleTypeForTow = (value) => {
        if (value === 'other') {
            setotherVehicleTypeForTow(true)
            setVehicleTypeForTow('')
        } else {
            setotherVehicleTypeForTow(false)
            setVehicleTypeForTow(value)
        }
    }

    const handleTowingReason = (value) => {
        if (value === 'Other') {
            setotherTowingReason(true)
            setTowingReason('')
        } else {
            setotherTowingReason(false)
            setTowingReason(value)
        }
    }


    const handleWeightEquipment = (value) => {
        if (value === 'other') {
            setotherWeightEquipment(true)
            setWeightEquipment('')
        } else {
            setotherWeightEquipment(false)
            setWeightEquipment(value)
        }
    }
    const handleVehicleTypeForEquipment = (value) => {
        if (value === 'other') {
            setotherVehicleTypeForEquipment(true)
            setVehicleTypeForEquipment('')
        } else {
            setotherVehicleTypeForEquipment(false)
            setVehicleTypeForEquipment(value)
        }
    }
    const handleVehicleTypeForTransport = (value) => {
        if (value === 'other') {
            setotherVehicleTypeForTransport(true)
            setVehicleTypeForTransport('')
        } else {
            setotherVehicleTypeForTransport(false)
            setVehicleTypeForTransport(value)
        }
    }

    const handleTransportVehicleCount = (value) => {
        if (value === 'other') {
            setOtherTransportVehicleCount(true)
            setTransportVehicleCount('')
        } else {
            setOtherTransportVehicleCount(false)
            setTransportVehicleCount(value)
        }
    }

    const handleEquipmentTransport = (value) => {
        if (value === 'other') {
            setOtherEquipmentTransport(true)
            setEquipmentTransport('')
        } else {
            setOtherEquipmentTransport(false)
            setEquipmentTransport(value)
        }
    }
    const handleTransportingVehicle = (value) => {
        if (value === 'other') {
            setOtherTransportingVehicle(true)
            setTransportingVehicle('')
        } else {
            setOtherTransportingVehicle(false)
            setTransportingVehicle(value)
        }
    }
    const handleProductDelivery = (value) => {
        if (value === 'other') {
            setOtherProductDelivery(true)
            setProductDelivery('')
        } else {
            setOtherProductDelivery(false)
            setProductDelivery(value)
        }
    }

    const handleLocationType = (value) => {
        if (value === 'other') {
            setOtherLocationType(true)
            setLocationType('')
        } else {
            setOtherLocationType(false)
            setLocationType(value)
        }
    }

    const handleContactMode = (value) => {
        if (value === 'other') {
            setOtherContactMode(true)
            setContactMode('')
        } else {
            setOtherContactMode(false)
            setContactMode(value)
        }
    }
    const handleBudget = (value) => {
        if (value === 'Other') {
            setOtherBudget(true)
            setBudget('')
        } else {
            setOtherBudget(false)
            setBudget(value)
        }
    }
    const handleTip = (value) => {
        if (value === 'Other') {
            setOtherTip(true)
            setTip('')
        } else {
            setOtherTip(false)
            setTip(value)
        }
    }
    const handlePaymentMethod = (value) => {
        if (value === 'Other') {
            setOtherPaymentMethod(true)
            setPaymentMethod('')
        } else {
            setOtherPaymentMethod(false)
            setPaymentMethod(value)
        }
    }
    //for pickup time in am/pm format
    const handleTimeChange = (event) => {
        var timeSplit = event.target.value.split(':'),
            hours,
            minutes,
            hours = timeSplit[0];
        minutes = timeSplit[1];
        // Check whether AM or PM
        let newformat = hours >= 12 ? 'PM' : 'AM';
        // Find current hour in AM-PM Format
        hours = hours % 12;

        // To display "0" as "12"
        hours = hours ? hours : 12;
        // minutes = minutes < 10 ? '0' + minutes : minutes;
        let selectedTime = hours + ':' + minutes + ' ' + newformat
        setPickUpTime(selectedTime)
    }
    const handleDropOffTimeChange = (event) => {
        var timeSplit = event.target.value.split(':'),
            hours,
            minutes,
            hours = timeSplit[0];
        minutes = timeSplit[1];
        // Check whether AM or PM
        let newformat = hours >= 12 ? 'PM' : 'AM';
        // Find current hour in AM-PM Format
        hours = hours % 12;

        // To display "0" as "12"
        hours = hours ? hours : 12;
        // minutes = minutes < 10 ? '0' + minutes : minutes;
        let selectedTime = hours + ':' + minutes + ' ' + newformat
        setDropOffTime(selectedTime)
    }
    const handleLocationSelection = (place, type) => {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q', {
            method: 'GET'
        }).then((response) => response.json())
            .then((data) => {
                if (data.results[1]) {
                    const address = data.results[0].formatted_address
                    if (type === 'pickup') {
                        setPickupLocation(address)
                        setPickupLat(lat)
                        setPickupLong(lng)
                    } else {
                        setDropOffLocation(address)
                        setDropLat(lat)
                        setDropLong(lng)
                    }
                }
            })
            .catch((error) => {
                toast.error(error, {
                    autoClose: 700,
                    hideProgressBar: true,
                })
            })
    };

    //  submit
    const handleSubmit = (e) => {
        e.preventDefault()
        if (userBusinessName && deliveryServiceType && pickUpDate && pickupLocation
            && pickUpDate && pickupTime && contactMode
            && budgetForService && tipForService && payment) {
            let UsPickUpDate = new Date(pickUpDate).toLocaleDateString("en-US")
            let UsDropOffDate = new Date(dropOffDate).toLocaleDateString("en-US")
            let distanceForDeliveryInMeter = getDistance(
                { latitude: pickupLat, longitude: pickupLong },
                { latitude: dropLat, longitude: dropLong }
            );
            let distanceForDelivery = distanceForDeliveryInMeter ? (distanceForDeliveryInMeter * 0.000621371).toFixed(2) : ''
            let quoteDetails = {
                "Requested Service": "Delivery Service",
                "What is the business name": userBusinessName,
                "What type of delivery services do you want?": deliveryServiceType,
                "Pickup Location": pickupLocation,
                "Pickup Date": UsPickUpDate,
                "Pickup Time": pickupTime,
                "What is the best way to contact you?": contactMode,
                "What is your budget for this service?": budgetForService,
                "Select a tip for this service?": tipForService,
                "What is your payment method for this service?": payment,
                "Any other details": otherInfo
            }
            if (distanceForDelivery) {
                quoteDetails['Distance betweeen location'] = `${distanceForDelivery} miles`
            }
            if (deliveryServiceType !== 'Road Side Assistance') {
                if (dropOffLocation) {
                    quoteDetails["Drop Off Location"] = dropOffLocation
                } else {
                    toast.warning('Drop off location required!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }
            }
            if (deliveryServiceType !== 'Road Side Assistance' && deliveryServiceType !== 'Restaurant(catering)') {
                if (dropOffLocation && locationType) {
                    quoteDetails["Drop Off Location"] = dropOffLocation
                    quoteDetails["Deliver home or office"] = locationType
                } else {
                    toast.warning('Drop off location required!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }
            }

            let data = {
                businessName: props.businessDetails.businessName,
                businessEmail: props.businessDetails.email,
                businessId: props.businessDetails.businessId,
                clickType: 'requestQuote',
                quoteDetails: quoteDetails
            }
            if (deliveryServiceType === 'Vehicle Transportation' || deliveryServiceType === 'Towing' || deliveryServiceType === 'Courier' ||
                deliveryServiceType === 'Heavy equipment' || deliveryServiceType === 'home(office) moving') {

                if (dropOffDate && dropOffTime) {
                    quoteDetails['Drop off date'] = UsDropOffDate;
                    quoteDetails['Drop Off Time'] = dropOffTime;
                    if (deliveryServiceType === 'Vehicle Transportation') {
                        if (vehicleTypeForTransport && transportVehicleCount && transportingVehicle) {
                            quoteDetails['What type of vehicle are you transporting?'] = vehicleTypeForTransport;
                            quoteDetails['How many vehicles are you transporting?'] = transportVehicleCount;
                            quoteDetails['Where are you transporting the vehicle?'] = transportingVehicle;
                            setServiceData(data)
                            setIsSubmit(true)
                        } else {
                            toast.warning('Please Select All Vehicle Transport data', {
                                autoClose: 700,
                                hideProgressBar: true,
                            })
                        }
                    } else if (deliveryServiceType === 'Towing') {
                        if (vehicleTypeForTow && towingReason) {
                            quoteDetails['What type of vehicle do you want to tow?'] = vehicleTypeForTow;
                            quoteDetails['What is wrong with the vehicle?'] = towingReason
                            setServiceData(data)
                            setIsSubmit(true)
                        } else {
                            toast.warning('Please Select towing vehicle and towing reason', {
                                autoClose: 700,
                                hideProgressBar: true,
                            })
                        }
                    } else if (deliveryServiceType === 'Courier') {
                        if (weightEquipment) {
                            quoteDetails['What is the weight of the product or equipment?'] = weightEquipment;
                            setServiceData(data)
                            setIsSubmit(true)
                        } else {
                            toast.warning('Please Select Weight of product', {
                                autoClose: 700,
                                hideProgressBar: true,
                            })
                        }
                    } else if (deliveryServiceType === 'Heavy equipment') {
                        if (weightEquipment && vehicleTypeForEquipment && equipmentTransport) {
                            quoteDetails['What is the weight of the product or equipment?'] = weightEquipment;
                            quoteDetails['What type of vehicle do you need to move the equipment?'] = vehicleTypeForEquipment;
                            quoteDetails['What type of equipment are you transporting?'] = equipmentTransport
                            setServiceData(data)
                            setIsSubmit(true)
                        } else {
                            toast.warning('Please Select all Equipment field', {
                                autoClose: 700,
                                hideProgressBar: true,
                            })
                        }
                    } else if (deliveryServiceType === 'home(office) moving') {
                        setServiceData(data)
                        setIsSubmit(true)
                    }
                } else {
                    toast.warning('Please Select DropOff Date And DropOff Time', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }
            } else if (deliveryServiceType === 'Road Side Assistance') {
                quoteDetails['What is wrong with the vehicle?'] = towingReason
                setServiceData(data)
                setIsSubmit(true)
            }
            else {
                if (deliveryServiceType === 'Light products') {
                    if (weightEquipment && productDelivery) {
                        quoteDetails['What is the weight of the product or equipment?'] = weightEquipment;
                        quoteDetails['What type of product(s) are you delivering?'] = productDelivery
                        setServiceData(data)
                        setIsSubmit(true)
                    } else {
                        toast.warning('Please Select Weight and Type of Product', {
                            autoClose: 700,
                            hideProgressBar: true,
                        })
                    }

                } else {
                    setServiceData(data)
                    setIsSubmit(true)
                }
            }
        } else {
            toast.warning('Please Select All required field!', {
                autoClose: 700,
                hideProgressBar: true,
            })
        }
    }

    return (
        <>
            {!isSubmit ? (
                <div id="rideTaxi" className='ride-delivery-sec card shadow-sm py-4 py-md-5 px-3 px-md-5 mt-4'>
                    <Helmet>
                        <title>Request Quote For Delivery Service</title>
                    </Helmet>
                    <ToastContainer />
                    <div className="section-label fs-3 mb-4">Delivery Service(Towing, food, couriers & others)</div>
                    <div className="mb-3">
                        <label htmlFor="userBusiness" className="form-label fs-6 mb-2">What is the business name?</label>
                        <div className='user_businessname'>
                            <input type="text" className="form-control mt-2" id="userBusiness" placeholder='Business name...' required
                                onChange={(e) => setUserBusinessName(e.target.value)} />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="deliveryService" className="form-label fs-6 mb-2">What type of delivery services do you want?</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupforDeliveryService" id="restaurant" required
                                    onClick={() => handleDeliveryServiceType('Restaurant(catering)')} />
                                <label className="form-check-label" htmlFor="restaurant">Restaurant(catering)</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupforDeliveryService" id="grocery"
                                    onClick={() => handleDeliveryServiceType('Grocery')} />
                                <label className="form-check-label" htmlFor="grocery">Grocery</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupforDeliveryService" id="vehicle_transportation"
                                    onClick={() => handleDeliveryServiceType('Vehicle Transportation')} />
                                <label className="form-check-label" htmlFor="vehicle_transportation">Vehicle Transportation</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupforDeliveryService" id="towing"
                                    onClick={() => handleDeliveryServiceType('Towing')} />
                                <label className="form-check-label" htmlFor="towing">Towing</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupforDeliveryService" id="towing"
                                    onClick={() => handleDeliveryServiceType('Road Side Assistance')} />
                                <label className="form-check-label" htmlFor="towing">Road Side Assistance</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupforDeliveryService" id="courier"
                                    onClick={() => handleDeliveryServiceType('Courier')} />
                                <label className="form-check-label" htmlFor="courier">Courier</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupforDeliveryService" id="heavy_equipment"
                                    onClick={() => handleDeliveryServiceType('Heavy equipment')} />
                                <label className="form-check-label" htmlFor="heavy_equipment">Heavy equipment</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupforDeliveryService" id="light_products"
                                    onClick={() => handleDeliveryServiceType('Light products')} />
                                <label className="form-check-label" htmlFor="light_products">Light products</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupforDeliveryService" id="home(office)"
                                    onClick={() => handleDeliveryServiceType('home(office) moving')} />
                                <label className="form-check-label" htmlFor="home(office)">home(office) moving</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupforDeliveryService" id="otherDeliveryType"
                                    onClick={() => handleDeliveryServiceType('other')} />
                                <label className="form-check-label" htmlFor="otherDeliveryType">Other</label>
                            </div>
                        </div>
                        {
                            otherDeliveryServiceType ?
                                <input type="text" className="form-control mt-2" id="OtherDeliveryType" placeholder='Other delivery...' required
                                    onChange={(e) => setDeliveryServiceType(e.target.value)} />
                                : ''
                        }
                    </div>
                    {
                        deliveryServiceType === 'Vehicle Transportation' ?
                            <div className='Vehicle Transportation'>
                                <div className="mb-3">
                                    <label htmlFor="chooseVehicleTypeForTransport" className="form-label fs-6 mb-2">What type of vehicle are you transporting?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForVehicleTransport" id="CAR"
                                                onClick={() => handleVehicleTypeForTransport('Car')} />
                                            <label className="form-check-label" htmlFor="CAR">Car</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForVehicleTransport" id="SUV" required
                                                onClick={() => handleVehicleTypeForTransport('SUV')} />
                                            <label className="form-check-label" htmlFor="SUV">SUV</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForVehicleTransport" id="VAN"
                                                onClick={() => handleVehicleTypeForTransport('Van')} />
                                            <label className="form-check-label" htmlFor="VAN">Van</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForVehicleTransport" id="pick_up_truck"
                                                onClick={() => handleVehicleTypeForTransport('Pick up truck')} />
                                            <label className="form-check-label" htmlFor="pick_up_truck">Pick up truck</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForVehicleTransport" id="otherVehicleTypeForTransport"
                                                onClick={() => handleVehicleTypeForTransport('other')} />
                                            <label className="form-check-label" htmlFor="otherVehicleTypeForTransport">Other</label>
                                        </div>
                                    </div>
                                    {
                                        otherVehicleTypeForTransport ?
                                            <input type="text" className="form-control mt-2" id="otherVehicleTypeForTransport" placeholder='Other Vehicle...' required
                                                onChange={(e) => setVehicleTypeForTransport(e.target.value)} />
                                            : ''
                                    }
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="choosePassenger" className="form-label fs-6 mb-2">How many vehicles are you transporting?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForVehicleCountTnp" id="VCountTrans1" required
                                                onClick={() => handleTransportVehicleCount('1')} />
                                            <label className="form-check-label" htmlFor="VCountTrans1">1</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForVehicleCountTnp" id="VCountTrans23"
                                                onClick={() => handleTransportVehicleCount('2-3')} />
                                            <label className="form-check-label" htmlFor="VCountTrans23">2-3</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForVehicleCountTnp" id="VCountTrans45"
                                                onClick={() => handleTransportVehicleCount('4-5')} />
                                            <label className="form-check-label" htmlFor="VCountTrans45">4-5</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForVehicleCountTnp" id="not_sure_transC"
                                                onClick={() => handleTransportVehicleCount('Not Sure')} />
                                            <label className="form-check-label" htmlFor="not_sure_transC">Not Sure</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForVehicleCountTnp" id="otherVehicleCountTnp"
                                                onClick={() => handleTransportVehicleCount('other')} />
                                            <label className="form-check-label" htmlFor="otherVehicleCountTnp">Other</label>
                                        </div>
                                    </div>
                                    {
                                        otherTransportVehicleCount ?
                                            <input type="text" className="form-control mt-2" id="otherVehicleCountTnp" placeholder='Other Vehicle count...' required
                                                onChange={(e) => setTransportVehicleCount(e.target.value)} />
                                            : ''
                                    }
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="chooseTransportingVehicle" className="form-label fs-6 mb-2">Where are you transporting the vehicle?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioForTransportingVehicle" id="transporting_home" required
                                                onClick={() => handleTransportingVehicle('Home')} />
                                            <label className="form-check-label" htmlFor="transporting_home">Home</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioForTransportingVehicle" id="auction_to_dealership"
                                                onClick={() => handleTransportingVehicle('Auction to dealership')} />
                                            <label className="form-check-label" htmlFor="auction_to_dealership">Auction to dealership</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioForTransportingVehicle" id="dealership_to_auction"
                                                onClick={() => handleTransportingVehicle('Dealership to auction')} />
                                            <label className="form-check-label" htmlFor="dealership_to_auction">Dealership to auction</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioForTransportingVehicle" id="transporting_office"
                                                onClick={() => handleTransportingVehicle('office')} />
                                            <label className="form-check-label" htmlFor="transporting_office">office</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioForTransportingVehicle" id="not_sure_transV"
                                                onClick={() => handleTransportingVehicle('Not Sure')} />
                                            <label className="form-check-label" htmlFor="not_sure_transV">Not Sure</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioForTransportingVehicle" id="otherVehicleTrans"
                                                onClick={() => handleTransportingVehicle('other')} />
                                            <label className="form-check-label" htmlFor="otherVehicleTrans">Other</label>
                                        </div>
                                    </div>
                                    {
                                        otherTransportingVehicle ?
                                            <input type="text" className="form-control mt-2" id="otherVehicleTransporting" placeholder='Other transporting vehicle...' required
                                                onChange={(e) => setTransportingVehicle(e.target.value)} />
                                            : ''
                                    }
                                </div>

                            </div>
                            : ''
                    }
                    {
                        deliveryServiceType === 'Towing' ?
                            <div className="mb-3">
                                <label htmlFor="chooseVehicleTypeForTow" className="form-label fs-6 mb-2">What type of vehicle do you want to tow?</label>
                                <div className="d-flex flex-wrap g-2">
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupForVehicleType" id="CAR"
                                            onClick={() => handleVehicleTypeForTow('Car')} />
                                        <label className="form-check-label" htmlFor="CAR">Car</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupForVehicleType" id="SUV" required
                                            onClick={() => handleVehicleTypeForTow('SUV')} />
                                        <label className="form-check-label" htmlFor="SUV">SUV</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupForVehicleType" id="VAN"
                                            onClick={() => handleVehicleTypeForTow('Van')} />
                                        <label className="form-check-label" htmlFor="VAN">Van</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupForVehicleType" id="pick_up_truck"
                                            onClick={() => handleVehicleTypeForTow('Pick up truck')} />
                                        <label className="form-check-label" htmlFor="pick_up_truck">Pick up truck</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupForVehicleType" id="truck"
                                            onClick={() => handleVehicleTypeForTow('Truck')} />
                                        <label className="form-check-label" htmlFor="truck">Truck</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupForVehicleType" id="otherVehicleTypeForTow"
                                            onClick={() => handleVehicleTypeForTow('other')} />
                                        <label className="form-check-label" htmlFor="otherVehicleTypeForTow">Other</label>
                                    </div>
                                </div>
                                {
                                    otherVehicleTypeForTow ?
                                        <input type="text" className="form-control mt-2" id="otherVehicleTypeForTow" placeholder='Other Vehicle...' required
                                            onChange={(e) => setVehicleTypeForTow(e.target.value)} />
                                        : ''
                                }
                            </div>
                            : ''
                    }
                    {
                        deliveryServiceType === 'Towing' || deliveryServiceType === 'Road Side Assistance' ?
                            <div className="mb-3">
                                <label htmlFor="chooseVehicleTowReason" className="form-label fs-6 mb-2">What is wrong with the vehicle?</label>
                                <div className="d-flex flex-wrap g-2">
                                    {
                                        VehicleProblem.map((problem) => (
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForVehicleTowReason" id={problem}
                                                    onClick={() => handleTowingReason(problem)} />
                                                <label className="form-check-label" htmlFor={problem}>{problem}</label>
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    otherTowingReason ?
                                        <input type="text" className="form-control mt-2" id="otherVehicleTowReason" placeholder='Other reason...' required
                                            onChange={(e) => setTowingReason(e.target.value)} />
                                        : ''
                                }
                            </div>
                            : ''
                    }
                    {
                        deliveryServiceType === 'Courier' || deliveryServiceType === 'Light products' || deliveryServiceType === 'Heavy equipment' ?
                            <div className="mb-3">
                                <label htmlFor="weightEquipment" className="form-label fs-6 mb-2">What is the weight of the product or equipment?</label>
                                <div className="d-flex flex-wrap g-2">
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupWeightEquipment" id="0_25_lb"
                                            onClick={() => handleWeightEquipment('0- 25 lb')} />
                                        <label className="form-check-label" htmlFor="0_25_lb">0- 25 lb</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupWeightEquipment" id="26_50_lb" required
                                            onClick={() => handleWeightEquipment('26- 50 lb')} />
                                        <label className="form-check-label" htmlFor="26_50_lb">26- 50 lb</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupWeightEquipment" id="51_75_lb"
                                            onClick={() => handleWeightEquipment('51- 75 lb')} />
                                        <label className="form-check-label" htmlFor="51_75_lb">51- 75 lb</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupWeightEquipment" id="76_100_lb"
                                            onClick={() => handleWeightEquipment('76 - 100 lb')} />
                                        <label className="form-check-label" htmlFor="76_100_lb">76 - 100 lb</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupWeightEquipment" id="above_101_lb"
                                            onClick={() => handleWeightEquipment('above 101 lb')} />
                                        <label className="form-check-label" htmlFor="above_101_lb">above 101 lb</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupWeightEquipment" id="otherWeightEquipment"
                                            onClick={() => handleWeightEquipment('other')} />
                                        <label className="form-check-label" htmlFor="otherWeightEquipment">Other</label>
                                    </div>
                                </div>
                                {
                                    otherWeightEquipment ?
                                        <input type="text" className="form-control mt-2" id="otherWeightEquipment" placeholder='Other weight...' required
                                            onChange={(e) => setWeightEquipment(e.target.value)} />
                                        : ''
                                }
                            </div> : ''
                    }
                    {
                        deliveryServiceType === 'Heavy equipment' ?
                            <div className='Heavy equipment'>
                                <div className="mb-3">
                                    <label htmlFor="chooseVehicleTypeForEquipment" className="form-label fs-6 mb-2">What type of vehicle do you need to move the equipment?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForVehicleEquipment" id="VAN"
                                                onClick={() => handleVehicleTypeForEquipment('Van')} />
                                            <label className="form-check-label" htmlFor="VAN">Van</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForVehicleEquipment" id="SUV" required
                                                onClick={() => handleVehicleTypeForEquipment('SUV')} />
                                            <label className="form-check-label" htmlFor="SUV">SUV</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForVehicleEquipment" id="trailer"
                                                onClick={() => handleVehicleTypeForEquipment('Trailer')} />
                                            <label className="form-check-label" htmlFor="trailer">Trailer</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForVehicleEquipment" id="pick_up_truck"
                                                onClick={() => handleVehicleTypeForEquipment('Pick up truck')} />
                                            <label className="form-check-label" htmlFor="pick_up_truck">Pick up truck</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForVehicleEquipment" id="summer_truck"
                                                onClick={() => handleVehicleTypeForEquipment('Summer Truck')} />
                                            <label className="form-check-label" htmlFor="summer_truck">Summer Truck</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForVehicleEquipment" id="not_sure"
                                                onClick={() => handleVehicleTypeForEquipment('Not Sure')} />
                                            <label className="form-check-label" htmlFor="not_sure">Not Sure</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioGroupForVehicleEquipment" id="otherVehicleTypeForEquipment"
                                                onClick={() => handleVehicleTypeForEquipment('other')} />
                                            <label className="form-check-label" htmlFor="otherVehicleTypeForEquipment">Other</label>
                                        </div>
                                    </div>
                                    {
                                        otherVehicleTypeForEquipment ?
                                            <input type="text" className="form-control mt-2" id="otherVehicleTypeForEquipment" placeholder='Other Vehicle...' required
                                                onChange={(e) => setVehicleTypeForEquipment(e.target.value)} />
                                            : ''
                                    }
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="equipmentTransport" className="form-label fs-6 mb-2">What type of equipment are you transporting?</label>
                                    <div className="d-flex flex-wrap g-2">
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioForEquipmentTransport" id="transporting_home" required
                                                onClick={() => handleEquipmentTransport('Home equipment')} />
                                            <label className="form-check-label" htmlFor="transporting_home equipment">Home equipment</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioForEquipmentTransport" id="auction_to_dealership"
                                                onClick={() => handleEquipmentTransport('Office equipment')} />
                                            <label className="form-check-label" htmlFor="auction_to_dealership">Office equipment</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioForEquipmentTransport" id="not_sure_transV"
                                                onClick={() => handleEquipmentTransport('Not Sure')} />
                                            <label className="form-check-label" htmlFor="not_sure_transV">Not Sure</label>
                                        </div>
                                        <div className="form-check me-3">
                                            <input className="form-check-input" type="radio" name="radioForEquipmentTransport" id="otherEqupTrans"
                                                onClick={() => handleEquipmentTransport('other')} />
                                            <label className="form-check-label" htmlFor="otherEqupTrans">Other</label>
                                        </div>
                                    </div>
                                    {
                                        otherEquipmentTransport ?
                                            <input type="text" className="form-control mt-2" id="otherEquipmentTransport" placeholder='Other...' required
                                                onChange={(e) => setEquipmentTransport(e.target.value)} />
                                            : ''
                                    }
                                </div>
                            </div>
                            : ''
                    }
                    {
                        deliveryServiceType === 'Light products' ?
                            <div className="mb-3">
                                <label htmlFor="productDeliver" className="form-label fs-6 mb-2">What type of product(s) are you delivering?</label>
                                <div className="d-flex flex-wrap g-2">
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioForProductDeliver" id="flower_gift" required
                                            onClick={() => handleProductDelivery('Flower & gift')} />
                                        <label className="form-check-label" htmlFor="flower_gift">Flower & gift</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioForProductDeliver" id="wine_liquor"
                                            onClick={() => handleProductDelivery('Wine & liquor')} />
                                        <label className="form-check-label" htmlFor="wine_liquor">Wine & liquor</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioForProductDeliver" id="auto_parts"
                                            onClick={() => handleProductDelivery('Auto parts')} />
                                        <label className="form-check-label" htmlFor="auto_parts">Auto parts</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioForProductDeliver" id="electronic"
                                            onClick={() => handleProductDelivery('Electronic')} />
                                        <label className="form-check-label" htmlFor="electronic">Electronic</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioForProductDeliver" id="otherProdDel"
                                            onClick={() => handleProductDelivery('other')} />
                                        <label className="form-check-label" htmlFor="otherProdDel">Other</label>
                                    </div>
                                </div>
                                {
                                    otherProductDelivery ?
                                        <input type="text" className="form-control mt-2" id="otherProductDelivery" placeholder='Other...' required
                                            onChange={(e) => setProductDelivery(e.target.value)} />
                                        : ''
                                }
                            </div>
                            : ''
                    }
                    {
                        deliveryServiceType !== 'Road Side Assistance' ?
                            <div className="mb-3">
                                <label htmlFor="petStatus" className="form-label fs-6 mb-2">Deliver home or office</label>
                                <div className="d-flex flex-wrap g-2">
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupForDeliveryLocation" id="deliverHome" required
                                            onClick={() => handleLocationType('Home')} />
                                        <label className="form-check-label" htmlFor="deliverHome">Home</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupForDeliveryLocation" id="deliverOffice"
                                            onClick={() => handleLocationType('Office')} />
                                        <label className="form-check-label" htmlFor="deliverOffice">Office</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input className="form-check-input" type="radio" name="radioGroupForDeliveryLocation" id="deliverOther"
                                            onClick={() => handleLocationType('other')} />
                                        <label className="form-check-label" htmlFor="deliverOther">Other</label>
                                    </div>
                                </div>
                                {
                                    otherLocationType ?
                                        <input type="text" className="form-control mt-2" id="OtherDeliverLocation" placeholder='Other...' required
                                            onChange={(e) => setLocationType(e.target.value)} />
                                        : ''
                                }
                            </div>
                            : ''
                    }

                    <div className="mb-3">
                        <label htmlFor="choosePassenger" className="form-label fs-6 mb-2">What is the best way to contact you?</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupContact" id="contact_call" required
                                    onClick={() => handleContactMode('Phone call')} />
                                <label className="form-check-label" htmlFor="contact_call">Phone call</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupContact" id="contact_email"
                                    onClick={() => handleContactMode('Email')} />
                                <label className="form-check-label" htmlFor="contact_email">Email</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupContact" id="contact_text"
                                    onClick={() => handleContactMode('Text Message')} />
                                <label className="form-check-label" htmlFor="contact_text">Text Message</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupContact" id="contact_other"
                                    onClick={() => handleContactMode('other')} />
                                <label className="form-check-label" htmlFor="contact_other">Other</label>
                            </div>
                        </div>
                        {
                            otherContactMode ?
                                <input type="text" className="form-control mt-2" id="otherContactMode" placeholder='Other contact...' required
                                    onChange={(e) => setContactMode(e.target.value)} />
                                : ''
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="chooseBudget" className="form-label fs-6 mb-2">What is your budget for this service?</label>
                        <div className="d-flex flex-wrap g-2">
                            {
                                Budget.map((budge) => (
                                    <div className="form-check me-3" key={budge}>
                                        <input className="form-check-input" type="radio" name="radioGroupBudget" id={budge} required
                                            onClick={() => handleBudget(budge)} />
                                        <label className="form-check-label" htmlFor={budge}>{budge}</label>
                                    </div>
                                ))
                            }

                        </div>
                        {
                            otherBudget ?
                                <input type="text" className="form-control mt-2" id="otherBudget" placeholder='Other budget...' required
                                    onChange={(e) => setBudget(e.target.value)} />
                                : ''
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="chooseTip" className="form-label fs-6 mb-2">Select a tip for this service?</label>
                        <div className="d-flex flex-wrap g-2">
                            {
                                Tip.map((tip) => (
                                    <div className="form-check me-3" key={tip}>
                                        <input className="form-check-input" type="radio" name="radioGroupTip" id={tip} required
                                            onClick={() => handleTip(tip)} />
                                        <label className="form-check-label" htmlFor={tip}>{tip}</label>
                                    </div>
                                ))
                            }

                        </div>
                        {
                            otherTip ?
                                <input type="text" className="form-control mt-2" id="otherTip" placeholder='Other tip...' required
                                    onChange={(e) => setTip(e.target.value)} />
                                : ''
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="choosePayment" className="form-label fs-6 mb-2">What is your payment method for this service?</label>
                        <div className="d-flex flex-wrap g-2">
                            {
                                PaymentMethod.map((pay) => (
                                    <div className="form-check me-3" key={pay}>
                                        <input className="form-check-input" type="radio" name="radioGroupPay" id={pay} required
                                            onClick={() => handlePaymentMethod(pay)} />
                                        <label className="form-check-label" htmlFor={pay}>{pay}</label>
                                    </div>
                                ))
                            }

                        </div>
                        {
                            otherPaymentMethod ?
                                <input type="text" className="form-control mt-2" id="otherPayment" placeholder='Other payment method...' required
                                    onChange={(e) => setPaymentMethod(e.target.value)} />
                                : ''
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="pickupLocation" className="form-label fs-6 mb-2">Pickup Location</label>
                        <Autocomplete
                            className="form-control"
                            placeholder='Please enter pickup location...'
                            onPlaceSelected={(e) => handleLocationSelection(e, 'pickup')}
                            options={{
                                fields: ["formatted_address", "geometry", "name"],
                                strictBounds: false,
                                types: [],
                            }}
                        />
                    </div>
                    {
                        deliveryServiceType !== 'Road Side Assistance' ?
                            <div className="mb-3">
                                <label htmlFor="DropLocation" className="form-label fs-6 mb-2">Drop Off Location</label>
                                <Autocomplete
                                    className="form-control"
                                    placeholder='Please enter dropoff location...'
                                    onPlaceSelected={(e) => handleLocationSelection(e, 'dropOff')}
                                    options={{
                                        fields: ["formatted_address", "geometry", "name"],
                                        strictBounds: false,
                                        types: [],
                                    }}
                                />
                            </div>
                            : ''
                    }

                    <div className="mb-3">
                        <label htmlFor="pickupDate" className="form-label fs-6 mb-2">Pickup Date</label>
                        <div className='date-picker-wrapper'>
                            <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={pickUpDate}
                                onChange={(value) => setPickUpDate(value)} minDate={new Date()}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="pickupTime" className="form-label fs-6 mb-2">Pickup Time</label>
                        <input type="time" id="pickupTime" className="form-control" min="09:00" max="18:00" required
                            onChange={(e) => handleTimeChange(e)} />
                    </div>
                    {
                        deliveryServiceType === 'Vehicle Transportation' || deliveryServiceType === 'Towing' || deliveryServiceType === 'Courier' ||
                            deliveryServiceType === 'Heavy equipment' || deliveryServiceType === 'home(office) moving' ?
                            <div className='Doff_off_time_date'>
                                <div className="mb-3">
                                    <label htmlFor="pickupDate" className="form-label fs-6 mb-2">Drop off date</label>
                                    <div className='date-picker-wrapper'>
                                        <DatePicker showIcon className="form-control w-100" id="appointmentDropOffDate" selected={dropOffDate}
                                            onChange={(value) => setDropOffDate(value)} minDate={new Date()}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="pickupTime" className="form-label fs-6 mb-2">Drop Off time</label>
                                    <input type="time" id="pickupTime" className="form-control" min="09:00" max="18:00" required
                                        onChange={(e) => handleDropOffTimeChange(e)} />
                                </div>
                            </div>
                            : ''
                    }


                    <div className="mb-4">
                        <label htmlFor="otherInfo" className="form-label fs-6 mb-2">Any other information</label>
                        <textarea className="form-control" id="otherInfo" rows="4" placeholder='Write your description here...' required
                            onChange={(e) => setOtherInfo(e.target.value)}></textarea>
                    </div>
                    <div className='text-danger small mb-2'>TO QUALIFY FOR A REBATE, RATE, COMMENT & SUBMIT RECEIPT OF TRANSECTIONS MADE TO sales@couponranking.com </div>
                    <div className="btn-wrapper">
                        <button type="submit" className="w-100 btn btn-primary" onClick={(e) => handleSubmit(e)}>Next</button>
                    </div>
                    {props.loading ?
                        <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                            style={{ zIndex: 1030 }}>
                            <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                        </div>
                        : ''
                    }
                </div>
            ) :
                (<UserInfoForQuoteService state={serviceData} />)
            }
        </>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clickPerCount: (data, toast, navigate) => dispatch(actions.clickPerCount(data, toast, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(DeliveryService))